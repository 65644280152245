//Packages
import React, { useState, useEffect, useContext } from "react";

const CreateDiscount = (props) => {

    return (   
        <div className="admin_addproduct">
            <div className="admin_pages_title">
                <div className="admin_pages_title_divResponsive2">
                    <h1>New Discount</h1>
                    <div className="admin_catalog_div_button">
                        <button className="button" style={{float:"right",marginTop:"40px"}}>Create Discount</button>
                    </div>
                </div>
            </div>
            <form>
                <div style={{width:"1147px",height:"790px", marginLeft:"20px", marginBottom:"20px",float:"left"}}>
                    <div style={{width:"550px",height:"230px",float:"left"}}>
                        <div className="editProduct_name" style={{float:"left",width:"100%",height:"105px",paddingTop:"15px"}}>
                            <p>Discount Name<span style={{color:"red", fontWeight:"bold"}}> *</span></p>
                            <input placeholder="Discount Name" name="title" />
                        </div>
                        <div className="editProduct_name" style={{float:"left",width:"100%",height:"105px",paddingTop:"15px"}}>
                            <p>Discount Value<span style={{color:"red", fontWeight:"bold"}}> *</span></p>
                            <input placeholder="Discount Name" name="title" />
                        </div>
                    </div>
                    <div style={{float:"left",width:"575px",height:"259px" ,marginLeft:"20px", backgroundColor:"white",boxShadow:"0px 0px 10px 2px rgb(222, 222, 222)",borderRadius:"5px"}}>
                        <p style={{fontFamily:"Source Code Pro", marginLeft:"30px", marginTop:"10px"}}>Applies to<span style={{color:"red",fontFamily:"Source Code Pro"}}> *</span></p>
                        <input style={{marginLeft:"30px"}} type="radio" id="age1" name="age" value="30" />
                        <label style={{fontFamily:"Source Code Pro"}} for="age1">  All products</label><br/>
                        <input style={{marginLeft:"30px"}} type="radio" id="age2" name="age" value="60" />
                        <label style={{fontFamily:"Source Code Pro"}} for="age2">  Specific tags</label><br/>  
                        <input style={{marginLeft:"30px"}} type="radio" id="age3" name="age" value="100" />
                        <label style={{fontFamily:"Source Code Pro"}} for="age3">  Specific products</label>
                    </div>
                </div>
            </form>
        </div>
    );
}
    
export default CreateDiscount;