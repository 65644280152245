//Style
import "./Login.css";

//Packages
import React, { useState, useContext } from "react";
import { useHistory, Link } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import { Authcontext } from "../context/auth-context";

//Images
import logo from "../../images/logo.png";

//Animations
import SpiLogin from "../../shared/animations/SpiLogin.js";

// -- FUNCTION
const Login = () => {
  const auth = useContext(Authcontext);

  //Login
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const history = useHistory();

  let responseData;

  const [loginLoading, setLoginLoading] = useState(false);

  // Login - Enter key press
  const handleKeyPress = async (event) => {
    if (event.key === "Enter") {
      event.preventDefault();
      setLoginLoading(true);
      try {
        const response = await fetch(
          process.env.REACT_APP_BACKEND_URL + "/users/login/",
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify({
              email: email,
              password: password,
            }),
          }
        );
        responseData = await response.json();

        if (response.ok) {
          auth.login(
            responseData.userId,
            responseData.token,
            responseData.email
          );
          if (responseData.email === "admin") {
            history.push("/admin/catalog");
          } else {
            history.push("/user/banner");
          }
        } else {
          setLoginLoading(false);
          toast.error("Wrong Credentials", {
            position: "top-center",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        }
      } catch (err) {}
    }
  };

  const clickToLogin = async (event) => {
    event.preventDefault();
    setLoginLoading(true);
    try {
      const response = await fetch(
        process.env.REACT_APP_BACKEND_URL + "/users/login",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            email: email,
            password: password,
          }),
        }
      );
      responseData = await response.json();

      if (response.ok) {
        setLoginLoading(false);
        auth.login(responseData.userId, responseData.token, responseData.email);
        //if(responseData.email === 'admin' || responseData.email === 'adminPerto' || responseData.email === 'administrator'){
        if (responseData.email === "admin") {
          history.push("/admin/catalog");
        } else {
          history.push("/user/banner");
        }
      } else {
        setLoginLoading(false);
        toast.error("Wrong Credentials", {
          position: "top-center",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      }
    } catch (err) {}
  };

  // -- RETURN
  return (
    <div className="div-login">
      <ToastContainer />
      <div className="login-header"></div>
      <div className="div-login-img">
        <img src={logo} alt="logo"></img>
        {loginLoading ? <SpiLogin /> : <div></div>}
      </div>
      <div className="login-data">
        <div className="login-data_div">
          <p className="p_login-data">User</p>
          <input
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            className="input_login-data"
            placeholder="User"
          ></input>
          <p className="p_login-data">Password</p>
          <input
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            className="input_login-data"
            type="password"
            placeholder="Password"
            onKeyPress={handleKeyPress}
          ></input>
          <button className="button_login" onClick={clickToLogin}>
            Login
          </button>

          <Link style={{ textDecoration: "none" }} to="/passwordRecovery">
            <p className="p_login-forgotten">Forgot password?</p>
          </Link>
        </div>
      </div>
    </div>
  );
};

export default Login;
