import styles from './style.module.css';

import bannerImg from '../../../images/logo.png';

const Banner = props => {
    return(
        <div className={styles.main}>
            <h1>Banner</h1>
            <h3>Banner Title</h3>
            <input placeholder="Welcome!"></input>
            <h3>Image</h3>
            <img alt="" src={bannerImg}></img><br/>
            <input type="file"></input><br/>
            <button>Update</button>
        </div>
    );
}

export default Banner;