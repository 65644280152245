import React from "react";
import styles from "./Table2.module.css";

const Table1 = props => {

    console.log(props.users);
    
    if(props.users.length > 0){
        return(
            <div className={styles.main}>
                <div className={styles.headerDataDiv}>
                    <div className={styles.headerData}><p>Client ID</p></div>
                    <div className={styles.headerData3}><p>Client</p></div>
                    <div className={styles.headerData3}><p>Email</p></div>
                    <div className={styles.headerData2}><p>Orders</p></div>
                    <div className={styles.headerData2}><p>Spent</p></div>
                </div>
                    {props.users.map((user) => (
                        <div className={styles.headerDataDiv}>
                            <div className={styles.tableData}><p>{user.customerId}</p></div>
                            <div className={styles.tableData3}><p>{user.companyName}</p></div>
                            <div className={styles.tableData3}><p>{user.email}</p></div>
                            <div className={styles.tableData2}><p>{user.orders}</p></div>
                            <div className={styles.tableData2}><p>{parseFloat(user.spent).toFixed(2)}€</p></div>
                        </div>
                    ))}
            </div>
        )
    }else return(<div></div>)
    
}

export default Table1;