import './style.css';

import Spi from '../../../../shared/animations/Spi';

import React, { useState, useEffect } from "react";
import Table1 from '../../components/Table1';

import { FiCalendar } from "react-icons/fi";

const DashboardProducts = () => {

    //Fetch products
    const [loadedData, setLoadedData] = useState([]);
    //const [products, setProducts] = useState([]);
    useEffect(() => {
        const sendRequest = async () => {
            try{
                const response = await fetch(process.env.REACT_APP_BACKEND_URL + '/products/get', {
                    method: 'GET'
                });
                const responseData = await response.json();
                if(response.ok){
                    setLoadedData(responseData);
                    //setProducts(responseData);
                    console.log(responseData);
                }
            } catch(err){
                console.log(err);
            }
        };
        sendRequest();
    }, []);

    

    if(loadedData){
        return(
            <div className="dashboard">
                <div className="dashboard_overview_mainDiv_titleDiv">
                <div className="dashboard_overview_titleDiv">
                    <h1>Products</h1>
                    <div className="dashboard_optionsDiv"><FiCalendar className="dashboard_optionsDiv_icon" /><p>All Time</p></div>
                </div>
                </div>
                <div className="dashmoard_mainDiv">
                    <Table1 title="Stock" products={loadedData} />
                    <Table1 title="Sales" products={loadedData} />
                    <Table1 title="Units" products={loadedData} />
                </div>
            </div>
        );
    }else{
        return <Spi />
    }
}

export default DashboardProducts;