import React, { useState, useContext } from "react";
import { Authcontext } from "../../../login/context/auth-context";

const UploadStocks = () => {
  const auth = useContext(Authcontext);

  // File and data
  const [file, setFile] = useState();
  const [array, setArray] = useState();
  const [head, setHead] = useState();
  const [fileName, setFileName] = useState("No file chosen");

  // Manage file
  const fileReader = new FileReader();
  const handleOnChange = (e) => {
    if (e.target.files[0]) {
      setFile(e.target.files[0]);
      setFileName(e.target.files[0].name);
    }
  };

  const csvFileToArray = (string) => {
    let csvHeader = string.slice(0, string.indexOf("\n")).split(",");
    csvHeader = csvHeader.map((h) => {
      h = h.replace('"', "");
      h = h.replace('"', "");
      return h;
    });
    setHead(csvHeader);

    const csvRows = string.slice(string.indexOf("\n") + 1).split("\n");

    const array = csvRows.map((i) => {
      const values = i.split(",");

      const obj = csvHeader.reduce((object, header, index) => {
        values[index] = values[index].replace('"', "");
        values[index] = values[index].replace('"', "");
        object[header] = values[index];
        return object;
      }, {});
      return obj;
    });

    const array2 = array.filter((product) => {
      if (product.modified === "1") return true;
      else return false;
    });

    setArray(array2);
  };

  const handleOnSubmit = (e) => {
    e.preventDefault();

    if (file) {
      fileReader.onload = function (event) {
        const text = event.target.result;
        console.log("----------- CSV readen -----------");
        console.log(text);
        csvFileToArray(text);
      };

      fileReader.readAsText(file);
    }
  };

  // Correct excel header
  const correctHeader = [
    "sku",
    "modified",
    "stock20",
    "stock21",
    "stock22",
    "stock23",
    "stock24",
    "stock25",
    "stock26",
    "stock27",
    "stock28",
    "stock29",
    "stock30",
    "stock31",
    "stock32",
    "stock33",
    "stock34",
    "stock35",
    "stock36",
    "stock37",
    "stock38",
    "stock39",
    "stock40",
    "stock41",
    "stock42",
    "stock43",
    "stock44",
    "stock45",
    "stock46",
  ];

  // Error found
  const errors = [];

  async function checkExcel() {
    // Check if table has right header
    for (let i = 0; i < head.length; i++) {
      if (head[i] !== correctHeader[i])
        errors.push({ right: correctHeader[i], wrong: head[i] });
    }

    if (errors.length === 0) {
      setLoading(true);

      console.log("No errors found");
      for (let j = 0; j < array.length; j++) {
        // Check available sizes

        let userBody = JSON.stringify({
          sku: array[j].sku,
          stock20: Number(array[j].stock20),
          stock21: Number(array[j].stock21),
          stock22: Number(array[j].stock22),
          stock23: Number(array[j].stock23),
          stock24: Number(array[j].stock24),
          stock25: Number(array[j].stock25),
          stock26: Number(array[j].stock26),
          stock27: Number(array[j].stock27),
          stock28: Number(array[j].stock28),
          stock29: Number(array[j].stock29),
          stock30: Number(array[j].stock30),
          stock31: Number(array[j].stock31),
          stock32: Number(array[j].stock32),
          stock33: Number(array[j].stock33),
          stock34: Number(array[j].stock34),
          stock35: Number(array[j].stock35),
          stock36: Number(array[j].stock36),
          stock37: Number(array[j].stock37),
          stock38: Number(array[j].stock38),
          stock39: Number(array[j].stock39),
          stock40: Number(array[j].stock40),
          stock41: Number(array[j].stock41),
          stock42: Number(array[j].stock42),
          stock43: Number(array[j].stock43),
          stock44: Number(array[j].stock44),
          stock45: Number(array[j].stock45),
          stock46: Number(array[j].stock46),
        });
        await post(userBody);
        console.log(userBody);
      }
      setLoading(false);
    } else {
      console.log(errors);
    }
  }

  //Post to database
  const post = async (userBody) => {
    setLoading(true);
    const url = process.env.REACT_APP_BACKEND_URL + "/stocks/update";

    try {
      const response = await fetch(url, {
        method: "PATCH",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${auth.token}`,
        },
        body: userBody,
      });
      const resData = await response.json();
      console.log(resData);
      setLoading(false);
    } catch (err) {
      console.log(err);
    }
  };

  // Loading
  const [loading, setLoading] = useState(false);

  // Response
  const [responses, setResponses] = useState([]);

  return (
    <React.Fragment>
      <form>
        <input
          id="idInput"
          type={"file"}
          style={{ display: "none" }}
          accept={".csv"}
          onChange={handleOnChange}
        />
        <label className="inputUsers_excel" htmlFor="idInput">
          Choose csv file
        </label>
        <p style={{ textAlign: "center" }}>{fileName}</p>
        <button
          className="button"
          style={{ display: "block", margin: "auto" }}
          onClick={(e) => {
            handleOnSubmit(e);
          }}
        >
          Import file
        </button>
      </form>
      <br />

      {array ? (
        <React.Fragment>
          <div
            style={{
              width: "1000px",
              overflow: "scroll",
              margin: "auto",
              border: "1px solid black",
            }}
          >
            <table>
              <thead>
                <tr key={"header"}>
                  {head.map((key) => (
                    <th>{key}</th>
                  ))}
                </tr>
              </thead>

              <tbody>
                {array.map((item) => (
                  <tr key={item.id}>
                    {Object.values(item).map((val) => (
                      <td>{val}</td>
                    ))}
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
          <button
            type="button"
            className="button_save"
            style={{ display: "block", margin: "auto", marginTop: "20px" }}
            onClick={checkExcel}
          >
            Update stocks
          </button>
        </React.Fragment>
      ) : (
        <span></span>
      )}
    </React.Fragment>
  );
};

export default UploadStocks;
