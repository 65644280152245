import './userBanner.css';
import { useHistory } from 'react-router';
import { useEffect, useContext } from 'react';

import { Authcontext } from "../../../login/context/auth-context";

import { GETauthResourceRequest } from '../../../shared/functions/requests';

// Redux
import { useDispatch } from 'react-redux';
import { userInfo } from '../../../redux/actions/userDiscount';

const Banner = props => {

  const auth = useContext(Authcontext);

    var history = useHistory();

    function goToCatalog(){
        history.push('/user/catalog');
    }

    const dispatch = useDispatch();
    useEffect(()=> {
        //fazer pedido de dados do cliente
        GETauthResourceRequest(auth,'/users/get',auth.email).then((res) => {
            // Store client discount on redux 
            dispatch(userInfo(res[0].discount));
        })
    });

    return(
        <div className="userBanner">
            <h1>Welcome!</h1>
            <button onClick={goToCatalog}>Catalog</button>
        </div>
    );
}

export default Banner;