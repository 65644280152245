import styles from "./Component4.module.css";

const Component4 = props => {
    return(
        <div className={styles.main}>
            <p className={styles.title}>{props.title}</p>
            <div className={styles.line}></div>
            <p className={styles.value}>{props.value}</p>
        </div>
    )
}

export default Component4;