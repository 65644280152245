//Style
import './AdminUser.css';

//Packages
import { Link } from 'react-router-dom';
import { FiEdit } from "react-icons/fi";

// -- FUNCTION
const AdminUser = props => {

    const url = `/admin/users/edituser/${props.user.email}`;

    return(
        <div className="admin_user">
            <div className="adminUser-div-div1">
                <p className="adminUser-div-div3-data">{props.user.customerId}</p>
            </div>
            <div className="adminUser-div-div2">
                <p className="adminUser-div-div3-data">{props.user.companyName}</p>
            </div>
            <div className="adminUser-div-div3">
                <p className="adminUser-div-div3-data">{props.user.email}</p>
            </div>
            <div className="adminUser-div-div4">
                <p className="adminUser-div-div3-data">{props.user.billingAdressCountry}</p>
            </div>
            <div className="adminUser-div-div5">
                <p className="adminUser-div-div3-data">
                    <Link to={url} >
                        <FiEdit className="admin_user_editIcon" />
                    </Link>
                </p>
            </div>
        </div>
    );
};

export default AdminUser;