import './Banner.css';

const Banner = props => {
    
    return(
        <div className="banner">
            <p>FREE SHIPPING 6 PAIRS AND MORE. 10€ TRANSPORT COST - LESS THAN 6</p>
        </div>
        );
};

export default Banner;