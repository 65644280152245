import './ShopcartOrder.css';

import {useState} from 'react';

import ShopcartNewOrder from './ShopcartNewOrder';
import { useDispatch } from 'react-redux';
import { addToCart } from '../../../../redux/actions/cartActions';
import { removeFromCart } from '../../../../redux/actions/cartActions';
import { FiTrash2 } from "react-icons/fi";

const ShopcartOrder = props => {

    const changeHandler = e => {
        if(e.target.value > Number(e.target.max) || e.target.value < Number(e.target.min)){
            e.target.value = 0;
        }else{
            const newValues = {...formValues, [e.target.name]: Number(e.target.value)}
            setFormValues(newValues);
            setMsgSaveChanges(1);
            sumQuantity(newValues);
            sumTotal(newValues);
        }
    }

    //STATE FORM QUANTITY for SIZE
    const [formValues, setFormValues] = useState({
            qty20: props.product.qty20,
            qty21: props.product.qty21,
            qty22: props.product.qty22,
            qty23: props.product.qty23,
            qty24: props.product.qty24,
            qty25: props.product.qty25,
            qty26: props.product.qty26,
            qty27: props.product.qty27,
            qty28: props.product.qty28,
            qty29: props.product.qty29,
            qty30: props.product.qty30,
            qty31: props.product.qty31,
            qty32: props.product.qty32,
            qty33: props.product.qty33,
            qty34: props.product.qty34,
            qty35: props.product.qty35,
            qty36: props.product.qty36,
            qty37: props.product.qty37,
            qty38: props.product.qty38,
            qty39: props.product.qty39,
            qty40: props.product.qty40,
            qty41: props.product.qty41,
            qty42: props.product.qty42,
            qty43: props.product.qty43,
            qty44: props.product.qty44,
            qty45: props.product.qty45,
            qty46: props.product.qty46
    });

    const [msgSaveChanges, setMsgSaveChanges] = useState(0);

    const [quantity, setQuantity] = useState(props.product.quantity);
    const [total, setTotal] = useState(props.product.total);

    const sumQuantity = (newValues) => {
        setQuantity(newValues.qty20 + newValues.qty21 + newValues.qty22 + newValues.qty23 + newValues.qty24 + newValues.qty25 + newValues.qty26 + newValues.qty27 + newValues.qty28 + newValues.qty29 +
                    newValues.qty30 + newValues.qty31 + newValues.qty32 + newValues.qty33 +
                    newValues.qty34 + newValues.qty35 + newValues.qty36 + newValues.qty37 +
                    newValues.qty38 + newValues.qty39 + newValues.qty40 + newValues.qty41 +
                    newValues.qty42 + newValues.qty43 + newValues.qty44 + newValues.qty45 + newValues.qty46);
    }

    const sumTotal = (newValues) => {
        const variable_total = (
            newValues.qty20 * props.product.pri20 + newValues.qty21 * props.product.pri21 + 
            newValues.qty22 * props.product.pri22 + newValues.qty23 * props.product.pri23 + 
            newValues.qty24 * props.product.pri24 + newValues.qty25 * props.product.pri25 + 
            newValues.qty26 * props.product.pri26 + newValues.qty27 * props.product.pri27 + 
            newValues.qty28 * props.product.pri28 + newValues.qty29 * props.product.pri29 +
            newValues.qty30 * props.product.pri30 + newValues.qty31 * props.product.pri31 +
            newValues.qty32 * props.product.pri32 + newValues.qty33 * props.product.pri33 +
            newValues.qty34 * props.product.pri34 + newValues.qty35 * props.product.pri35 +
            newValues.qty36 * props.product.pri36 + newValues.qty37 * props.product.pri37 +
            newValues.qty38 * props.product.pri38 + newValues.qty39 * props.product.pri39 +
            newValues.qty40 * props.product.pri40 + newValues.qty41 * props.product.pri41 +
            newValues.qty42 * props.product.pri42 + newValues.qty43 * props.product.pri43 + 
            newValues.qty44 * props.product.pri44 + newValues.qty45 * props.product.pri45 +
            newValues.qty46 * props.product.pri46);
        setTotal(variable_total);
    }

    const dispatch = useDispatch();

    const deleteProduct = e => {
        e.preventDefault();
        dispatch(removeFromCart(props.product.sku));
    }

    const addToCart1 = e => {
        e.preventDefault();
        dispatch(addToCart(props.product.sku, props.product.title, props.product.color, 
            formValues.qty20, formValues.qty21, formValues.qty22, formValues.qty23, 
            formValues.qty24, formValues.qty25, formValues.qty26, formValues.qty27, 
            formValues.qty28, formValues.qty29, formValues.qty30, formValues.qty31, 
            formValues.qty32, formValues.qty33, formValues.qty34, formValues.qty35, 
            formValues.qty36, formValues.qty37, formValues.qty38, formValues.qty39, 
            formValues.qty40, formValues.qty41, formValues.qty42, formValues.qty43, 
            formValues.qty44, formValues.qty45, formValues.qty46,
            quantity, total,
            props.product.pri20, props.product.pri21, props.product.pri22, props.product.pri23,
            props.product.pri24, props.product.pri25, props.product.pri26,
            props.product.pri27, props.product.pri28, props.product.pri29, props.product.pri30,
            props.product.pri31, props.product.pri32, props.product.pri33, props.product.pri34,
            props.product.pri35, props.product.pri36, props.product.pri37, props.product.pri38,
            props.product.pri39, props.product.pri40, props.product.pri41, props.product.pri42, props.product.pri43, props.product.pri44, props.product.pri45, props.product.pri46,
            props.product.image, 
            props.product.stock20, props.product.stock21, props.product.stock22, props.product.stock23, 
            props.product.stock24, props.product.stock25, props.product.stock26, props.product.stock27, 
            props.product.stock28,
            props.product.stock29, props.product.stock30, props.product.stock31, props.product.stock32,
            props.product.stock33, props.product.stock34, props.product.stock35, props.product.stock36,
            props.product.stock37, props.product.stock38, props.product.stock39, props.product.stock40,
            props.product.stock41, props.product.stock42, props.product.stock43, props.product.stock44, props.product.stock45, props.product.stock46,
            props.product.pvp20, props.product.pvp21, props.product.pvp22, props.product.pvp23, 
            props.product.pvp24, props.product.pvp25, props.product.pvp26, props.product.pvp27, 
            props.product.pvp28, props.product.pvp29, props.product.pvp30,
            props.product.pvp31, props.product.pvp32, props.product.pvp33, props.product.pvp34,
            props.product.pvp35, props.product.pvp36, props.product.pvp37, props.product.pvp38,
            props.product.pvp39, props.product.pvp40, props.product.pvp41, props.product.pvp42, props.product.pvp43, props.product.pvp44, props.product.pvp45, props.product.pvp46,
            props.product.ava20, props.product.ava21, props.product.ava22, props.product.ava23, 
            props.product.ava24, props.product.ava25, props.product.ava26, props.product.ava27, 
            props.product.ava28, props.product.ava29, props.product.ava30,
            props.product.ava31, props.product.ava32, props.product.ava33, props.product.ava34,
            props.product.ava35, props.product.ava36, props.product.ava37, props.product.ava38,
            props.product.ava39, props.product.ava40, props.product.ava41, props.product.ava42, props.product.ava43, props.product.ava44, props.product.ava45, props.product.ava46));
            setMsgSaveChanges(0);
    }

    return(
        <div className="qtyfullsize">
            <div className="quantitySize-image">
                <p className="quantitySize-image-sku">{props.product.title} {props.product.color}</p>
                <div className="quantitySize-image-divimg">
                    <img src={props.product.image} alt="" />
                </div>
                <p className="quantitySize-image-sku">{props.product.sku}</p>
            </div>
            <div className="quantitySizeAllforShopingCart">
                <div className="quantitySize">
                    {props.product.ava20 ? <ShopcartNewOrder changeHandler={changeHandler} price={props.product.pri20} pvp={props.product.pvp20} qty={props.product.qty20} stock={props.product.stock20} size="20"/> : <span />}
                    {props.product.ava21 ? <ShopcartNewOrder changeHandler={changeHandler} price={props.product.pri21} pvp={props.product.pvp21} qty={props.product.qty21} stock={props.product.stock21} size="21"/> : <span />}
                    {props.product.ava22 ? <ShopcartNewOrder changeHandler={changeHandler} price={props.product.pri22} pvp={props.product.pvp22} qty={props.product.qty22} stock={props.product.stock22} size="22"/> : <span />}
                    {props.product.ava23 ? <ShopcartNewOrder changeHandler={changeHandler} price={props.product.pri23} pvp={props.product.pvp23} qty={props.product.qty23} stock={props.product.stock23} size="23"/> : <span />}
                    {props.product.ava24 ? <ShopcartNewOrder changeHandler={changeHandler} price={props.product.pri24} pvp={props.product.pvp24} qty={props.product.qty24} stock={props.product.stock24} size="24"/> : <span />}
                    {props.product.ava25 ? <ShopcartNewOrder changeHandler={changeHandler} price={props.product.pri25} pvp={props.product.pvp25} qty={props.product.qty25} stock={props.product.stock25} size="25"/> : <span />}
                    {props.product.ava26 ? <ShopcartNewOrder changeHandler={changeHandler} price={props.product.pri26} pvp={props.product.pvp26} qty={props.product.qty26} stock={props.product.stock26} size="26"/> : <span />}
                    {props.product.ava27 ? <ShopcartNewOrder changeHandler={changeHandler} price={props.product.pri27} pvp={props.product.pvp27} qty={props.product.qty27} stock={props.product.stock27} size="27"/> : <span />}
                    {props.product.ava28 ? <ShopcartNewOrder changeHandler={changeHandler} price={props.product.pri28} pvp={props.product.pvp28} qty={props.product.qty28} stock={props.product.stock28} size="28"/> : <span />}
                    {props.product.ava29 ? <ShopcartNewOrder changeHandler={changeHandler} price={props.product.pri29} pvp={props.product.pvp29} qty={props.product.qty29} stock={props.product.stock29} size="29"/> : <span />}
                    {props.product.ava30 ? <ShopcartNewOrder changeHandler={changeHandler} price={props.product.pri30} pvp={props.product.pvp30} qty={props.product.qty30} stock={props.product.stock30} size="30"/> : <span />}
                    {props.product.ava31 ? <ShopcartNewOrder changeHandler={changeHandler} price={props.product.pri31} pvp={props.product.pvp31} qty={props.product.qty31} stock={props.product.stock31} size="31"/> : <span />}
                    {props.product.ava32 ? <ShopcartNewOrder changeHandler={changeHandler} price={props.product.pri32} pvp={props.product.pvp32} qty={props.product.qty32} stock={props.product.stock32} size="32"/> : <span />}
                    {props.product.ava33 ? <ShopcartNewOrder changeHandler={changeHandler} price={props.product.pri33} pvp={props.product.pvp33} qty={props.product.qty33} stock={props.product.stock33} size="33"/> : <span />}
                    {props.product.ava34 ? <ShopcartNewOrder changeHandler={changeHandler} price={props.product.pri34} pvp={props.product.pvp34} qty={props.product.qty34} stock={props.product.stock34} size="34"/> : <span />}
                    {props.product.ava35 ? <ShopcartNewOrder changeHandler={changeHandler} price={props.product.pri35} pvp={props.product.pvp35} qty={props.product.qty35} stock={props.product.stock35} size="35"/> : <span />}
                    {props.product.ava36 ? <ShopcartNewOrder changeHandler={changeHandler} price={props.product.pri36} pvp={props.product.pvp36} qty={props.product.qty36} stock={props.product.stock36} size="36"/> : <span />}
                    {props.product.ava37 ? <ShopcartNewOrder changeHandler={changeHandler} price={props.product.pri37} pvp={props.product.pvp37} qty={props.product.qty37} stock={props.product.stock37} size="37"/> : <span />}
                    {props.product.ava38 ? <ShopcartNewOrder changeHandler={changeHandler} price={props.product.pri38} pvp={props.product.pvp38} qty={props.product.qty38} stock={props.product.stock38} size="38"/> : <span />}
                    {props.product.ava39 ? <ShopcartNewOrder changeHandler={changeHandler} price={props.product.pri39} pvp={props.product.pvp39} qty={props.product.qty39} stock={props.product.stock39} size="39"/> : <span />}
                    {props.product.ava40 ? <ShopcartNewOrder changeHandler={changeHandler} price={props.product.pri40} pvp={props.product.pvp40} qty={props.product.qty40} stock={props.product.stock40} size="40"/> : <span />}
                    {props.product.ava41 ? <ShopcartNewOrder changeHandler={changeHandler} price={props.product.pri41} pvp={props.product.pvp41} qty={props.product.qty41} stock={props.product.stock41} size="41"/> : <span />}
                    {props.product.ava42 ? <ShopcartNewOrder changeHandler={changeHandler} price={props.product.pri42} pvp={props.product.pvp42} qty={props.product.qty42} stock={props.product.stock42} size="42"/> : <span />}
                    {props.product.ava43 ? <ShopcartNewOrder changeHandler={changeHandler} price={props.product.pri43} pvp={props.product.pvp43} qty={props.product.qty43} stock={props.product.stock43} size="43"/> : <span />}
                    {props.product.ava44 ? <ShopcartNewOrder changeHandler={changeHandler} price={props.product.pri44} pvp={props.product.pvp44} qty={props.product.qty44} stock={props.product.stock44} size="44"/> : <span />}
                    {props.product.ava45 ? <ShopcartNewOrder changeHandler={changeHandler} price={props.product.pri45} pvp={props.product.pvp45} qty={props.product.qty45} stock={props.product.stock45} size="45"/> : <span />}
                    {props.product.ava46 ? <ShopcartNewOrder changeHandler={changeHandler} price={props.product.pri46} pvp={props.product.pvp46} qty={props.product.qty46} stock={props.product.stock46} size="46"/> : <span />}
                </div>
                <div className="quantitySize_shoppingmoreinfo">
                    <FiTrash2 className="shopcartOrder-trashIcon" onClick={deleteProduct}/>
                    <div className="shopcart-button-edit">
                        {msgSaveChanges===1 ? 
                        <button className="button" style={{width:"100px",height:"20px"}} onClick={addToCart1}>EDIT</button>
                        :
                        <button disabled={true} className="button" style={{width:"100px",height:"20px"}}>EDIT</button>
                        }
                    </div>
                    <p className="shopcartOrder-qty">Total: {parseFloat(total).toFixed(2)} €</p>
                    <p className="shopcartOrder-qty">Quantity: {quantity}</p>
                    {msgSaveChanges ? <p className="shopcartOrder-dontforget">Don't forget to save changes!</p> : <p className="shopcartOrder-dontforget"></p>}   
                </div>
            </div>
        </div>
    );
}

export default ShopcartOrder;