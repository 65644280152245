//Style
import "./AdminAddUser.css";

//Packages
import React, { useState, useContext, useEffect } from "react";
import 'react-toastify/dist/ReactToastify.css';
import { Authcontext } from "../../../../login/context/auth-context";
import { useHistory } from "react-router";

// Dialog
import DeleteDialog from "../../../../shared/DeleteDialog";
import { changeDialogVisibility } from '../../../../redux/actions/dialogActions';
import { useSelector, useDispatch } from 'react-redux';

// -- FUNCTION
const AdminAddUser = (props) => {

  const auth = useContext(Authcontext);
  const history = useHistory();
  
  //States form
  const [formValues, setFormValues] = useState({
    email: "",
    password: "",
    companyName: "",
    customerId: "",
    phoneNumber: "",
    billingAdress: "",
    billingAdressCity: "",
    billingAdressCountry: "",
    billingAdressZipCode: "",
    shippingAdress1: "",
    shippingAdress1City: "",
    shippingAdress1Country: "",
    shippingAdress1ZipCode: "",
    shippingAdress2: "",
    shippingAdress2City: "",
    shippingAdress2Country: "",
    shippingAdress2ZipCode: "",
    shippingAdress3: "",
    shippingAdress3City: "",
    shippingAdress3Country: "",
    shippingAdress3ZipCode: "",
  });


  const changeHandler = e => {
    setFormValues({...formValues, [e.target.name]: e.target.value})
  }

  //Post to database
  const postToDatabse = async event => {
    try {
      const response = await fetch(process.env.REACT_APP_BACKEND_URL + '/users', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${auth.token}`
        },
        body: JSON.stringify({
          email: formValues.email,
          password: formValues.password,
          companyName: formValues.companyName,
          customerId: formValues.customerId,
          discount: 0,
          phoneNumber: formValues.phoneNumber,
          billingAdress: formValues.billingAdress,
          billingAdressCity: formValues.billingAdressCity,
          billingAdressCountry: formValues.billingAdressCountry,
          billingAdressZipCode: formValues.billingAdressZipCode,
          shippingAdress1: formValues.shippingAdress1,
          shippingAdress1City: formValues.shippingAdress1City,
          shippingAdress1Country: formValues.shippingAdress1Country,
          shippingAdress1ZipCode: formValues.shippingAdress1ZipCode,
          shippingAdress2: formValues.shippingAdress2,
          shippingAdress2City: formValues.shippingAdress2City,
          shippingAdress2Country: formValues.shippingAdress2Country,
          shippingAdress2ZipCode: formValues.shippingAdress2ZipCode,
          shippingAdress3: formValues.shippingAdress3,
          shippingAdress3City: formValues.shippingAdress3City,
          shippingAdress3Country: formValues.shippingAdress3Country,
          shippingAdress3ZipCode: formValues.shippingAdress3ZipCode
        })
      });
      const responseData = await response.json();
      console.log(responseData);
      
    }  catch (err) {
      console.log(err);
    }
    history.push('/admin/users/');
  };

  // Dialog
  function testSave(){
    chgVisibility();
  }
  const dialogVisibility = useSelector(state => state.dialogVisibility);
  const { visibility } = dialogVisibility;
  const dispatch = useDispatch();
  const chgVisibility = () => {
      dispatch(changeDialogVisibility(!visibility));
  }

  // Possibility of creating user only if some camps are filled
  const [filledCamps,setFilledCamps] = useState(false);

  useEffect(() => {
    if(formValues.email === "" || formValues.password === "" || formValues.customerId === "" || formValues.companyName === ""){
      setFilledCamps(false);
    }
    else{
      setFilledCamps(true);
    }
  },[formValues.email,formValues.password,formValues.companyName,formValues.customerId]);

  // Return
  return (
      <div className="editUser">
        <div className="container_title">
          <h1>Add User</h1>
          {filledCamps ?
            <button className="button_save" type="button" style={{float:"right", marginTop:"30px"}} onClick={testSave}>SAVE</button>
          :
            <button className="button_disabled" type="button" style={{float:"right", marginTop:"30px"}}>SAVE</button>
          }
        </div>
        <form>
            <div className="editUser_email">
              <p>Email <span style={{color:"red", fontWeight:"bold"}}>*</span></p>
              <input onChange={changeHandler} name="email" placeholder="Email"></input>
            </div>
            <div className="editUser_password">
              <p>Password <span style={{color:"red", fontWeight:"bold"}}>*</span></p>
              <input onChange={changeHandler} name="password" placeholder="Password"></input>
            </div>
            <div className="company_details_div">
              <div className="company_details_div_1">
                <p>Company Details</p>
              </div>
              <div className="company_details_div_companyName">
                <p>Company Name <span style={{color:"red", fontWeight:"bold"}}>*</span></p>
                <input  onChange={changeHandler} name="companyName" width="85%" placeholder="Company Name" ></input>
              </div>
              <div className="company_details_div_companyName">
                <p>Customer ID <span style={{color:"red", fontWeight:"bold"}}>*</span></p>
                <input onChange={changeHandler} name="customerId" placeholder="Customer ID"></input>
              </div>
              <div className="company_details_div_companyName">
                <p>Phone Number</p>
                <input onChange={changeHandler} name="phoneNumber" placeholder="Phone number" ></input>
              </div>
              <div className="company_details_div_companyName">
                <p>Billing Address</p>
                <input onChange={changeHandler} name="billingAdress" placeholder="Billing Adress" ></input>
              </div>
              <div className="company_details_div_cityCountry">
                <p>City</p>
                <input onChange={changeHandler} name="billingAdressCity" placeholder="City"></input>
              </div>
              <div className="company_details_div_cityCountry">
                <p>Country</p>
                <input onChange={changeHandler} name="billingAdressCountry" placeholder="Country" ></input>
              </div>
              <div className="company_details_div_companyName">
                <p>Zip Code</p>
                <input onChange={changeHandler} name="billingAdressZipCode" placeholder="Zip code" ></input>
              </div>
            </div>

            <div className="shippingAdress_div">
              <div className="company_details_div_1">
                <p>Shipping Address 01</p>
              </div>
              <div className="company_details_div_companyName">
                <p>Address</p>
                <input onChange={changeHandler} name="shippingAdress1" placeholder="Adress" ></input>
              </div>
              <div className="company_details_div_cityCountry">
                <p>City</p>
                <input onChange={changeHandler} name="shippingAdress1City" placeholder="City"></input>
              </div>
              <div className="company_details_div_cityCountry">
                <p>Country</p>
                <input onChange={changeHandler} name="shippingAdress1Country" placeholder="Country" ></input>
              </div>
              <div className="company_details_div_companyName">
                <p>Zip Code</p>
                <input onChange={changeHandler} name="shippingAdress1ZipCode" placeholder="Zip code" ></input>
              </div>
            </div>

            <div className="shippingAdress_div">
              <div className="company_details_div_1">
                <p>Shipping Address 02</p>
              </div>
              <div className="company_details_div_companyName">
                <p>Address</p>
                <input onChange={changeHandler} name="shippingAdress2" placeholder="Adress"></input>
              </div>
              <div className="company_details_div_cityCountry">
                <p>City</p>
                <input onChange={changeHandler} name="shippingAdress2City" placeholder="City"></input>
              </div>
              <div className="company_details_div_cityCountry">
                <p>Country</p>
                <input onChange={changeHandler} name="shippingAdress2Country" placeholder="Country" ></input>
              </div>
              <div className="company_details_div_companyName">
                <p>Zip Code</p>
                <input onChange={changeHandler} name="shippingAdress2ZipCode" placeholder="Zip code" ></input>
              </div>
            </div>

            <div className="shippingAdress_div">
              <div className="company_details_div_1">
                <p>Shipping Address 03</p>
              </div>
              <div className="company_details_div_companyName">
                <p>Address</p>
                <input onChange={changeHandler} name="shippingAdress3" placeholder="Adress" ></input>
              </div>
              <div className="company_details_div_cityCountry">
                <p>City</p>
                <input onChange={changeHandler} name="shippingAdress3City" placeholder="City"></input>
              </div>
              <div className="company_details_div_cityCountry">
                <p>Country</p>
                <input onChange={changeHandler} name="shippingAdress3Country" placeholder="Country" ></input>
              </div>
              <div className="company_details_div_companyName">
                <p>Zip Code</p>
                <input onChange={changeHandler} name="shippingAdress3ZipCode" placeholder="Zip code" ></input>
              </div>
            </div>
            </form>
            {!visibility ?
              <DeleteDialog
                type="save" 
                action={postToDatabse} 
                title="CREATE USER"
                text="Are you sure you want to create user?" 
              />
            :
            <span></span>
          }
        </div>
    
  );
};

export default AdminAddUser;
