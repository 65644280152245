//Style
import './StockError.css';

//Components
import Header from '../../../shared/header/Header';

const StockError = props => {

    return(
        <div>
            <Header />
            <p class="stockerror_message">Some products have run out of stock during the time you did your order.</p>
            <p class="stockerror_message">Please try making a new order or contact our team.</p>
        </div>
    )
}

export default StockError;