import styles from './style.module.css';

const SizeInfo = props => {
    return(
        <div className={styles.sizeInfoDiv}>
        <div className={styles.size}><p>{props.size}</p></div>
        <div className={styles.stock}><p>{props.qty}</p></div>
        <p className={styles.price}>{parseFloat(props.price).toFixed(2)}€</p>
        <p className={styles.price}>{parseFloat(props.pvp).toFixed(2)}€</p>
    </div>
    )
}

export default SizeInfo;