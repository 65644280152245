import './style.css';

import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';

import SmallComponent from '../../sales/components/SmallComponent';
import Component3 from '../../components/Component3';
import Component4 from '../../components/Component4';

import { FiCalendar } from "react-icons/fi";

import { useState, useEffect, useContext } from 'react';
import { Authcontext } from '../../../../login/context/auth-context';
import Spi from '../../../../shared/animations/Spi';

const DashboardOverview = () => {

  const auth = useContext(Authcontext);
      //Fetch dashboard overview data
      const [loadedData, setLoadedData] = useState([]);
      useEffect(() => {
        const sendRequest = async () => {
            try{
                const response = await fetch(process.env.REACT_APP_BACKEND_URL + '/dashboard/overview', {
                    method: 'GET',
                    headers: {
                        'Authorization': `Bearer ${auth.token}`
                    }
                });

                const responseData = await response.json();
    
                setLoadedData(responseData);
                console.log(responseData);
            } catch(err){
                console.log(err);
            }
        };
        sendRequest();
    }, [auth.token]);

    const data = [
        {
          name: 'Monday',
          visits: 4,
        },
        {
          name: 'Tuesday',
          visits: 10,
        },
        {
          name: 'Wednesday',
          visits: 3,
        },
        {
          name: 'Thursday',
          visits: 0,
        },
        {
          name: 'Friday',
          visits: 25,
        },
        {
          name: 'Saturday',
          visits: 1,
        },
        {
          name: 'Sunday',
          visits: 17,
        },
      ];

  if(loadedData){
    return(
      <div className="dashboard">
          <div className="dashboard_overview_mainDiv_titleDiv">
          <div className="dashboard_overview_titleDiv">
              <h1>Overview</h1>
              <div className="dashboard_optionsDiv"><FiCalendar className="dashboard_optionsDiv_icon" /><p>All Time</p></div>
          </div>
          </div>

          <div className="dashmoard_mainDiv">
          <div className="dashboard_overview_left">
            <div className="dashboard_overview_left_smallComponents">
              <SmallComponent title="Visits" value="73"/>
              <SmallComponent title="Clients" value={loadedData.users}/>
              <SmallComponent title="Products" value={loadedData.products}/>
            </div>
            <div className="dashboard_overview_left_graphic">
              <div className="dashboard_overview_left_graphic_div">
                <ResponsiveContainer width="98%" height="98%">
                  <LineChart
                    width={500}
                    height={300}
                    data={data}
                    margin={{
                      top: 25,
                      right: 30,
                      left: 10,
                      bottom: 5,
                    }}
                  >
                    <CartesianGrid strokeDasharray="3 3" />
                    <XAxis dataKey="name" />
                    <YAxis />
                    <Tooltip />
                    <Legend />
                    <Line type="monotone" dataKey="visits" stroke="#82ca9d" activeDot={{ r: 8 }} />
                  </LineChart>
                </ResponsiveContainer>
              </div>
            </div>
          </div>
          <div className="dashboard_overview_right">
            <Component3 title="Cart Convertion Rate" value="32%" />
            <Component4 title="Cart Abandonment Rate" value="68%"/>
          </div>
        </div> 
      </div>
  )
  }else return <Spi />

}

export default DashboardOverview;