import React from "react";

import './UserFooter.css';

const UserFooter = () => {
    return(
        <footer className="div-login_footer2">
            <div className="div_footer_information2">
                <div className="div-footer-email2">
                    <p className="div-footer-email-contact2">FOR ANY QUESTION FELL FREE TO CONTACT +31 71 589 00 11</p>
                    <a className="div-footer-about-a" target="_tab" href="https://trademixpt-my.sharepoint.com/:f:/g/personal/aqa_giga_trademixpt_onmicrosoft_com/Eo_tZBmBdr1InBkwaomvstQBj0Z6erxGcFUBTCQqmAfefA?e=Oc02i8"><p className="div-footer-email-contact2">DOWNLOAD PHOTO CATALOG</p></a>
                </div>
                <div className="div-footer-about">
                    <p className="div-footer-about-title">ABOUT OUR BRAND</p>
                    <a className="div-footer-about-a" href={process.env.REACT_APP_LINK_STORY}><p className="div-footer-about-links">Our Story</p></a>
                    <a className="div-footer-about-a" href={process.env.REACT_APP_LINK_NEWS}><p className="div-footer-about-links">News</p></a>
                    <a className="div-footer-about-a" href={process.env.REACT_APP_LINK_LOOKBOOK}><p className="div-footer-about-links">Lookbook</p></a>
                </div>
                <div className="div-footer-info">
                    <p className="div-footer-about-title">INFORMATION</p>
                    <a className="div-footer-about-a" target="_tab" href={process.env.REACT_APP_LINK_TERMSANDCONDITIONS}><p className="div-footer-about-links">Terms and Conditions</p></a>
                    <a className="div-footer-about-a" href={process.env.REACT_APP_LINK_PRIVACY}><p className="div-footer-about-links">Privacy Policy</p></a>
                    <a className="div-footer-about-a" href={process.env.REACT_APP_LINK_COOKIES}><p className="div-footer-about-links">Cookies Privacy</p></a>
                </div>
                <div className="div-footer-contact">
                    <p className="div-footer-about-links">{process.env.REACT_APP_LINK_PHONE}</p>
                    <p className="div-footer-about-links">{process.env.REACT_APP_LINK_EMAIL}</p>
                    <p className="div-footer-about-links">{process.env.REACT_APP_LINK_ADDRESS}</p>
                    <p className="div-footer-about-links">{process.env.REACT_APP_LINK_COUNTRY}</p>
                </div>
            </div>
            <div className="line_footer">

            </div>
            <p className="p_footer_developer">Developed by <b>Perto Design</b></p>
        </footer>
    )
}

export default UserFooter;