import './ProductQuantity.css';

import {useSelector} from 'react-redux';

const ProductQuantity = props => {

    const priceVisibility = useSelector(state => state.priceVisibility);
    const { visibility } = priceVisibility;

    if(props.stock === -1) return <div></div>
    else return(
        <div className="qtySizeData-div">
            <div className="qtySizeData-div-size">
                <p>{props.size}</p>
            </div>
            <div className="qtySizeData-div-stock">
                {props.stock === 0 ?
                <p className="redColor">{props.stock}</p>
                :
                <p className="qtySizeData-div-stock-green">{Number(props.stock)}</p>
                }
            </div>
            <div className="qtySizeData-div-qty">
                {props.stock === 0 ?
                <input onChange={props.change} name={props.name} disabled></input>
                :
                <input onChange={props.change} name={props.name} placeholder="0" type="number" min="0" max={props.stock}></input>
                }
            </div>
            {
                visibility === true ?
                    <div>
                        <p className="qtySizeData-div-price">{parseFloat(props.price).toFixed(2)}€</p>
                        <p className="qtySizeData-div-pricepvp">{parseFloat(props.pvp).toFixed(2)}€</p>
                    </div>
                :
                    <p></p>
            }
        </div>
    );
};

export default ProductQuantity;