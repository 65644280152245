import './Header.css';

import {useSelector, useDispatch} from 'react-redux';
import { changeVisibility } from '../../../redux/actions/visiblityActions';

import { useContext } from 'react';
import { Authcontext } from "../../../login/context/auth-context";

import { NavLink } from 'react-router-dom';
import { FiShoppingCart, FiUser, FiEye, FiEyeOff } from "react-icons/fi";
import logo from '../../../images/logo.png';
import Banner from '../../../shared/formElements/Banner';

const Header = () => {

    const cart = useSelector(state => state.cart);
    const { cartItems } = cart;
    const num = cartItems.length;

    const priceVisibility = useSelector(state => state.priceVisibility);
    const { visibility } = priceVisibility;

    const dispatch = useDispatch();
    const chgVisibility = () => {
        dispatch(changeVisibility(!visibility));
    }

    const auth = useContext(Authcontext);

    function logout(){
        auth.logout();
    }

    return(
        <div className="user-header">
           <div className="user-header-size">
                <div className="user-div_link_produtos_logo">
                <NavLink to="/user/banner">
                    <img src={logo} alt="logo" />
                </NavLink>
                </div>
                <div className="user-div_link_produtos">
                    <NavLink to="/user/catalog" activeClassName="selected">
                        <p className="user-link">Catalog</p>
                    </NavLink>
                </div>
                <div className="user-div_link_encomendas">
                    <NavLink to="/user/orders" activeClassName="selected">
                        <p className="user-link">Orders</p>
                    </NavLink>
                </div>
                <div className="user-div_link_sair">
                        <p onClick={logout} className="user-logout user-link">Logout</p>
                </div>
                <div className="user-div_link_conta">
                    <NavLink to="/user/account" activeClassName="selected">
                        <FiUser  className="user-div_link_conta-iconAccount"/>
                    </NavLink>
                </div>
                <div className="user-div_link_conta" onClick={chgVisibility}>
                    {visibility ?
                        <FiEyeOff className="user-div_link_conta-iconAccount"/>
                    :
                        <FiEye className="user-div_link_conta-iconAccount"/>
                    }
                </div>
                <div className="user-div_link_cart">
                    <NavLink to="/user/catalog/shopcart">
                        <FiShoppingCart className="div_link_cart-icon"/>
                    </NavLink>
                </div>
                {num > 0 ? (
                    <div className="user-div_link_numcart">
                        <div className="user-div_link_numcart-tiems">
                            <p>{num}</p>
                        </div>
                    </div>
                ) : <div></div>}
            </div>
            <Banner />
        </div>
    )
}

export default Header;