import styles from "./style.module.css";

const SmallComponent = props => {
    return(
        <div className={styles.main}>
            <p className={styles.title}>{props.title}</p>
            <div className={styles.line}></div>
            {props.title === 'Orders' || props.title === 'Visits' || props.title === 'Clients' || props.title === 'Products' ?
            <p className={styles.value}>{props.value}</p> :
            <p className={styles.value}>{parseFloat(props.value).toFixed(2)}€</p>
            }
            
        </div>
    )
}

export default SmallComponent;