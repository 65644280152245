import './Product.css';

import {Link} from 'react-router-dom';
import {useSelector} from 'react-redux';

const Product = props => {

    //Filter category name
    var categoryName = props.product.category.split(" ")[0];

    // Get user discount
    const discountValue = useSelector(state => state.userInfo);
    const { value } = discountValue;
    const discount = (100-value)/100;
    console.log("valor disconto: " + discount);


    const prices2 = [];

        if(props.product.price20 !== 0 && Number(props.product.price20)) prices2.push(props.product.price20*discount);
        if(props.product.price21 !== 0 && Number(props.product.price21)) prices2.push(props.product.price21*discount);
        if(props.product.price22 !== 0 && Number(props.product.price22)) prices2.push(props.product.price22*discount);
        if(props.product.price23 !== 0 && Number(props.product.price23)) prices2.push(props.product.price23*discount);
        if(props.product.price24 !== 0 && Number(props.product.price24)) prices2.push(props.product.price24*discount);
        if(props.product.price25 !== 0 && Number(props.product.price25)) prices2.push(props.product.price25*discount);
        if(props.product.price26 !== 0 && Number(props.product.price26)) prices2.push(props.product.price26*discount);
        if(props.product.price27 !== 0 && Number(props.product.price27)) prices2.push(props.product.price27*discount);
        if(props.product.price28 !== 0 && Number(props.product.price28)) prices2.push(props.product.price28*discount);
        if(props.product.price29 !== 0 && Number(props.product.price29)) prices2.push(props.product.price29*discount);
        if(props.product.price30 !== 0 && Number(props.product.price30)) prices2.push(props.product.price30*discount);
        if(props.product.price31 !== 0 && Number(props.product.price31)) prices2.push(props.product.price31*discount);
        if(props.product.price32 !== 0 && Number(props.product.price32)) prices2.push(props.product.price32*discount);
        if(props.product.price33 !== 0 && Number(props.product.price33)) prices2.push(props.product.price33*discount);
        if(props.product.price34 !== 0 && Number(props.product.price34)) prices2.push(props.product.price34*discount);
        if(props.product.price35 !== 0 && Number(props.product.price35)) prices2.push(props.product.price35*discount);
        if(props.product.price36 !== 0 && Number(props.product.price36)) prices2.push(props.product.price36*discount);
        if(props.product.price37 !== 0 && Number(props.product.price37)) prices2.push(props.product.price37*discount);
        if(props.product.price38 !== 0 && Number(props.product.price38)) prices2.push(props.product.price38*discount);
        if(props.product.price39 !== 0 && Number(props.product.price39)) prices2.push(props.product.price39*discount);
        if(props.product.price40 !== 0 && Number(props.product.price40)) prices2.push(props.product.price40*discount);
        if(props.product.price41 !== 0 && Number(props.product.price41)) prices2.push(props.product.price41*discount);
        if(props.product.price42 !== 0 && Number(props.product.price42)) prices2.push(props.product.price42*discount);
        if(props.product.price43 !== 0 && Number(props.product.price43)) prices2.push(props.product.price43*discount);
        if(props.product.price44 !== 0 && Number(props.product.price44)) prices2.push(props.product.price44*discount);
        if(props.product.price45 !== 0 && Number(props.product.price45)) prices2.push(props.product.price45*discount);
        if(props.product.price46 !== 0 && Number(props.product.price46)) prices2.push(props.product.price46*discount);

    const maxPrice = parseFloat(Math.max.apply(null, prices2)).toFixed(2);
    const minPrice = parseFloat(Math.min.apply(null, prices2)).toFixed(2);

    const priceVisibility = useSelector(state => state.priceVisibility);
    const { visibility } = priceVisibility;

    return(
        visibility === true ? 
            <Link to={`/user/catalog/product/${props.product.sku}`} style={{textDecoration: 'none'}}>
            <div className="admin-div-product">
                <div className="admin-imagem_produto">
                    <img src={props.product.image1} alt="logo" />
                </div>
                <div className="sku_and_categorie">
                    <p className="div_produto-categorie">{categoryName}</p>
                    <p className="div_produto-sku">{"SKU " + props.product.sku }</p>
                </div>
                <p className="div_produto-title">{props.product.title} {props.product.color}</p>
                {prices2.length === 0 ? 
                    <p className="div_produto-price-notAvailable">Not Available</p>
                :
                    <p className="admin-div_produto-price">{minPrice}€ - {maxPrice}€</p>
                }
            </div>
            </Link>        
        :
            <Link to={`/user/catalog/product/${props.product.sku}`} style={{textDecoration: 'none'}}>
                <div className="admin-div-product">
                    <div className="admin-imagem_produto">
                        <img src={props.product.image1} alt="logo" />
                    </div>
                    <div className="sku_and_categorie">
                        <p className="div_produto-categorie">{categoryName}</p>
                        <p className="div_produto-sku">{"SKU " + props.product.sku }</p>
                    </div>
                    <p className="div_produto-title">{props.product.title} {props.product.color}</p>
                </div>
            </Link>   
    )
}

export default Product;