//Style
import './AdminHeader.css';

//Packages
import React, { useContext } from 'react';
import { FiActivity, FiBookOpen, FiUser, FiUsers, FiFileText, FiGrid, FiLogOut, FiDivideCircle, FiDatabase } from "react-icons/fi";
import { NavLink, useLocation } from 'react-router-dom';
//import logo from '../../../user/shared/images/logoheader.svg';
import logo from '../../../images/logo.png';
import { Authcontext } from "../../../login/context/auth-context";
import { useSelector } from 'react-redux';

// --FUNCTION
const AdminHeader = () => {

    //teste delete dialog

    const deleteDialog = useSelector(state => state.dialogVisibility);
    const { visibility } = deleteDialog;

  const auth = useContext(Authcontext);

  function logout(){
      auth.logout();
  }

const location = useLocation();   

if(location.pathname.includes("dashboard")){
    var styles = {
        "height" : "200px"
    }
}

//<div className="ordersNumber"><p>3</p></div>

    // -- RETURN
    if(visibility) return(
        <div className="admin-header">
                <div className="admin-header-link-image">
                    <img src={logo} alt="logo" />
                </div>
                <div className="dqdq" style={styles}>
                    <div className="admin-header-link" >
                        <NavLink to="/admin/dashboard/overview" style={{ textDecoration: 'none' }}>
                            <FiActivity className="admin-link-icon2" />
                            <p className="admin-link">Dashboard</p>
                        </NavLink>
                    </div>
                    <React.Fragment>
                    <div className="admin-header-link">
                        <NavLink to="/admin/dashboard/overview" activeClassName="admin_selected" style={{ textDecoration: 'none' }}>
                            <p className="admin-link2">Overview</p>
                        </NavLink>
                    </div>
                    <div className="admin-header-link">
                        <NavLink to="/admin/dashboard/dashboardSales" activeClassName="admin_selected" style={{ textDecoration: 'none' }}>
                            <p className="admin-link2">Sales</p>
                        </NavLink>
                    </div>
                    <div className="admin-header-link">
                        <NavLink to="/admin/dashboard/dashboardProducts" activeClassName="admin_selected" style={{ textDecoration: 'none' }}>
                            <p className="admin-link2">Products</p>
                        </NavLink>
                    </div>
                    <div className="admin-header-link">
                        <NavLink to="/admin/dashboard/dashboardClients" activeClassName="admin_selected" style={{ textDecoration: 'none' }}>
                            <p className="admin-link2">Clients</p>
                        </NavLink>
                    </div> 
                </React.Fragment>
                
                <div></div>
                
                </div>
                <div className="admin-header-link">
                    <NavLink to="/admin/banner" activeClassName="admin_selected" style={{ textDecoration: 'none' }}>
                        <FiBookOpen className="admin-link-icon2" />
                        <p className="admin-link">Banner</p>
                    </NavLink>
                </div>
                <div className="admin-header-link">
                    <NavLink to="/admin/catalog" activeClassName="admin_selected" style={{ textDecoration: 'none' }}>
                        <FiGrid className="admin-link-icon" />
                        <p className="admin-link">Products</p>
                    </NavLink>
                </div>
                <div className="admin-header-link">
                    <NavLink to="/admin/updateStocks" activeClassName="admin_selected" style={{ textDecoration: 'none' }}>
                        <FiDatabase className="admin-link-icon" />
                        <p className="admin-link">Stocks</p>
                    </NavLink>
                </div>
                <div className="admin-header-link">
                    <NavLink to="/admin/orders" activeClassName="admin_selected" style={{ textDecoration: 'none' }}>
                        <FiFileText className="admin-link-icon" />
                        <p className="admin-link">Orders</p>
                        
                    </NavLink>
                </div>
                <div className="admin-header-link">
                    <NavLink to="/admin/users" activeClassName="admin_selected" style={{ textDecoration: 'none' }}>
                        <FiUsers className="admin-link-icon" />
                        <p className="admin-link">Users</p>
                    </NavLink>
                </div>
                <div className="admin-header-link">
                    <NavLink to="/admin/discounts" activeClassName="admin_selected" style={{ textDecoration: 'none' }}>
                        <FiDivideCircle className="admin-link-icon" />
                        <p className="admin-link">Discounts</p>
                    </NavLink>
                </div>
                <div className="admin-header-link-div-bottom">
                    <p className="admin_name">Admin</p>
                    <p className="admin_email">B2B</p>
                    <div className="admin-header-link2">
                        <NavLink to="/admin/account" activeClassName="admin_selected">
                            <FiUser className="admin-link-icon" />
                            <p className="admin-link">Account</p>
                        </NavLink>
                    </div>
                    <div className="admin-header-link2">
                        <FiLogOut className="admin-link-icon" />
                        <p onClick={logout} className="admin-logout admin-link" style={{ textDecoration: 'none' }}>Logout</p>
                    </div>
                    <p className="admin_pertodesign">Powered by <span>PertoDesign</span></p>
                </div>
            </div>
    )
    else{
        return(
            <div className="admin-header2">
                    <div className="admin-header-link-image">
                        <img src={logo} alt="logo" />
                    </div>
                    <div className="dqdq" style={styles}>
                        <div className="admin-header-link" >
                            <NavLink to="/admin/dashboard/overview" style={{ textDecoration: 'none' }}>
                                <FiActivity className="admin-link-icon2" />
                                <p className="admin-link">Dashboard</p>
                            </NavLink>
                        </div>
                        <React.Fragment>
                        <div className="admin-header-link">
                            <NavLink to="/admin/dashboard/overview" activeClassName="admin_selected" style={{ textDecoration: 'none' }}>
                                <p className="admin-link2">Overview</p>
                            </NavLink>
                        </div>
                        <div className="admin-header-link">
                            <NavLink to="/admin/dashboard/dashboardSales" activeClassName="admin_selected" style={{ textDecoration: 'none' }}>
                                <p className="admin-link2">Sales</p>
                            </NavLink>
                        </div>
                        <div className="admin-header-link">
                            <NavLink to="/admin/dashboard/dashboardProducts" activeClassName="admin_selected" style={{ textDecoration: 'none' }}>
                                <p className="admin-link2">Products</p>
                            </NavLink>
                        </div>
                        <div className="admin-header-link">
                            <NavLink to="/admin/dashboard/dashboardClients" activeClassName="admin_selected" style={{ textDecoration: 'none' }}>
                                <p className="admin-link2">Clients</p>
                            </NavLink>
                        </div> 
                    </React.Fragment>
                    
                    <div></div>
                    
                    </div>
                    <div className="admin-header-link">
                        <NavLink to="/admin/banner" activeClassName="admin_selected" style={{ textDecoration: 'none' }}>
                            <FiBookOpen className="admin-link-icon2" />
                            <p className="admin-link">Banner</p>
                        </NavLink>
                    </div>
                    <div className="admin-header-link">
                        <NavLink to="/admin/catalog" activeClassName="admin_selected" style={{ textDecoration: 'none' }}>
                            <FiGrid className="admin-link-icon" />
                            <p className="admin-link">Catalog</p>
                        </NavLink>
                    </div>
                    <div className="admin-header-link">
                        <NavLink to="/admin/orders" activeClassName="admin_selected" style={{ textDecoration: 'none' }}>
                            <FiFileText className="admin-link-icon" />
                            <p className="admin-link">Orders</p>
                            
                        </NavLink>
                    </div>
                    <div className="admin-header-link">
                        <NavLink to="/admin/users" activeClassName="admin_selected" style={{ textDecoration: 'none' }}>
                            <FiUsers className="admin-link-icon" />
                            <p className="admin-link">Users</p>
                        </NavLink>
                    </div>
                    <div className="admin-header-link-div-bottom">
                        <p className="admin_name">Admin</p>
                        <div className="admin-header-link2">
                            <NavLink to="/admin/account" activeClassName="admin_selected">
                                <FiUser className="admin-link-icon" />
                                <p className="admin-link">Account</p>
                            </NavLink>
                        </div>
                        <div className="admin-header-link2">
                            <FiLogOut className="admin-link-icon" />
                            <p onClick={logout} className="admin-logout admin-link" style={{ textDecoration: 'none' }}>Logout</p>
                        </div>
                        <p className="admin_pertodesign">Powered by <span>PertoDesign</span></p>
                    </div>
                    <div style={{
                        position: "fixed",
                        width: "230px",
                        height: "100%",
                        top: "0",
                        left: "0",
                        right: "0",
                        bottom: "0",
                        backgroundColor: "rgba(0,0,0,0.5)"
                    }}></div>
                </div>
        )
    }
}

export default AdminHeader;