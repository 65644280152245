import firebase from 'firebase';

const firebaseConfig = {
  apiKey: "AIzaSyBk3Py6lqjMDivUERPTUxW9ZSCB24RSUKE",
  authDomain: "react-hosting-73546.firebaseapp.com",
  projectId: "react-hosting-73546",
  storageBucket: "react-hosting-73546.appspot.com",
  messagingSenderId: "752823632157",
  appId: "1:752823632157:web:8f504fdd61e652da7c61f4",
  measurementId: "G-Y9ZG2Y49SR"
};

// Initialize Firebase
const app = firebase.initializeApp(firebaseConfig);
var storage = firebase.storage();

export {
    storage, 
    app
};