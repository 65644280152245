import {Link} from 'react-router-dom';

import './Informacao.css';

import {useSelector} from 'react-redux';

const Informacao = props => {

    const priceVisibility = useSelector(state => state.priceVisibility);
    const { visibility } = priceVisibility;

        return(
            <div className="div_pro_inf">
                <p className="info-category">{props.category}</p>
                <p className="b">{props.title} {props.color}</p>
                {visibility === true ?
                    <p className="c">{props.minPrice}€ - {props.maxPrice}€</p>
                :
                    <p className="c"></p>
                }
                <p className="ll">Description</p>
                <div className="informacao-line"></div>
                <p className="info-description-data">{props.description}</p>
                <p className="ll">Materials</p>
                <div className="informacao-line"></div>
                <p className="info-materials-data">{props.materials}</p>
                {props.stockInfo !== "" ?
                    <p className="restock-info" style={{color:"red", fontSize:"14px", fontWeight:"600", marginTop:"2px", marginBottom:"7px"}}>{props.stockInfo}</p>
                :
                    <p className="restock-info">For stock information send email to {process.env.REACT_APP_LINK_EMAIL}</p>
                }
                <Link to={`/user/catalog/quantity/${props.title}`}>
                    <button className="button_save" style={{width:"100%"}}>CHOOSE SIZES AND QUANTITIES</button>
                </Link>
            </div>
        );
}

export default Informacao;