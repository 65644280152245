import { createStore, combineReducers, applyMiddleware } from 'redux';
import thunk from 'redux-thunk';

import { composeWithDevTools } from 'redux-devtools-extension'

import { cartReducer } from './reducers/cartReducers'
import { getProductDetailsReducer, getProductsReducer } from './reducers/productReducers'
import { getPriceVisibility } from './reducers/visibilityReducers'
import { getDialogVisibility } from './reducers/dialogReducers'
import { getUserInfo } from './reducers/userDiscountReducers';

const reducer = combineReducers({
    cart: cartReducer,
    getProducts: getProductsReducer,
    getProductDetails: getProductDetailsReducer,
    priceVisibility: getPriceVisibility,
    dialogVisibility: getDialogVisibility,
    userInfo: getUserInfo
});

const middleware = [thunk];

const store = createStore(
    reducer, 
    composeWithDevTools(applyMiddleware(...middleware))
);

export default store;