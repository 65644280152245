import './QtyFullSize.css';

import React, { useState  } from "react";

import FullLine from './FullLine';
import { useDispatch } from 'react-redux';
import { addToCart } from '../../../../redux/actions/cartActions';
import ProductQuantity from '../../../../shared/product-quantity/ProductQuantity';
import {useSelector} from 'react-redux';


const QtyFullSize = props => {

    //STATE FORM QUANTITY for SIZE
    const [formValues, setFormValues] = useState({
            qty20: 0,
            qty21: 0,
            qty22: 0,
            qty23: 0,
            qty24: 0,
            qty25: 0,
            qty26: 0,
            qty27: 0,
            qty28: 0,
            qty29: 0,
            qty30: 0,
            qty31: 0,
            qty32: 0,
            qty33: 0,
            qty34: 0,
            qty35: 0,
            qty36: 0,
            qty37: 0,
            qty38: 0,
            qty39: 0,
            qty40: 0,
            qty41: 0,
            qty42: 0,
            qty43: 0,
            qty44: 0,
            qty45: 0,
            qty46: 0
    });

    const changeHandlerNumber = e => {
        if(e.target.value > Number(e.target.max) || e.target.value < Number(e.target.min)){
            e.target.value = 0;
        }else{
            const newValues = {...formValues, [e.target.name]: Number(e.target.value)}
            setFormValues(newValues);
            sumQuantity(newValues);
            sumTotal(newValues);
        }
    };

    const [style, setStyle] = useState();

    const [quantity, setQuantity] = useState(0);
    const [total, setTotal] = useState(0);

    const sumQuantity = (newValues) => {
        setQuantity(newValues.qty20 + newValues.qty21 + newValues.qty22 + newValues.qty23 +
                    newValues.qty24 + newValues.qty25 + newValues.qty26 + newValues.qty27 + newValues.qty28 + newValues.qty29 +
                    newValues.qty30 + newValues.qty31 + newValues.qty32 + newValues.qty33 +
                    newValues.qty34 + newValues.qty35 + newValues.qty36 + newValues.qty37 +
                    newValues.qty38 + newValues.qty39 + newValues.qty40 + newValues.qty41 +
                    newValues.qty42 + newValues.qty43 + newValues.qty44 + newValues.qty45 + newValues.qty46);
    }

    const sumTotal = (newValues) => {
        const variable_total = parseFloat(
            newValues.qty20 * props.product.price20*discount + newValues.qty21 * props.product.price21*discount +
            newValues.qty22 * props.product.price22*discount + newValues.qty23 * props.product.price23*discount +
            newValues.qty24 * props.product.price24*discount + newValues.qty25 * props.product.price25*discount + 
            newValues.qty26 * props.product.price26*discount + newValues.qty27 * props.product.price27*discount + 
            newValues.qty28 * props.product.price28*discount + newValues.qty29 * props.product.price29*discount +
            newValues.qty30 * props.product.price30*discount + newValues.qty31 * props.product.price31*discount +
            newValues.qty32 * props.product.price32*discount + newValues.qty33 * props.product.price33*discount +
            newValues.qty34 * props.product.price34*discount + newValues.qty35 * props.product.price35*discount +
            newValues.qty36 * props.product.price36*discount + newValues.qty37 * props.product.price37*discount +
            newValues.qty38 * props.product.price38*discount + newValues.qty39 * props.product.price39*discount +
            newValues.qty40 * props.product.price40*discount + newValues.qty41 * props.product.price41*discount +
            newValues.qty42 * props.product.price42*discount + newValues.qty43 * props.product.price43*discount +
            newValues.qty44 * props.product.price45*discount + newValues.qty46 * props.product.price46*discount).toFixed(2);
        setTotal(variable_total);
    }

    const dispatch = useDispatch();

    const addToCart1 = e => {
        e.preventDefault();
        dispatch(addToCart(props.product.sku, props.product.title, props.product.color, formValues.qty20, formValues.qty21, formValues.qty22, formValues.qty23, formValues.qty24, formValues.qty25, formValues.qty26, formValues.qty27, formValues.qty28,formValues.qty29,
            formValues.qty30,formValues.qty31,formValues.qty32, formValues.qty33, formValues.qty34,
            formValues.qty35, formValues.qty36, formValues.qty37, formValues.qty38, formValues.qty39, formValues.qty40, formValues.qty41, formValues.qty42, formValues.qty43, formValues.qty44, formValues.qty45, formValues.qty46, 
            quantity, total, props.product.price20*discount, props.product.price21*discount, props.product.price22*discount, props.product.price23*discount, props.product.price24*discount, props.product.price25*discount, props.product.price26*discount,
            props.product.price27*discount, props.product.price28*discount, props.product.price29*discount, props.product.price30*discount,
            props.product.price31*discount, props.product.price32*discount, props.product.price33*discount, props.product.price34*discount,
            props.product.price35*discount, props.product.price36*discount, props.product.price37*discount, props.product.price38*discount,
            props.product.price39*discount, props.product.price40*discount, props.product.price41*discount, props.product.price42*discount, props.product.price43, props.product.price44, props.product.price45, props.product.price46,
            props.product.image1, props.product.stock20, props.product.stock21, props.product.stock22, props.product.stock23, props.product.stock24, props.product.stock25, props.product.stock26, props.product.stock27, props.product.stock28,
            props.product.stock29, props.product.stock30, props.product.stock31, props.product.stock32,
            props.product.stock33, props.product.stock34, props.product.stock35, props.product.stock36,
            props.product.stock37, props.product.stock38, props.product.stock39, props.product.stock40,
            props.product.stock41, props.product.stock42, props.product.stock43,props.product.stock44,props.product.stock45, props.product.stock46,
            props.product.pvp20, props.product.pvp21, props.product.pvp22, props.product.pvp23, props.product.pvp24, props.product.pvp25, props.product.pvp26,
            props.product.pvp27, props.product.pvp28, props.product.pvp29, props.product.pvp30,
            props.product.pvp31, props.product.pvp32, props.product.pvp33, props.product.pvp34,
            props.product.pvp35, props.product.pvp36, props.product.pvp37, props.product.pvp38,
            props.product.pvp39, props.product.pvp40, props.product.pvp41, props.product.pvp42, props.product.pvp43,props.product.pvp44,props.product.pvp45,props.product.pvp46,
            props.product.ava20, props.product.ava21, props.product.ava22, props.product.ava23, props.product.ava24, props.product.ava25, props.product.ava26, props.product.ava27, props.product.ava28, props.product.ava29, props.product.ava30,
            props.product.ava31, props.product.ava32, props.product.ava33, props.product.ava34,
            props.product.ava35, props.product.ava36, props.product.ava37, props.product.ava38,
            props.product.ava39, props.product.ava40, props.product.ava41, props.product.ava42, props.product.ava43, props.product.ava44, props.product.ava45, props.product.ava46));
            setStyle({display: "none"})
    }

    const priceVisibility = useSelector(state => state.priceVisibility);
    const { visibility } = priceVisibility;

    // Get user discount
    const discountValue = useSelector(state => state.userInfo);
    const { value } = discountValue;
    const discount = (100-value)/100;
    console.log("valor disconto: " + discount);

    return(
        <div className="qtyfullsize" style={style}>
            <div className="quantitySize-image">
                <p className="quantitySize-image-sku">{props.product.sku}</p>
                <div className="quantitySize-image-divimg">
                    <img src={props.product.image1} alt="" />
                </div>
                <p className="quantitySize-image-sku">{props.product.color}</p>
            </div>
            <form onSubmit={addToCart1}>
                <div className="QUANTITY_quantitySize">
                    {props.product.ava20 ? <ProductQuantity  change={changeHandlerNumber} name="qty20" price={props.product.price20*discount} pvp={props.product.pvp20} stock={props.product.stock20} size="20"/> : <div></div>}
                    {props.product.ava21 ? <ProductQuantity  change={changeHandlerNumber} name="qty21" price={props.product.price21*discount} pvp={props.product.pvp21} stock={props.product.stock21} size="21"/> : <div></div>}
                    {props.product.ava22 ? <ProductQuantity  change={changeHandlerNumber} name="qty22" price={props.product.price22*discount} pvp={props.product.pvp22} stock={props.product.stock22} size="22"/> : <div></div>}
                    {props.product.ava23 ? <ProductQuantity  change={changeHandlerNumber} name="qty23" price={props.product.price23*discount} pvp={props.product.pvp23} stock={props.product.stock23} size="23"/> : <div></div>}
                    {props.product.ava24 ? <ProductQuantity  change={changeHandlerNumber} name="qty24" price={props.product.price24*discount} pvp={props.product.pvp24} stock={props.product.stock24} size="24"/> : <div></div>}
                    {props.product.ava25 ? <ProductQuantity  change={changeHandlerNumber} name="qty25" price={props.product.price25*discount} pvp={props.product.pvp25} stock={props.product.stock25} size="25"/> : <div></div>}
                    {props.product.ava26 ? <ProductQuantity  change={changeHandlerNumber} name="qty26" price={props.product.price26*discount} pvp={props.product.pvp26} stock={props.product.stock26} size="26"/> : <div></div>}
                    {props.product.ava27 ? <ProductQuantity  change={changeHandlerNumber} name="qty27" price={props.product.price27*discount} pvp={props.product.pvp27} stock={props.product.stock27} size="27"/> : <div></div>}
                    {props.product.ava28 ? <ProductQuantity  change={changeHandlerNumber} name="qty28" price={props.product.price28*discount} pvp={props.product.pvp28} stock={props.product.stock28} size="28"/> : <div></div>}
                    {props.product.ava29 ? <ProductQuantity  change={changeHandlerNumber} name="qty29" price={props.product.price29*discount} pvp={props.product.pvp29} stock={props.product.stock29} size="29"/> : <div></div>}
                    {props.product.ava30 ? <ProductQuantity  change={changeHandlerNumber} name="qty30" price={props.product.price30*discount} pvp={props.product.pvp30} stock={props.product.stock30} size="30"/> : <div></div>}
                    {props.product.ava31 ? <ProductQuantity  change={changeHandlerNumber} name="qty31" price={props.product.price31*discount} pvp={props.product.pvp31} stock={props.product.stock31} size="31"/> : <div></div>}
                    {props.product.ava32 ? <ProductQuantity  change={changeHandlerNumber} name="qty32" price={props.product.price32*discount} pvp={props.product.pvp32} stock={props.product.stock32} size="32"/> : <div></div>}
                    {props.product.ava33 ? <ProductQuantity  change={changeHandlerNumber} name="qty33" price={props.product.price33*discount} pvp={props.product.pvp33} stock={props.product.stock33} size="33"/> : <div></div>}
                    {props.product.ava34 ? <ProductQuantity  change={changeHandlerNumber} name="qty34" price={props.product.price34*discount} pvp={props.product.pvp34} stock={props.product.stock34} size="34"/> : <div></div>}
                    {props.product.ava35 ? <ProductQuantity  change={changeHandlerNumber} name="qty35" price={props.product.price35*discount} pvp={props.product.pvp35} stock={props.product.stock35} size="35"/> : <div></div>}
                    {props.product.ava36 ? <ProductQuantity  change={changeHandlerNumber} name="qty36" price={props.product.price36*discount} pvp={props.product.pvp36} stock={props.product.stock36} size="36"/> : <div></div>}
                    {props.product.ava37 ? <ProductQuantity  change={changeHandlerNumber} name="qty37" price={props.product.price37*discount} pvp={props.product.pvp37} stock={props.product.stock37} size="37"/> : <div></div>}
                    {props.product.ava38 ? <ProductQuantity  change={changeHandlerNumber} name="qty38" price={props.product.price38*discount} pvp={props.product.pvp38} stock={props.product.stock38} size="38"/> : <div></div>}
                    {props.product.ava39 ? <ProductQuantity  change={changeHandlerNumber} name="qty39" price={props.product.price39*discount} pvp={props.product.pvp39} stock={props.product.stock39} size="39"/> : <div></div>}
                    {props.product.ava40 ? <ProductQuantity  change={changeHandlerNumber} name="qty40" price={props.product.price40*discount} pvp={props.product.pvp40} stock={props.product.stock40} size="40"/> : <div></div>}
                    {props.product.ava41 ? <ProductQuantity  change={changeHandlerNumber} name="qty41" price={props.product.price41*discount} pvp={props.product.pvp41} stock={props.product.stock41} size="41"/> : <div></div>}
                    {props.product.ava42 ? <ProductQuantity  change={changeHandlerNumber} name="qty42" price={props.product.price42*discount} pvp={props.product.pvp42} stock={props.product.stock42} size="42"/> : <div></div>}
                    {props.product.ava43 ? <ProductQuantity  change={changeHandlerNumber} name="qty43" price={props.product.price43*discount} pvp={props.product.pvp43} stock={props.product.stock43} size="43"/> : <div></div>}
                    {props.product.ava44 ? <ProductQuantity  change={changeHandlerNumber} name="qty44" price={props.product.price44*discount} pvp={props.product.pvp44} stock={props.product.stock44} size="44"/> : <div></div>}
                    {props.product.ava45 ? <ProductQuantity  change={changeHandlerNumber} name="qty45" price={props.product.price45*discount} pvp={props.product.pvp45} stock={props.product.stock45} size="45"/> : <div></div>}
                    {props.product.ava46 ? <ProductQuantity  change={changeHandlerNumber} name="qty46" price={props.product.price46*discount} pvp={props.product.pvp46} stock={props.product.stock46} size="46"/> : <div></div>}
                </div>
            
                <div>
                    <div className="qtyfullsize-infoprice-div"><div className="qtyfullsize-infoprice"><p>Quantity: {quantity}</p></div></div>
                    {visibility ? <div className="qtyfullsize-infoprice-div"><div className="qtyfullsize-infoprice"><p>Total: {total} €</p></div></div> : 
                    <div className="qtyfullsize-infoprice-div"><div className="qtyfullsize-infoprice"><p>Total: </p></div></div>}
                    <div className="qtyfullsize-infoprice-div"><div className="qtyfullsize-infoprice-noborder">
                    {quantity===0 ?
                        <button className="button_save" style={{width:"100%"}} disabled="true">ADD TO CART</button>
                    :
                        <button className="button_save" style={{width:"100%"}}>ADD TO CART</button>
                    }
                    </div></div>
                
                </div>
            </form>

            <FullLine />
        </div>   
    )
}

export default QtyFullSize;