import './Input.css';

const Input = props => {

    const style = {
        width : props.width,
        height : props.height,
        marginBottom : props.marginBottom,
        marginTop : props.marginTop,
    }

    return(
        <input
            name={props.name}
            className="formElements-input" 
            placeholder={props.placeholder}
            onChange={props.changeHandler}
            defaultValue={props.defaultValue}
            style={style}>
        </input>
    );
};

export default Input;