import Spi from "../../../../shared/animations/Spi";
import styles from "./style2.module.css";

const BigComponent = props => {
    if(props.shoe1){
        return(
            <div className={styles.main}>
                <p className={styles.title}>{props.title}</p>
                <div className={styles.line}></div>
                
                <p className={styles.name}>
                    <div className={styles.point}>
                    </div>{props.shoe1} 
                    <span className={styles.value}>{props.value1}€</span>
                </p>
                <p className={styles.name}><div className={styles.point1}></div>{props.shoe2} <span className={styles.value1}>{props.value2}€</span></p>
                <p className={styles.name}><div className={styles.point2}></div>{props.shoe3} <span className={styles.value2}>{props.value3}€</span></p>
                <p className={styles.name}><div className={styles.point3}></div>{props.shoe4} <span className={styles.value3}>{props.value4}€</span></p>
                <p className={styles.name}><div className={styles.point4}></div>{props.shoe5} <span className={styles.value4}>{props.value5}€</span></p>
            </div>
        )
    }else return(<Spi />)

}

export default BigComponent;