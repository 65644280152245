import * as actionTypes from '../constants/cartConstants';

export const addToCart = (sku, title, color, 
    qty20, qty21, qty22, qty23, qty24, qty25, qty26, qty27, qty28, qty29, qty30, qty31, qty32, qty33, qty34, qty35, qty36, qty37, qty38, qty39, qty40, qty41, qty42, qty43, qty44, qty45, qty46,
    quantity, total, 
    pri20,  pri21, pri22, pri23, pri24, pri25, pri26, pri27, pri28, pri29,pri30, pri31, pri32, pri33, pri34, pri35, pri36, pri37, pri38, pri39, pri40, pri41, pri42, pri43, pri44, pri45, pri46,
    image, 
    stock20, stock21, stock22, stock23, stock24, stock25, stock26, stock27, stock28, stock29, stock30, stock31, stock32, stock33, stock34, stock35,stock36, stock37, stock38, stock39, stock40, stock41, stock42, stock43, stock44, stock45, stock46,
    pvp20, pvp21, pvp22, pvp23, pvp24, pvp25, pvp26, pvp27, pvp28, pvp29, pvp30,pvp31, pvp32, pvp33, pvp34, pvp35, pvp36, pvp37, pvp38, pvp39, pvp40, pvp41, pvp42, pvp43, pvp44, pvp45, pvp46,
    ava20, ava21, ava22, ava23, ava24, ava25, ava26,ava27, ava28, ava29, ava30, ava31, ava32, ava33, ava34, ava35, ava36, ava37, ava38, ava39, ava40,ava41, ava42, ava43, ava44, ava45, ava46
    ) => async (dispatch, getState) => {

    dispatch({
        type: actionTypes.ADD_TO_CART,
        payload: {
            sku,
            title,
            color,
            qty20,
            qty21,
            qty22,
            qty23,
            qty24,
            qty25,
            qty26,
            qty27,
            qty28,
            qty29,
            qty30,
            qty31,
            qty32,
            qty33,
            qty34,
            qty35,
            qty36,
            qty37,
            qty38,
            qty39,
            qty40,
            qty41,
            qty42,
            qty43,
            qty44,
            qty45,
            qty46,
            quantity,
            total,
            pri20,
            pri21,
            pri22,
            pri23,
            pri24,
            pri25,
            pri26,
            pri27,
            pri28,
            pri29,
            pri30,
            pri31,
            pri32,
            pri33,
            pri34,
            pri35,
            pri36,
            pri37,
            pri38,
            pri39,
            pri40,
            pri41,
            pri42,
            pri43,
            pri44,
            pri45,
            pri46,
            image,
            stock20,
            stock21,
            stock22,
            stock23,
            stock24,
            stock25,
            stock26,
            stock27,
            stock28,
            stock29,
            stock30,
            stock31,
            stock32,
            stock33,
            stock34,
            stock35,
            stock36,
            stock37,
            stock38,
            stock39,
            stock40,
            stock41,
            stock42,
            stock43,
            stock44,
            stock45,
            stock46,
            pvp20,
            pvp21,
            pvp22,
            pvp23,
            pvp24,
            pvp25,
            pvp26,
            pvp27,
            pvp28,
            pvp29,
            pvp30,
            pvp31,
            pvp32,
            pvp33,
            pvp34,
            pvp35,
            pvp36,
            pvp37,
            pvp38,
            pvp39,
            pvp40,
            pvp41,
            pvp42,
            pvp43,
            pvp44,
            pvp45,
            pvp46,
            ava20,
            ava21,
            ava22,
            ava23,
            ava24,
            ava25,
            ava26,
            ava27,
            ava28,
            ava29,
            ava30,
            ava31,
            ava32,
            ava33,
            ava34,
            ava35,
            ava36,
            ava37,
            ava38,
            ava39,
            ava40,
            ava41,
            ava42,
            ava43,
            ava44,
            ava45,
            ava46
        }
    })

    //localStorage.setItem('cart', JSON.stringify(getState().cart.cartItems));
}

export const removeFromCart = (id) => (dispatch, getState) => {
    dispatch({
        type: actionTypes.REMOVE_FROM_CART,
        payload: id
    })

    //localStorage.setItem('cart', JSON.stringify(getState().cart.cartItems));
};

export function clearCart() {
    console.log('clear_action')
    return { 
      type: actionTypes.CLEAR_CART
    }
  }