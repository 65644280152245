import './ShopcartNewOrder.css';

const ShopcartNewOrder = props => {

    const qtyName = 'qty' + props.size;

    return(
        <div className="shopcart-qtySizeData-div2">
            <div className="qtySizeData-div-size">
                <p>{props.size}</p>
            </div>
            <div className="qtySizeData-div-stock">
                {props.stock === 0 ?
                <p className="redColor">{props.stock}</p>
                :
                <p className="qtySizeData-div-stock-green">{props.stock}</p>
                }
            </div>
            <div className="qtySizeData-div-qty">
                {props.stock === 0 ?
                <input name={props.name} disabled></input>
                :
                <input defaultValue={props.qty} onChange={props.changeHandler} name={qtyName} placeholder="0" type="number" min="0" max={props.stock}></input>
                }
            </div>
            <p className="qtySizeData-div-price">{props.price}€</p>
            <p className="qtySizeData-div-pricepvp">{props.pvp}€</p>
        </div>
    )
}

export default ShopcartNewOrder;