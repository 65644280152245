import React, { useState,useContext } from "react";
import './ImportUsers.css';
import { Authcontext } from "../../../login/context/auth-context";
import Loading from "../../shared/loading/Loading";

const ImportUsers = () => {

  const auth = useContext(Authcontext);

  // File and data
    const [file, setFile] = useState();
    const [array, setArray] = useState();
    const [head,setHead] =useState()
    const [fileName,setFileName] = useState("No file chosen");

  // Manage file
    const fileReader = new FileReader();
    const handleOnChange = (e) => {
      setFile(e.target.files[0]);
      setFileName(e.target.files[0].name);
    };
  
    const csvFileToArray = string => {
      const csvHeader = string.slice(0, string.indexOf("\n")).split(",");
      setHead(csvHeader);

      const csvRows = string.slice(string.indexOf("\n") + 1).split("\n");
  
      const array = csvRows.map(i => {
        const values = i.split(",");
        const obj = csvHeader.reduce((object, header, index) => {
          object[header] = values[index];
          return object;
        }, {});
        return obj;
      });
  
      setArray(array);
      console.log("csvRows");
      console.log(array);
    };
  
    const handleOnSubmit = (e) => {
      e.preventDefault();

      if (file) {

        fileReader.onload = function (event) {
          const text = event.target.result;
          console.log(text);
          csvFileToArray(text);
        };

        fileReader.readAsText(file);
      }

    }

    // Correct excel header
    const correctHeader = ["email","password","companyName","customerID","phone","billingAddress","city","country","zipCode",
                           "address1","city1","country1","zipCode1","address2","city2","country2","zipCode2","address3",
                           "city3","country3","zipCode3\r"];

    // Error found
    const errors = [];

    async function checkExcel(){
      // Check if table has right header
      for(let i=0;i<head.length;i++){
        if(head[i] !== correctHeader[i]) errors.push({right:correctHeader[i],wrong:head[i]});
      }

      if(errors.length === 0){
        setLoading(true);
        console.log("No errors found");
        for(let j=0;j<array.length;j++){

          //let lastField = array[j].zipCode3.replace('\r', '');

          let userBody = JSON.stringify({
            email: array[j].email,
            password: array[j].password,
            companyName: array[j].companyName,
            customerId: array[j].customerID,
            phoneNumber: array[j].phone,
            billingAdress: array[j].billingAddress,
            billingAdressCity: array[j].city,
            billingAdressCountry: array[j].country,
            billingAdressZipCode: array[j].zipCode,
            shippingAdress1: array[j].address1,
            shippingAdress1City: array[j].city1,
            shippingAdress1Country: array[j].country1,
            shippingAdress1ZipCode: array[j].zipCode1,
            shippingAdress2: array[j].address2,
            shippingAdress2City: array[j].city2,
            shippingAdress2Country: array[j].country2,
            shippingAdress2ZipCode: array[j].zipCode2,
            shippingAdress3: array[j].address3,
            shippingAdress3City: array[j].city3,
            shippingAdress3Country: array[j].country3,
            shippingAdress3ZipCode: array[j].zipCode3
          })
          await post(userBody);
        }
        setLoading(false);
      } 
      else{
        console.log(errors);
      }
    }

  // HTTP request POST
  const post = async userBody => {
    try {
      const response = await fetch(process.env.REACT_APP_BACKEND_URL + '/users', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${auth.token}`
        },
        body: userBody
      });
      const responseData = await response.json();
      console.log(responseData);
      let responseArray = responses;
      responseArray.push(responseData.message);
      setResponses(responseArray);
      
    }  catch (err) {
      console.log(err);
    }
  };

  // Loading
  const [loading,setLoading] = useState(false);

  // Response
  const [responses,setResponses] = useState([]);

  if(!loading){
    return (
      <div style={{marginLeft:"230px",backgroundColor:"#F4F3F8", minHeight:"100vh"}}>
        <div style={{width:"100%",height:"90px",backgroundColor:"white",overflow:"auto"}}>
          <h1 style={{marginLeft:"20px",marginTop:"30px", fontFamily: 'Source Code Pro',fontWeight:"500", fontSize:"38px"}}>Import users</h1>
        </div>
        <form>
          <input id="idInput" type={"file"} style={{display:"none"}} accept={".csv"}onChange={handleOnChange}/>
          <label className="inputUsers_excel" htmlFor="idInput">Choose csv file</label>
          <p style={{textAlign:"center"}}>{fileName}</p>
          <button className="button" style={{display:"block", margin:"auto"}} onClick={(e) => { handleOnSubmit(e);}}>Import file</button>
        </form>
        <br />

        {array ?
          <React.Fragment>
            <div style={{width:"1000px", overflow:"scroll", margin:"auto", border:"1px solid black"}}>
              <table>
                <thead>
                  <tr key={"header"}>
                    {head.map((key) => (
                      <th>{key}</th>
                    ))}
                  </tr>
                </thead>
        
                <tbody>
                  {array.map((item) => (
                    <tr key={item.id}>
                      {Object.values(item).map((val) => (
                        <td>{val}</td>
                      ))}
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
            <button className="button_save" style={{display:"block", margin:"auto", marginTop:"20px"}} onClick={checkExcel}>Create users</button>
          </React.Fragment> 
        :
        <span></span>
        }

      </div>
    );
  }
  else{
    return(
      <Loading/>
    )
  }
}

export default ImportUsers;