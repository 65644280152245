import './Search.css';

const Search = props => {
    return(
        <input 
            className="formElements_input"
            placeholder="Search product by name, color or sku"
            onChange={props.onChange}
        >
        </input>
    );
};

export default Search;