import React, { useState,useContext } from "react";
import { Authcontext } from "../../../login/context/auth-context";
import Loading from "../../shared/loading/Loading";

const ImportProducts = () => {

    const auth = useContext(Authcontext);

    return (
      <div style={{marginLeft:"230px",backgroundColor:"#F4F3F8", minHeight:"100vh"}}>
        <div style={{width:"100%",height:"90px",backgroundColor:"white",overflow:"auto"}}>
          <h1 style={{marginLeft:"20px",marginTop:"30px", fontFamily: 'Source Code Pro',fontWeight:"500", fontSize:"38px"}}>Import images</h1>
        </div>
        <form>
          <input id="idInput" type={"file"} style={{display:"none"}} accept={".jpg"}/>
          <label className="inputUsers_excel" htmlFor="idInput">Choose csv file</label>
          <p style={{textAlign:"center"}}></p>
          <button className="button" style={{display:"block", margin:"auto"}}>Import file</button>
        </form>
      </div>
    );
}

export default ImportProducts;