import React, {useState} from "react";

import './Imagens.css';

const Imagens = props => {

    const image1 = props.img1;
    const image2 = props.img2;
    const image3 = props.img3;
    const image4 = props.img4;

    const [priImg, setPriImg] = useState(image1);

    const changeImage1 = () => {
        setPriImg(image1);
    }

    const changeImage2 = () => {
        setPriImg(image2);
    }

    const changeImage3 = () => {
        setPriImg(image3);
    }

    const changeImage4 = () => {
        setPriImg(image4);
    }

    return(
        <div className="div_produto_imagens">
            <div className="div_produto_imagens-img_sec">
                <div onClick={changeImage1} className="div_produto_imagens-img_sec-div_img">
                    <img src={image1} alt="logo"/> 
                </div>
                <div onClick={changeImage2} className="div_produto_imagens-img_sec-div_img">
                    <img src={image2} alt="logo"/> 
                </div>
                <div onClick={changeImage3} className="div_produto_imagens-img_sec-div_img">
                    <img src={image3} alt="logo"/> 
                </div>
                <div onClick={changeImage4} className="div_produto_imagens-img_sec-div_img">
                    <img src={image4} alt="logo"/>                 
                </div>
            </div>
            <div className="div_produto_imagens-img_pri">
                <div className="div_produto_imagens-img_pri-div_img">
                    <img src={priImg} alt="logo"/> 
                </div>
            </div>
        </div>
    );
}

export default Imagens;