import styles from './style.module.css';

import SizeInfo from './SizeInfo';

const Component = props => {
    return(
        <div className={styles.mainDiv}>
            <div className={styles.photoDiv}>
                <p>{props.product.title} {props.product.color}</p>
                <p>{props.product.sku} </p>
                <img src={props.product.image} alt="" />
            </div>
            <div className={styles.infoDiv}>
                <div className={styles.legendDiv}>
                    <p>Size</p>
                    <p>Quantity</p>
                    <p>Price</p>
                    <p>PVP</p>
                </div>
                <div className={styles.infoDivUp}>
                    {props.product.ava24 ? <SizeInfo size="24" qty={props.product.qty24} price={props.product.pri24} pvp={props.product.pvp24}/> : <span /> }
                    {props.product.ava25 ? <SizeInfo size="25" qty={props.product.qty25} price={props.product.pri25} pvp={props.product.pvp25}/> : <span /> }
                    {props.product.ava26 ? <SizeInfo size="26" qty={props.product.qty26} price={props.product.pri26} pvp={props.product.pvp26}/> : <span /> }
                    {props.product.ava27 ? <SizeInfo size="27" qty={props.product.qty27} price={props.product.pri27} pvp={props.product.pvp27}/> : <span /> }
                    {props.product.ava28 ? <SizeInfo size="28" qty={props.product.qty28} price={props.product.pri28} pvp={props.product.pvp28}/> : <span /> }
                    {props.product.ava29 ? <SizeInfo size="29" qty={props.product.qty29} price={props.product.pri29} pvp={props.product.pvp29}/> : <span /> }
                    {props.product.ava30 ? <SizeInfo size="30" qty={props.product.qty30} price={props.product.pri30} pvp={props.product.pvp30}/> : <span /> }
                    {props.product.ava31 ? <SizeInfo size="31" qty={props.product.qty31} price={props.product.pri31} pvp={props.product.pvp31}/> : <span /> }
                    {props.product.ava32 ? <SizeInfo size="32" qty={props.product.qty32} price={props.product.pri32} pvp={props.product.pvp32}/> : <span /> }
                    {props.product.ava33 ? <SizeInfo size="33" qty={props.product.qty33} price={props.product.pri33} pvp={props.product.pvp33}/> : <span /> }
                    {props.product.ava34 ? <SizeInfo size="34" qty={props.product.qty34} price={props.product.pri34} pvp={props.product.pvp34}/> : <span /> }
                    {props.product.ava35 ? <SizeInfo size="35" qty={props.product.qty35} price={props.product.pri35} pvp={props.product.pvp35}/> : <span /> }
                    {props.product.ava36 ? <SizeInfo size="36" qty={props.product.qty36} price={props.product.pri36} pvp={props.product.pvp36}/> : <span /> }
                    {props.product.ava37 ? <SizeInfo size="37" qty={props.product.qty37} price={props.product.pri37} pvp={props.product.pvp37}/> : <span /> }
                    {props.product.ava38 ? <SizeInfo size="38" qty={props.product.qty38} price={props.product.pri38} pvp={props.product.pvp38}/> : <span /> }
                    {props.product.ava39 ? <SizeInfo size="39" qty={props.product.qty39} price={props.product.pri39} pvp={props.product.pvp39}/> : <span /> }
                    {props.product.ava40 ? <SizeInfo size="40" qty={props.product.qty40} price={props.product.pri40} pvp={props.product.pvp40}/> : <span /> }
                    {props.product.ava41 ? <SizeInfo size="41" qty={props.product.qty41} price={props.product.pri41} pvp={props.product.pvp41}/> : <span /> }
                    {props.product.ava42 ? <SizeInfo size="42" qty={props.product.qty42} price={props.product.pri42} pvp={props.product.pvp42}/> : <span /> }
                    {props.product.ava43 ? <SizeInfo size="43" qty={props.product.qty43} price={props.product.pri43} pvp={props.product.pvp43}/> : <span /> }
                </div>
                <div className={styles.infoDivDown}>
                    <p>Total: {props.product.total}€</p>
                    <p>Quantity: {props.product.quantity}</p>
                </div>
            </div>
        </div>
    )
}

export default Component;