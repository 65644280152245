import { useState } from "react";
import { Link } from "react-router-dom";

import logo from '../images/logo.png';

const PassRecovery = () => {

    const [isEmailSent,setIsEmailSent] = useState(false);

    // Manage email input
    const [email,setEmail] = useState('');
    const [code,setCode] = useState('');
    const [password,setPassword] = useState('');
    const [feedback,setFeedback] = useState();

    const sendEmail = async e => {

        e.preventDefault();
        const url = process.env.REACT_APP_BACKEND_URL + "/passordRecovery/sentEmail";

        console.log(email);
        try {
            const response = await fetch(url, {
              method: 'POST',
              headers: {
                'Content-Type': 'application/json'
              },
              body: JSON.stringify({
                email: email
              })
            });
            let responseData = await response.json();
            if(responseData.success){
                setIsEmailSent(true);
                setFeedback();
            }
            else{
                setFeedback(responseData);
            }

        }  catch (err) {
            console.log(err);
          }
    }

    const changePassword = async e => {

        e.preventDefault();
        const url = process.env.REACT_APP_BACKEND_URL + "/passordRecovery/changePassword";

        try {
            const response = await fetch(url, {
              method: 'POST',
              headers: {
                'Content-Type': 'application/json'
              },
              body: JSON.stringify({
                email: email,
                code: code,
                password: password
              })
            });
            let responseData = await response.json();
            setFeedback(responseData);
            console.log(responseData);

        } catch (err) {
            console.log(err);
        }
    }

    if(!isEmailSent) return(
        <div style={{width:"100vw", height:"65vh", overflow:"hidden"}}>
            <div className="div-login-img">
                <img src={logo} alt="logo"></img>
            </div>
            <div style={{width:"400px",height:"500px",margin:"auto",marginTop:"100px"}}>
                <h2 style={{fontFamily:"Source Code Pro",textAlign:"center",marginBottom:"20px", fontSize:"20px"}}>Password Recuperation</h2>
                {feedback ? <p className="p_login-data" style={{color:"red",textAlign:"center"}}>{feedback.message}</p> : <span></span> }
                <p className="p_login-data">Email</p>
                <input className="input_login-data" placeholder="Email" onChange={(e) => {setEmail(e.target.value)}}></input>
                <button className="button" style={{marginLeft:"115px",marginTop:"20px"}} onClick={sendEmail}>Send Email</button>
                <button className="button_back" style={{marginLeft:"115px",marginTop:"20px"}} onClick={()=>setIsEmailSent(true)}>Already have a code</button>
                <Link to="/"><button className="button_back" style={{marginLeft:"115px",marginTop:"20px"}} >Back to Login</button></Link>
            </div>
        </div>
    )
    else{
            return(
                <div style={{width:"100vw", height:"65vh", overflow:"hidden"}}>
                    <div className="div-login-img">
                        <img src={logo} alt="logo"></img>
                    </div>
                    <div style={{width:"400px",height:"400px",margin:"auto",marginTop:"25px"}}>
                        <h2 style={{fontFamily:"Source Code Pro",textAlign:"center",marginBottom:"20px", fontSize:"20px"}}>Code Verification</h2>
                        {feedback ? feedback.success ? <p className="p_login-data" style={{color:"green",textAlign:"center"}}>{feedback.message}</p> : <p className="p_login-data" style={{color:"red",textAlign:"center"}}>{feedback.message}</p> : <span></span> }
                        <p className="p_login-data">Email</p>
                        <input className="input_login-data" placeholder="Email"  defaultValue={email} onChange={(e) => {setEmail(e.target.value)}}></input>
                        <p className="p_login-data">Code</p>
                        <input className="input_login-data" placeholder="Code" onChange={(e) => {setCode(e.target.value)}}></input>
                        <p className="p_login-data">New Password</p>
                        <input className="input_login-data" placeholder="New Password" onChange={(e) => {setPassword(e.target.value)}}></input>
                        <button className="button" style={{marginLeft:"115px",marginTop:"20px"}} onClick={changePassword}>Change password</button>
                        <Link to="/"><button className="button_back" style={{marginLeft:"115px",marginTop:"20px"}} >Back to Login</button></Link>
                    </div>
                </div>
            )
    } 
}

export default PassRecovery;