//Style
import './App.css';

//Packages
import { BrowserRouter as Router, Route, Switch, Redirect } from 'react-router-dom';
import { useState, useCallback } from 'react';

//Components
import Login from './login/pages/Login';
import UserCatalog from './user/catalog/main/page/UserCatalog';
import UserProduct from './user/catalog/product/page/UserProduct';
import Quantity from './user/catalog/quantity/page/Quantity';
import Shopcart from './user/catalog/shopcart/page/Shopcart';
import PurchaseConfirmation from './user/catalog/purchaseConfirmation/page/PurchaseConfirmation';
import UserOrders from './user/orders/main/page/UserOrders';
import UserOrderProducts from './user/orders/order-products/page/UserOrderProducts';
import UserAccount from './user/account/page/UserAccount';
import AdminCatalog from './admin/catalog/main/page/AdminCatalog';
import AdminAddProduct from './admin/catalog/addProduct/page/AdminAddProduct';
import AdminEditProduct from './admin/catalog/editProduct/AdminEditProduct';
import AdminOrders from './admin/orders/main/page/AdminOrders';
import AdminOrderProducts from './admin/orders/order-products/page/AdminOrderProducts';
import AdminUsers from './admin/users/main/page/AdminUsers';
import AdminAddUser from './admin/users/add-user/page/AdminAddUser';
import Header from './user/shared/header/Header';
import AdminHeader from './admin/shared/header/AdminHeader';
import LoginFooter from './login/components/LoginFooter';
import UserFooter from './user/shared/footer/UserFooter';
import StockError from './user/catalog/stockError/page/StockError';
import AdminAccount from './admin/account/page/AdminAccount';
import Banner from './admin/banners/page/Banner';
import BannerUser from './user/banner/page/Banner';

import Dashboard from './admin/dashboard/overview/page/DashboardOverview';
import DashboardClients from './admin/dashboard/clients/page/DashboardClients';
import DashboardProducts from './admin/dashboard/products/page/DashboardProducts';
import DashboardSales from './admin/dashboard/sales/page/DashboardSales';

import { Authcontext } from './login/context/auth-context';
import React from 'react';
import AdminEditUser from './admin/users/edit-user/page/AdminEditUser';
import ImportUsers from './admin/users/add-excel/ImportUsers';
import ImportProducts from './admin/catalog/importCSV/ImportProducts';
import ImportImages from './admin/catalog/importImages/ImportImages';

import PassRecovery from './passwordRecovery/PassRecovery';
import Discount from './admin/discounts/main/Discount';
import CreateDiscount from './admin/discounts/createDiscount/CreateDiscount';
import UpdateStocks from './admin/stocks/UpdateStocks';

const App = () => {

  const [token, setToken] = useState(false);
  const [userId, setUserId] = useState(null);
  const [email, setEmail] = useState(null);

  const login = useCallback((uid, token, email) => {
    setToken(token);
    setUserId(uid);
    setEmail(email);
  }, []);

  const logout = useCallback(() => {
    setToken(null);
    setUserId(null);
    setEmail(null);
  }, []);

  let routes;

  if(token && (email === 'admin' || email === 'adminPerto' || email === 'administrator')){
    routes=(
      <Switch>
          <Route path="/admin/catalog" exact>
            <AdminHeader />
            <AdminCatalog />
          </Route>
          <Route path="/admin/updateStocks"exact >
            <AdminHeader />
            <UpdateStocks />
          </Route>
          <Route path="/admin/catalog/addproduct"exact >
            <AdminHeader />
            <AdminAddProduct />
          </Route>
          <Route path="/admin/catalog/editproduct/:pid"exact >
            <AdminHeader />
            <AdminEditProduct />
          </Route>
          <Route path="/admin/catalog/importProducts"exact >
            <AdminHeader />
            <ImportProducts />
          </Route>
          <Route path="/admin/catalog/importImages"exact >
            <AdminHeader />
            <ImportImages />
          </Route>
          <Route path="/admin/orders" exact>
            <AdminHeader />
            <AdminOrders />
          </Route>
          <Route path="/admin/discounts" exact>
            <AdminHeader />
            <Discount />
          </Route>
          <Route path="/admin/discounts/createDiscount" exact>
            <AdminHeader />
            <CreateDiscount />
          </Route>
          <Route path="/admin/orders/products/:oid"exact >
            <AdminHeader />
            <AdminOrderProducts />
          </Route>
          <Route path="/admin/users" exact>
            <AdminHeader />
            <AdminUsers />
          </Route>
          <Route path="/admin/users/adduser"exact >
            <AdminHeader />
            <AdminAddUser />
          </Route>
          <Route path="/admin/users/edituser/:uid"exact >
            <AdminHeader />
            <AdminEditUser />
          </Route>
          <Route path="/admin/users/importUsers"exact >
            <AdminHeader />
            <ImportUsers />
          </Route>
          <Route path="/admin/account" exact>
            <AdminAccount />
          </Route>
          <Route path="/admin/dashboard/overview" exact>
            <AdminHeader />
            <Dashboard />
          </Route>
          <Route path="/admin/dashboard/dashboardSales" exact>
            <AdminHeader />
            <DashboardSales />
          </Route>
          <Route path="/admin/dashboard/dashboardProducts" exact>
            <AdminHeader />
            <DashboardProducts />
          </Route>
          <Route path="/admin/dashboard/dashboardClients" exact>
            <AdminHeader />
            <DashboardClients />
          </Route>
          <Route path="/admin/banner" exact>
            <AdminHeader />
            <Banner />
          </Route>
          <Redirect to="admin/catalog" />
      </Switch>
    );
  }else if(token){
    routes=(
      <Switch>
          <Route path="/user/banner" exact>
            <Header />
            <BannerUser />
            <UserFooter />
          </Route>
          <Route path="/user/catalog" exact>
            <Header />
            <UserCatalog />
            <UserFooter />
          </Route>
          <Route path="/user/catalog/product/:pid" exact>
            <Header />
            <UserProduct />
            <UserFooter />
          </Route>
          <Route path="/user/catalog/quantity/:pname" exact>
            <Header />
            <Quantity />
          </Route>
          <Route path="/user/catalog/shopcart" exact>
            <Header />
            <Shopcart />
          </Route>
          <Route path="/user/catalog/confirmation" exact>
            <PurchaseConfirmation />
          </Route>
          <Route path="/user/catalog/stockError" exact>
            <StockError />
          </Route>
          <Route path="/user/orders" exact>
            <Header />
            <UserOrders />
          </Route>
          <Route path="/user/orders/products/:oid" exact>
            <Header />
            <UserOrderProducts />
          </Route>
          <Route path="/user/account"exact >
            <Header />
            <UserAccount />
          </Route>
      </Switch>
    );
  }
  else{
    routes=(
      <Switch>
        <Route path="/" exact>
          <Login />
          <LoginFooter />
        </Route>
        <Route path="/passwordRecovery" exact>
          <PassRecovery />
          <LoginFooter />
        </Route>
        <Redirect to="/" />
      </Switch>
    );
  }

  // -- RETURN
  return (
    <Authcontext.Provider value={{isLoggedIn: !!token, userId: userId, token: token, email: email, login: login, logout: logout}}>
      <Router>
          {routes}
      </Router>
    </Authcontext.Provider>
  );
}

export default App;