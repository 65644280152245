//Style
import styles1 from './style.module.css';
import logo from '../../../../images/logo.png';

//Packages
import { useParams } from 'react-router-dom';
import React, { useState, useEffect, useContext } from "react";

//Custom hooks
import {Authcontext} from '../../../../login/context/auth-context';

//Components
import Component from '../../../../shared/OrderDetails/Component'

import { Page, Text, View, Document, Image, StyleSheet, PDFDownloadLink } from '@react-pdf/renderer';
import Component2 from '../../../../shared/OrderDetails/Component2';

import Loading from '../../../shared/loading/Loading';

const AdminOrderProducts = () => {

  /*
  <PDFViewer>
    <MyDocument />
  </PDFViewer>
  */

    const auth = useContext(Authcontext);

    //Fetch
    const orderId = useParams().oid;
    const [loadedData, setLoadedData] = useState();
    let url = process.env.REACT_APP_BACKEND_URL + '/orders/perOrderId/' + orderId;
    const [fileName, setFileName] = useState('');

    useEffect(() => {
        const sendRequest = async () => {
            try{
                const response = await fetch(url,{
                    method: 'GET',
                    headers: {
                        'Authorization': `Bearer ${auth.token}`
                    }
                });
                const responseData = await response.json();
                setLoadedData(responseData.order);
                setFileName(responseData.order[0].orderId + '.pdf');
            } catch (err){
                console.log(err);
            }
        }
        sendRequest();
    }, [auth.token, url]);
    

// Create styles
const styles = StyleSheet.create({
    page: {
      flexDirection: 'row',
      fontSize: 8,
      paddingRight: 50,
      paddingLeft: 15,
      marginBottom:0,
      height: '2.7cm'
    },
    page2: {
      flexDirection: 'row',
      fontSize: 8,
      paddingRight: 50,
      paddingLeft: 15,
      marginTop: 0
    },
    title: {
        margin: 20,
        fontSize: 25,
        textAlign: 'center',
        backgroundColor: '#e4e4e4',
        textTransform: 'uppercase'
    },
    //flexGrow: 1,
    section: {
      margin: 10,
      marginTop: 0,
      padding: 15,
      alignItems: 1,
      width: 15,
      height: 80
    },
    textBold: {
        textAlign: 'center',
        width: '40',
        height: 15,
        paddingTop: 3,
        fontWeight: '900',
        border: '1px solid rgb(135, 135, 135)',
        marginBottom: '5'
    },
    text: {
        textAlign: 'center',
        width: '40',
        fontWeight: '100',
        color: 'rgb(135, 135, 135)'
    },
    se: {
        backgroundColor: 'blue',
        width: '700'
    },
    le: {
        flexDirection: 'row',
        fontSize: '10px',
        paddingRight: 50,
        paddingLeft: 15,
        backgroundColor: 'blue'
    },
    header: {
        fontSize: '12px',
        marginLeft: 40,
        marginBottom: 5
    },
    header2: {
        fontSize: '15px',
        fontWeight: 900,
        color: 'rgb(135, 135, 135)',
        marginLeft: 40,
        marginTop: 20,
        marginBottom: 5
    },
    header3: {
        fontSize: '12px',
        marginLeft: 40,
        marginBottom: 30
    },
    image: {
        width: 60,
        height: 75,
        marginLeft: 40,
        marginTop: 10
      },
      row: {
        flexDirection: 'row'
      },
      text22: {
        marginTop: 10,
        marginLeft: 10,
        fontSize: 15
      },
      text23: {
        marginTop: 2,
        marginLeft: 10,
        fontSize: 10,
        color: 'rgb(135, 135, 135)'
      },
      proMargin: {
          marginTop: 25,
      },
      headerBox: {
          border: '1px solid black',
          marginLeft: 40,
          marginRight: 40,
      },
      line: {
        border: '1px solid black',
        marginLeft: 40,
        marginRight: 40
      },
      textqty1: {
        marginTop: 20,
        marginLeft: 10,
        fontSize: 10,
      },
      textqty: {
        marginTop: 2,
        marginLeft: 10,
        fontSize: 10,
      },
      logo: {
        width: '5cm',
        height: '2cm',
        marginLeft: '1.5cm',
        marginTop: 50
      },
      header5: {
        fontSize: '12px',
        marginLeft: 40,
        marginBottom: 5,
        marginTop: 20,
        color: 'rgb(135, 135, 135)',
        fontWeight: 100,
      },
      headerQ: {
        fontSize: '12px',
        marginLeft: 40,
        marginBottom: 5,
        color: 'black',
        fontWeight: 100,
        width: '115px',
        borderBottom: '1px solid black',
        paddingBottom: 20,
        marginRight: 0,
        marginTop: 50
        },
      headerV: {
        fontSize: '12px',
        marginBottom: 5,
        color: 'black',
        fontWeight: 100,
        width: '115px',
        borderBottom: '1px solid black',
        paddingBottom: 20,
        marginLeft: 0,
        marginTop: 50,
        textAlign: 'right'
      },
      headerQ2: {
        fontSize: '12px',
        marginLeft: 40,
        marginBottom: 5,
        color: 'black',
        fontWeight: 100,
        width: '115px',
        marginRight: 0,
        marginTop: 20
      },
      headerV2: {
        fontSize: '19px',
        marginBottom: 5,
        color: 'black',
        fontWeight: 100,
        width: '115px',
        marginLeft: 0,
        marginTop: 20,
        textAlign: 'right'
      },
      header6: {
        fontSize: '12px',
        marginLeft: 40,
        marginBottom: 5,
        marginTop: 50,
        color: 'rgb(135, 135, 135)',
        fontWeight: 100,
        width: '230px'
      },
      header7: {
        fontSize: '12px',
        marginLeft: 40,
        marginBottom: 5,
        marginTop: 20,
        color: 'rgb(135, 135, 135)',
        fontWeight: 100,
        borderBottom: '1px solid black',
        paddingBottom: 20,
        width: '240px'
      },
      header8: {
        fontSize: '12px',
        marginLeft: 40,
        marginBottom: 5,
        marginTop: 20,
        color: 'rgb(135, 135, 135)',
        fontWeight: 100,
        width: '240px'
      },
      aligneLeftHeader: {
        textAlign: 1
      },
      header10: {
        fontSize: '12px',
        marginLeft: 40,
        marginBottom: 5,
        maxWidth: '230px'
    },
  });
  
  // Create Document Component
const MyDocument = () => (
  <Document>
    <Page size="A4">
    <Image style={styles.logo} src={logo} />
    <Text style={styles.header2}>Order Confirmation </Text>
    <View style={styles.row}>
      <View style={styles.aligneLeftHeader}>
        <Text style={styles.header5}>Date:</Text>
        <Text style={styles.header}>{loadedData[0].date}</Text>
        <Text style={styles.header5}>Client:</Text>
        <Text style={styles.header}>{loadedData[0].username}</Text>
      </View>
      <View>
        <Text style={styles.header5}>Client ID:</Text>
        <Text style={styles.header}>{loadedData[0].customerId}</Text>
        <Text style={styles.header5}>Client Email:</Text>
        <Text style={styles.header}>{loadedData[0].email}</Text>
      </View>
      <View>
        <Text style={styles.header5}>Order Number:</Text>
        <Text style={styles.header}>{loadedData[0].orderId}</Text>
      </View>
    </View>

    {loadedData[0].productsList.map(product =>
      <View wrap={false} style={styles.proMargin}>
        <View style={styles.row}>
          <Image style={styles.image} src={product.image} />
          <View >
            <Text style={styles.text22}>{product.title} {product.color}</Text>
            <Text style={styles.text23}>{product.sku}</Text>
            <Text style={styles.textqty1}>Quantity - {parseFloat(product.quantity).toFixed(2)}</Text>
            <Text style={styles.textqty}>Total - {parseFloat(product.total).toFixed(2)}€</Text>
          </View>
        </View>

      <View wrap={false} style={styles.page}>
        {product.ava24 ? <View style={styles.section}>
          <Text style={styles.textBold}>24</Text>
          <Text style={styles.textBold}>{product.qty24}</Text>
          <Text style={styles.text}>{parseFloat(product.pri24).toFixed(2)}€</Text>
          <Text style={styles.text}>{parseFloat(product.pvp24).toFixed(2)}€</Text>
        </View> : <Text></Text>}
        {product.ava25 ? <View style={styles.section}>
          <Text style={styles.textBold}>25</Text>
          <Text style={styles.textBold}>{product.qty25}</Text>
          <Text style={styles.text}>{parseFloat(product.pri25).toFixed(2)}€</Text>
          <Text style={styles.text}>{parseFloat(product.pvp25).toFixed(2)}€</Text>
        </View> : <Text></Text>}
        {product.ava26 ? <View style={styles.section}>
          <Text style={styles.textBold}>26</Text>
          <Text style={styles.textBold}>{product.qty26}</Text>
          <Text style={styles.text}>{parseFloat(product.pri26).toFixed(2)}€</Text>
          <Text style={styles.text}>{parseFloat(product.pvp26).toFixed(2)}€</Text>
        </View> : <Text></Text>}
        {product.ava27 ? <View style={styles.section}>
          <Text style={styles.textBold}>27</Text>
          <Text style={styles.textBold}>{product.qty27}</Text>
          <Text style={styles.text}>{parseFloat(product.pri27).toFixed(2)}€</Text>
          <Text style={styles.text}>{parseFloat(product.pvp27).toFixed(2)}€</Text>
        </View> : <Text></Text>}
        {product.ava28 ? <View style={styles.section}>
          <Text style={styles.textBold}>28</Text>
          <Text style={styles.textBold}>{product.qty28}</Text>
          <Text style={styles.text}>{parseFloat(product.pri28).toFixed(2)}€</Text>
          <Text style={styles.text}>{parseFloat(product.pvp28).toFixed(2)}€</Text>
        </View> : <Text></Text>}
        {product.ava29 ? <View style={styles.section}>
          <Text style={styles.textBold}>29</Text>
          <Text style={styles.textBold}>{product.qty29}</Text>
          <Text style={styles.text}>{parseFloat(product.pri29).toFixed(2)}€</Text>
          <Text style={styles.text}>{parseFloat(product.pvp29).toFixed(2)}€</Text>
        </View> : <Text></Text>}
        {product.ava30 ? <View style={styles.section}>
          <Text style={styles.textBold}>30</Text>
          <Text style={styles.textBold}>{product.qty30}</Text>
          <Text style={styles.text}>{parseFloat(product.pri30).toFixed(2)}€</Text>
          <Text style={styles.text}>{parseFloat(product.pvp30).toFixed(2)}€</Text>
        </View> : <Text></Text>}
        {product.ava31 ? <View style={styles.section}>
          <Text style={styles.textBold}>31</Text>
          <Text style={styles.textBold}>{product.qty31}</Text>
          <Text style={styles.text}>{parseFloat(product.pri31).toFixed(2)}€</Text>
          <Text style={styles.text}>{parseFloat(product.pvp31).toFixed(2)}€</Text>
        </View> : <Text></Text>}
        {product.ava32 ? <View style={styles.section}>
          <Text style={styles.textBold}>32</Text>
          <Text style={styles.textBold}>{product.qty32}</Text>
          <Text style={styles.text}>{parseFloat(product.pri32).toFixed(2)}€</Text>
          <Text style={styles.text}>{parseFloat(product.pvp32).toFixed(2)}€</Text>
        </View> : <Text></Text>}
        {product.ava33 ? <View style={styles.section}>
          <Text style={styles.textBold}>33</Text>
          <Text style={styles.textBold}>{product.qty33}</Text>
          <Text style={styles.text}>{parseFloat(product.pri33).toFixed(2)}€</Text>
          <Text style={styles.text}>{parseFloat(product.pvp33).toFixed(2)}€</Text>
        </View> : <Text></Text>}
      </View>
      <View wrap={false} style={styles.page2}>
      {product.ava34 ? <View style={styles.section}>
          <Text style={styles.textBold}>34</Text>
          <Text style={styles.textBold}>{product.qty34}</Text>
          <Text style={styles.text}>{parseFloat(product.pri34).toFixed(2)}€</Text>
          <Text style={styles.text}>{parseFloat(product.pvp34).toFixed(2)}€</Text>
        </View> : <Text></Text>}
        {product.ava35 ? <View style={styles.section}>
          <Text style={styles.textBold}>35</Text>
          <Text style={styles.textBold}>{product.qty35}</Text>
          <Text style={styles.text}>{parseFloat(product.pri35).toFixed(2)}€</Text>
          <Text style={styles.text}>{parseFloat(product.pvp35).toFixed(2)}€</Text>
        </View> : <Text></Text>}
        {product.ava36 ? <View style={styles.section}>
        <Text style={styles.textBold}>36</Text>
        <Text style={styles.textBold}>{product.qty36}</Text>
        <Text style={styles.text}>{parseFloat(product.pri36).toFixed(2)}€</Text>
        <Text style={styles.text}>{parseFloat(product.pvp36).toFixed(2)}€</Text>
      </View> : <Text></Text>}
      {product.ava37 ? <View style={styles.section}>
        <Text style={styles.textBold}>37</Text>
        <Text style={styles.textBold}>{product.qty37}</Text>
        <Text style={styles.text}>{parseFloat(product.pri37).toFixed(2)}€</Text>
        <Text style={styles.text}>{parseFloat(product.pvp37).toFixed(2)}€</Text>
      </View> : <Text></Text>}
      {product.ava38 ? <View style={styles.section}>
        <Text style={styles.textBold}>38</Text>
        <Text style={styles.textBold}>{product.qty38}</Text>
        <Text style={styles.text}>{parseFloat(product.pri38).toFixed(2)}€</Text>
        <Text style={styles.text}>{parseFloat(product.pvp38).toFixed(2)}€</Text>
      </View> : <Text></Text>}
      {product.ava39 ? <View style={styles.section}>
        <Text style={styles.textBold}>39</Text>
        <Text style={styles.textBold}>{product.qty39}</Text>
        <Text style={styles.text}>{parseFloat(product.pri39).toFixed(2)}€</Text>
        <Text style={styles.text}>{parseFloat(product.pvp39).toFixed(2)}€</Text>
      </View> : <Text></Text>}
      {product.ava40 ? <View style={styles.section}>
        <Text style={styles.textBold}>40</Text>
        <Text style={styles.textBold}>{product.qty40}</Text>
        <Text style={styles.text}>{parseFloat(product.pri40).toFixed(2)}€</Text>
        <Text style={styles.text}>{parseFloat(product.pvp40).toFixed(2)}€</Text>
      </View> : <Text></Text>}
      {product.ava41 ? <View style={styles.section}>
        <Text style={styles.textBold}>41</Text>
        <Text style={styles.textBold}>{product.qty41}</Text>
        <Text style={styles.text}>{parseFloat(product.pri41).toFixed(2)}€</Text>
        <Text style={styles.text}>{parseFloat(product.pvp41).toFixed(2)}€</Text>
      </View> : <Text></Text>}
      {product.ava42 ? <View style={styles.section}>
        <Text style={styles.textBold}>42</Text>
        <Text style={styles.textBold}>{product.qty42}</Text>
        <Text style={styles.text}>{parseFloat(product.pri42).toFixed(2)}€</Text>
        <Text style={styles.text}>{parseFloat(product.pvp42).toFixed(2)}€</Text>
      </View> : <Text></Text>}
      {product.ava43 ? <View style={styles.section}>
        <Text style={styles.textBold}>43</Text>
        <Text style={styles.textBold}>{product.qty43}</Text>
        <Text style={styles.text}>{parseFloat(product.pri43).toFixed(2)}€</Text>
        <Text style={styles.text}>{parseFloat(product.pvp43).toFixed(2)}€</Text>
      </View> : <Text></Text>}
    </View>
    <Text style={styles.line}></Text>
  </View>
    )}
    <View style={styles.row}>
      <View style={styles.aligneLeftHeader}>
        <Text style={styles.header6}>Shipping To:</Text>
        <Text style={styles.header10}>{loadedData[0].shippingAdress}</Text>
        <Text style={styles.header5}>Notes:</Text>
        <Text style={styles.header}>{loadedData[0].orderNote}</Text>
      </View>
      <View>
        <View style={styles.row}>
          <View style={styles.aligneLeftHeader5}>
            <Text style={styles.headerQ}>Quantity (Items):</Text>
            <Text style={styles.headerQ2}>Total:</Text>
          </View>
          <View>
            <Text style={styles.headerV}>{loadedData[0].quantity}</Text>
            <Text style={styles.headerV2}>{loadedData[0].cost} €</Text>
          </View>
        </View>
      </View>
    </View>
  </Page>
  </Document>
);

/*
 <Div width="350px" height="250px" float="right" marginLeft="15px">
                      <Div width="348px" height="50px" border="1px solid #c2c2c2" marginBottom="15px">
                        <p className="shopcart-form-p">Quantity: {loadedData[0].quantity}</p>
                      </Div>
                      <Div width="348px" height="50px" border="1px solid #c2c2c2" marginBottom="15px">
                        <p className="shopcart-form-p">Total: {loadedData[0].cost} €</p>
                      </Div>
                      <PDFDownloadLink document={<MyDocument />} fileName={fileName}>
                    {({ blob, url, loading, error }) => (loading ? <Button width="100%" height="40px" text="PRINT ORDER" disabled="true" /> : <Button width="100%" height="40px" text="PRINT ORDER"/>)}
                    </PDFDownloadLink>
                      
                    </Div>
                    <Div width="400px" height="250px" float="right" marginLeft="15px">
                      <Div width="398px" height="172px" border="1px solid #c2c2c2" marginBottom="15px">
                        <p className="shopcart-form-p"> {loadedData[0].shippingAdress} </p>
                      </Div>
                    </Div>
                    <Div width="300px" height="200px" float="right">
                        <Div width="298px" height="172px" border="1px solid #c2c2c2">
                            <p className="shopcart-form-p">Order note: <br/> {loadedData[0].orderNote}</p>
                        </Div>
                    </Div>
*/
  
    // -- RETURN
    if(loadedData){
        return(
          <div className={styles1.adminOrderDetail}>
                <div className={styles1.header}>
                  <div className={styles1.header1}>
                    <p className={styles1.id}>ID : <span>{loadedData[0].orderId}</span></p>
                    <p className={styles1.email}>Email : {loadedData[0].email}</p>
                  </div>
                  <div className={styles1.header2}>
                    <PDFDownloadLink document={<MyDocument />} fileName={fileName}>
                      {({ blob, url, loading, error }) => (loading ? <button disabled="true">Print Order</button> : <button>Print Order</button>)}
                    </PDFDownloadLink>
                  </div>
                </div>

                {loadedData[0].productsList.map(product =>
                  <Component product={product} />)
                }
                <Component2 order={loadedData[0]}/>

           </div>
        )
    }else{
        return <Loading/>
    }
}

export default AdminOrderProducts;