//Style
import './Shopcart.css';

//Packages
import moment from 'moment';
import {useHistory} from 'react-router-dom';
import {useDispatch, useSelector} from 'react-redux';
import {useContext, useState} from 'react';

//Components
import { clearCart } from '../../../../redux/actions/cartActions';
import ShopcartOrder from '../components/ShopcartOrder';
import MainDiv from '../../../../shared/containers/MainDiv';
import Div from '../../../../shared/containers/Div';
import Title from '../../../../shared/formElements/Title';
import { Authcontext } from '../../../../login/context/auth-context';
import { useEffect } from 'react';
import Spi from '../../../../shared/animations/Spi';

// -- FUNCTION
const Shopcart = ( {products} ) => {

  const auth = useContext(Authcontext);

  //User Adresses
  const [adress1, setAdress1] = useState('');
  const [adress2, setAdress2] = useState('');
  const [adress3, setAdress3] = useState('');
  const [customerId, setCustomerId] = useState('');
  const [customerName, setCustomerName] = useState('');
  
  const [vimagro, setVimagro] = useState('');

  //Fetch User
  useEffect(() => {

    const fetchUser = async () => {
      const url = `/users/get/${auth.email}`;
      try{
        const response = await fetch(process.env.REACT_APP_BACKEND_URL + url, {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${auth.token}`
          }
        })
        const responseData = await response.json();
        console.log(responseData);
        setAdress1(responseData[0].shippingAdress1+' '+responseData[0].shippingAdress1City+
        ' '+responseData[0].shippingAdress1Country+' '+responseData[0].shippingAdress1ZipCode);

        setAdress2(responseData[0].shippingAdress2+' '+responseData[0].shippingAdress2City+
        ' '+responseData[0].shippingAdress2Country+' '+responseData[0].shippingAdress2ZipCode);

        setAdress3(responseData[0].shippingAdress3+' '+responseData[0].shippingAdress3City+
                   ' '+responseData[0].shippingAdress3Country+' '+responseData[0].shippingAdress3ZipCode);

        setShippingAdress(responseData[0].shippingAdress1+' '+responseData[0].shippingAdress1City+
        ' '+responseData[0].shippingAdress1Country+' '+responseData[0].shippingAdress1ZipCode);

        setCustomerId(responseData[0].customerId);
        setCustomerName(responseData[0].companyName);
        }catch(err){
        console.log("Erro");
      }
    }

    fetchUser();
  }, [auth.email, auth.token]);

    //Redux
    const dispatch = useDispatch();
    const cart = useSelector(state => state.cart);
    const { cartItems } = cart;

    //Order note
    const [orderNote, setOrderNote] = useState('');
    function handleOrderNoteChange(e){
      setOrderNote(e.target.value);
    }


    const [awaiting, setAwaiting] = useState(false);

    //Post to database order
    const history = useHistory();
    const postToDatabase = async event => {
        setAwaiting(true);
        event.preventDefault();

        let dateNow = moment().format("MMM Do YY");
        
        try {
          const response = await fetch(process.env.REACT_APP_BACKEND_URL + '/orders', {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
              'Authorization': `Bearer ${auth.token}`
            },
            body: JSON.stringify({
                username: customerName,
                customerId: customerId,
                email: auth.email,
                orderNote: orderNote,
                shippingAdress: shippingAdress,
                date: dateNow,
                cost: getCartCount(),
                quantity: getCartQuantity(),
                productsList: cartItems.map(p => p)
            })
          });
    
          const responseData = await response.json();
          console.log(responseData);
          if(responseData.message==='Order Accepted') history.push('/user/catalog/confirmation');
          else history.push('/user/catalog/stockError');
        }  catch (err) {
          console.log(err);
        }

        dispatch(clearCart());

      };

      const getCartCount = () => {
        const priceCart = cartItems.reduce((qty, item) => Number(item.total) + qty, 0);
        return parseFloat(priceCart).toFixed(2);
      }

      const getCartQuantity = () => {
        const priceCart = cartItems.reduce((qty, item) => Number(item.quantity) + qty, 0);
        return priceCart;
      }

      const [shipping, setShipping] = useState(1);
      const [shippingAdress, setShippingAdress] = useState('');

      function check1(){
        setShipping(1);
        setShippingAdress(adress1);
      }
      function check2(){
        setShipping(2);
        setShippingAdress(adress2);
      }
      function check3(){
        setShipping(3);
        setShippingAdress(adress3);
      }
      function check4(){
        setVimagro(1);
      }

      const styleCheck = {
        backgroundColor : "#BCA291"
      }


      if(!awaiting){
        return(
           <MainDiv>
             <Title text="Shopping Cart" />
                {cartItems.length === 0 ? (
                    <h1 className="shopcartmain-div-h1">Your cart is empty</h1>
                ) : (<div>{cartItems.map(product => (
                    <ShopcartOrder
                            product={product}
                        />))
                    }
                    <Div width="350px" height="250px" float="right" marginLeft="15px">
                      <Div width="348px" height="50px" border="1px solid #c2c2c2" marginBottom="15px">
                        <p className="shopcart-form-p">Quantity: {getCartQuantity()}</p>
                      </Div>
                      <Div width="348px" height="50px" border="1px solid #c2c2c2" marginBottom="15px">
                        <p className="shopcart-form-p">Total: {getCartCount()} €</p>
                      </Div>
                      {vimagro === 1 ?
                      <button className="button_save" style={{width:"100%"}} onClick={postToDatabase} >ORDER</button>
                      : <button disabled={true} className="button_save" style={{width:"100%"}}>ORDER</button>
                      }
                      {vimagro === 1 ? <div style={styleCheck} className="shopcart-checkbox"></div> : <div className="shopcart-checkbox" onClick={check4}></div>}
                      <div className="div_terms_conditions"><p className="terms_conditions">All transactions are made according to the Vimagro terms and conditions, I have read and accepted
                        the terms and conditions</p></div>
                    </Div>
                    <Div width="400px" height="250px" float="right" marginLeft="15px">
                      <Div width="398px" height="50px" border="1px solid #c2c2c2" marginBottom="15px">
                        {shipping === 1 ? <div style={styleCheck} className="shopcart-checkbox"></div> : <div className="shopcart-checkbox" onClick={check1}></div>}
                        <p className="shopcart-form-p">Shipping Adress 01. <span className="shopcart-form-p-adress">{adress1}</span></p>
                      </Div>
                      <Div width="398px" height="50px" border="1px solid #c2c2c2" marginBottom="15px">
                        {shipping === 2 ? <div style={styleCheck} className="shopcart-checkbox"></div> : <div className="shopcart-checkbox" onClick={check2}></div>}
                        <p className="shopcart-form-p">Shipping Adress 02. <span className="shopcart-form-p-adress">{adress2}</span></p>
                      </Div>
                      <Div width="398px" height="50px" border="1px solid #c2c2c2" marginBottom="15px">
                        {shipping === 3 ? <div style={styleCheck} className="shopcart-checkbox"></div> : <div className="shopcart-checkbox" onClick={check3}></div>}
                        <p className="shopcart-form-p">Shipping Adress 03. <span className="shopcart-form-p-adress">{adress3}</span></p>
                      </Div>
                    </Div>
                    <Div width="300px" height="200px" float="right">
                      <textarea onChange={handleOrderNoteChange} className="shopcart-textarea" placeholder="Order notes:"></textarea>
                    </Div>
                    </div>)
            }
           </MainDiv>
      )
    }
  else{
    return(
      <div className="createProduct_load_div">
        <h2>Order is being placed, do not close window.</h2>
        <Spi />
      </div>
    )
  }
}

export default Shopcart;