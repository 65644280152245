import React from "react";

import './LoginFooter.css';

const LoginFooter = () => {
    return(
        <footer className="div-login_footer">
            <div className="div_footer_information">
                <div className="div-footer-email">
                    <div className="div-footer-email-margin">
                        <p className="div-footer-about-title">WANT TO BE A DEALER?</p>
                        <div className="div-footer-email-divInput">
                            <input className="div-footer-email-input" placeholder="Email"></input>
                        </div>
                        <div className="div-footer-email-divButton">
                            <button className="div-footer-email-button">REQUEST</button>
                        </div>
                    </div>
                </div>
                <div className="div-footer-about">
                    <p className="div-footer-about-title">ABOUT OUR BRAND</p>
                    <a className="div-footer-about-a" href={process.env.REACT_APP_LINK_STORY}><p className="div-footer-about-links">Our Story</p></a>
                    <a className="div-footer-about-a" href={process.env.REACT_APP_LINK_NEWS}><p className="div-footer-about-links">News</p></a>
                    <a className="div-footer-about-a" href={process.env.REACT_APP_LINK_LOOKBOOK}><p className="div-footer-about-links">Lookbook</p></a>
                </div>
                <div className="div-footer-info">
                    <p className="div-footer-about-title">INFORMATION</p>
                    <a className="div-footer-about-a" target="_tab" href={process.env.REACT_APP_LINK_TERMSANDCONDITIONS}><p className="div-footer-about-links">Terms and Conditions</p></a>
                    <a className="div-footer-about-a" href={process.env.REACT_APP_LINK_PRIVACY}><p className="div-footer-about-links">Privacy Policy</p></a>
                    <a className="div-footer-about-a" href={process.env.REACT_APP_LINK_COOKIES}><p className="div-footer-about-links">Cookies Privacy</p></a>
                </div>
                <div className="div-footer-contact">
                    <p className="div-footer-about-title">CONTACTS</p>
                    <p className="div-footer-about-links">{process.env.REACT_APP_LINK_PHONE}</p>
                    <p className="div-footer-about-links">{process.env.REACT_APP_LINK_EMAIL}</p>
                    <p className="div-footer-about-links">{process.env.REACT_APP_LINK_ADDRESS}</p>
                    <p className="div-footer-about-links">{process.env.REACT_APP_LINK_COUNTRY}</p>
                </div>
            </div>

            <div className="line_footer">

            </div>
            
            <p className="p_footer_developer">Developed by <b>Perto Design</b></p>
        </footer>
    )
}

export default LoginFooter;