import * as actionTypes from '../constants/cartConstants';

export const cartReducer = (state = { cartItems: [] }, action) => {
    switch(action.type) {
        
        case actionTypes.ADD_TO_CART:

            const item = action.payload;
            
            const existItem = state.cartItems.find((x) => x.sku === item.sku);

            if(existItem){
                return{
                    ...state,
                    cartItems: state.cartItems.map((x) => x.sku === existItem.sku ? item : x)
                }
            } else {
                return {
                    ...state,
                    cartItems: [...state.cartItems, item]
                }
            }
        
        case actionTypes.REMOVE_FROM_CART:

            const itemToEliminate = action.payload;

            return{
                ...state,
                cartItems: state.cartItems.filter((x) => x.sku !== itemToEliminate)
            }
            
        case actionTypes.CLEAR_CART:
            return {
            ...state,
            cartItems: []
        }

        default:
            return state;
    }
}