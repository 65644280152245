import './ShopcartOrderCom.css';

const ShopcartOrderCom = props => {

    const qtyName = 'qty' + props.size;

    return(
        <div className="shopcart-qtySizeData-div77">
            <div className="qtySizeData-div-size">
                <p>{props.size}</p>
            </div>
            <div className="qtySizeData-div-qty">
                <input name={qtyName} onChange={props.changeHandler} className="shopcartOrderCom-qty" defaultValue={props.qty}></input>
            </div>
            <p className="qtySizeData-div-price">{parseFloat(props.price).toFixed(2)}€</p>
            <p className="qtySizeData-div-pricepvp">{parseFloat(props.pvp).toFixed(2)}€</p>
        </div>
        
    )
}

export default ShopcartOrderCom;

