//Packages
import React, { useState, useEffect, useContext } from "react";
import { GETrequest } from '../../shared/functions/requests';
import { CSVLink } from "react-csv";
import UploadStocks from "./Components/UploadStocks";
import Loading from "../shared/loading/Loading";

const UpdateStocks = (props) => {

    const [products, setProducts] = useState([]);

    const headers = [
        { label: "sku", key: "sku" },
        { label: "modified", key: "modified" },
        { label: "stock20", key: "s20" },
        { label: "stock21", key: "s21" },
        { label: "stock22", key: "s22" },
        { label: "stock23", key: "s23" },
        { label: "stock24", key: "s24" },
        { label: "stock25", key: "s25" },
        { label: "stock26", key: "s26" },
        { label: "stock27", key: "s27" },
        { label: "stock28", key: "s28" },
        { label: "stock29", key: "s29" },
        { label: "stock30", key: "s30" },
        { label: "stock31", key: "s31" },
        { label: "stock32", key: "s32" },
        { label: "stock33", key: "s33" },
        { label: "stock34", key: "s34" },
        { label: "stock35", key: "s35" },
        { label: "stock36", key: "s36" },
        { label: "stock37", key: "s37" },
        { label: "stock38", key: "s38" },
        { label: "stock39", key: "s39" },
        { label: "stock40", key: "s40" },
        { label: "stock41", key: "s41" },
        { label: "stock42", key: "s42" },
        { label: "stock43", key: "s43" },
        { label: "stock44", key: "s44" },
        { label: "stock45", key: "s45" },
        { label: "stock46", key: "s46" }
    ];
    const [data,setData] = useState();
    let data2 = [];

    // Fetch products
    useEffect(() => {
        GETrequest('/stocks/get').then((res) => {

        let count = 0;
        let numProducts = res.length;
        const promise1 = new Promise((resolve, reject) => {
            
            res.map((product) => {

                data2.push(
                    {
                        sku:product.sku,
                        modified:0,
                        s20:product.stock20,
                        s21:product.stock21,
                        s22:product.stock22,
                        s23:product.stock23,
                        s24:product.stock24,
                        s25:product.stock25,
                        s26:product.stock26,
                        s27:product.stock27,
                        s28:product.stock28,
                        s29:product.stock29,
                        s30:product.stock30,
                        s31:product.stock31,
                        s32:product.stock32,
                        s33:product.stock33,
                        s34:product.stock34,
                        s35:product.stock35,
                        s36:product.stock36,
                        s37:product.stock37,
                        s38:product.stock38,
                        s39:product.stock39,
                        s40:product.stock40,
                        s41:product.stock41,
                        s42:product.stock42,
                        s43:product.stock43,
                        s44:product.stock44,
                        s45:product.stock45,
                        s46:product.stock46,
                    }
                )

                count++;
                if(count === numProducts){
                    console.log("acabou");
                    setData(data2);
                    resolve("all data stored in csv");
                } 

            })
        });

        promise1.then((response) => {
            setProducts(res);
        })

        });

    }, []);

    if(products.length>0 ) return (   
        <div className="admin_addproduct">
            <div className="admin_pages_title">
                <div className="admin_pages_title_divResponsive2">
                    <h1>Stocks</h1>
                </div>
            </div>
            <form>
                <div style={{width:"1147px",height:"790px", marginLeft:"20px", marginBottom:"20px",float:"left",textAlign:"center"}}>
                    <p>Update your stocks using a csv file.</p>
                    <p>Download the file with the current stocks clicking on button "Download Stocks".</p>
                    <p>On the column modified, change from 0 to 1 if you want to update that product stock.</p>
                    <p>If this value is not changed to 1, even if change the stock, the product it will not be updated.</p>
                    <CSVLink data={data} headers={headers} filename={"stocks.csv"} onClick={() => console.log(data)}>
                        <button className="button" type="button">Download stocks</button>
                    </CSVLink>
                    <p>After downloading and updating the stocks, you must upload the file clicking on button "Upload Stocks".</p>
                    <UploadStocks />
                </div>
            </form>
        </div>
    );
    else return(
        <Loading />
    )
}
    
export default UpdateStocks;