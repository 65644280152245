//Style
import "./AdminAddProduct.css";

//Packages
import React, { useState, useEffect, useContext } from "react";
import { storage } from "../../../../firebase";
import { Authcontext } from "../../../../login/context/auth-context";
import { FiEdit } from "react-icons/fi";

//Components
import Div from "../../../../shared/containers/Div";
import Spi from '../../../../shared/animations/Spi';

//Images
import imgg from '../../../../images/default_image.png';

// Dialog
import DeleteDialog from "../../../../shared/DeleteDialog";
import { changeDialogVisibility } from '../../../../redux/actions/dialogActions';
import { useSelector, useDispatch } from 'react-redux';
import { VscCheck } from "react-icons/vsc";
import { Link } from 'react-router-dom';

// Refactoring
import GridComponent from "../components/GridComponent";

const AdminAddProduct = (props) => {

    const auth = useContext(Authcontext);
        
    const [previewUrl, setPreviewUrl] = useState();
    const [previewUrl2, setPreviewUrl2] = useState();
    const [previewUrl3, setPreviewUrl3] = useState();
    const [previewUrl4, setPreviewUrl4] = useState();

    //Image State
    const [image1, setImage1] = useState(null);
    const [url1, setUrl1] = useState('');
    const [image2, setImage2] = useState(null);
    const [url2, setUrl2] = useState('');
    const [image3, setImage3] = useState(null);
    const [url3, setUrl3] = useState('');
    const [image4, setImage4] = useState(null);
    const [url4, setUrl4] = useState('');

        useEffect(() => {
            if (!image1) {
              return;
            }
            const fileReader = new FileReader();
            fileReader.onload = () => {
              setPreviewUrl(fileReader.result);
            };
            fileReader.readAsDataURL(image1);
          }, [image1]);

          useEffect(() => {
            if (!image2) {
              return;
            }
            const fileReader = new FileReader();
            fileReader.onload = () => {
              setPreviewUrl2(fileReader.result);
            };
            fileReader.readAsDataURL(image2);
          }, [image2]);

          useEffect(() => {
            if (!image3) {
              return;
            }
            const fileReader = new FileReader();
            fileReader.onload = () => {
              setPreviewUrl3(fileReader.result);
            };
            fileReader.readAsDataURL(image3);
          }, [image3]);

          useEffect(() => {
            if (!image4) {
              return;
            }
            const fileReader = new FileReader();
            fileReader.onload = () => {
              setPreviewUrl4(fileReader.result);
            };
            fileReader.readAsDataURL(image4);
          }, [image4]);

        const handleImageChange = e => {

            if(e.target.files[0]){
                setImage1(e.target.files[0]);
            }
            else{
                setImage1(null);
            }
        }
        
        const handleImageChange2 = e => {
            if(e.target.files[0]){
                setImage2(e.target.files[0]);
            }
            else{
                setImage2(null);
            }
        }
        const handleImageChange3 = e => {
            if(e.target.files[0]){
                setImage3(e.target.files[0]);
            }
            else{
                setImage3(null);
            }
        }
        const handleImageChange4 = e => {
            if(e.target.files[0]){
                setImage4(e.target.files[0]);
            }
            else{
                setImage4(null);
            }
        }

    //States form
    const [formValues, setFormValues] = useState({
        title: "", color: "", sku: "", description: "", materials: "", category: "",
        price20: 0,price21: 0,price22: 0,price23: 0,price24: 0, price25: 0, price26: 0, price27: 0, price28: 0, price29: 0, price30: 0, price31: 0, price32: 0, price33: 0, price34: 0, price35: 0, price36: 0, price37: 0, price38: 0, price39: 0, price40: 0, price41: 0, price42: 0, price43: 0, price44: 0, price45: 0, price46: 0,
        pvp20: 0, pvp21: 0, pvp22: 0,pvp23: 0,pvp24: 0, pvp25: 0, pvp26: 0, pvp27: 0, pvp28: 0, pvp29: 0, pvp30: 0, pvp31: 0, pvp32: 0, pvp33: 0, pvp34: 0, pvp35: 0, pvp36: 0, pvp37: 0, pvp38: 0, pvp39: 0, pvp40: 0, pvp41: 0, pvp42: 0, pvp43: 0, pvp44: 0, pvp45: 0, pvp46: 0,
        stock20: 0,stock21: 0,stock22: 0,stock23: 0,stock24: 0, stock25: 0, stock26: 0, stock27: 0, stock28: 0, stock29: 0, stock30: 0, stock31: 0, stock32: 0, stock33: 0, stock34: 0, stock35: 0, stock36: 0, stock37: 0, stock38: 0, stock39: 0, stock40: 0, stock41: 0, stock42: 0, stock43: 0, stock44: 0, stock45: 0, stock46: 0,
        barcode20: 0,barcode21: 0,barcode22: 0,barcode23: 0,barcode24: 0, barcode25: 0, barcode26: 0, barcode27: 0, barcode28: 0, barcode29: 0, barcode30: 0, barcode31: 0, barcode32: 0, barcode33: 0, barcode34: 0, barcode35: 0, barcode36: 0, barcode37: 0, barcode38: 0, barcode39: 0, barcode40: 0, barcode41: 0, barcode42: 0, barcode43: 0, barcode44: 0, barcode45: 0, barcode46: 0,
        vendor: "", tag: "" 
    });

    //Handle change form
    const changeHandler = e => {
        setFormValues({...formValues, [e.target.name]: e.target.value})
    };

    const changeHandlerNumber = e => {
        setFormValues({...formValues, [e.target.name]: Number(e.target.value)})
    };

    //POST IMAGES to firebase
    const PostToFirebase = async event => {
        setLoading(true);
        const promise1 = new Promise((resolve, reject) => {
            var uploadTask = storage.ref(`images/${image1.name}`).put(image1);  
            uploadTask.on(
                    "state_changed",
                    snapshot => {},
                    error => {
                        console.log(error);
                    },
                    () => {
                        storage.ref("images").child(image1.name).getDownloadURL().then(url => {
                        console.log(url);
                        let publicURL = "https://storage.googleapis.com/react-hosting-73546.appspot.com/images/" + image1.name;
                        setUrl1(publicURL);
                        resolve("image 1 uploaded");
                    });
                }
            )
        });
        
        promise1.then((response) => {
                const promise2 = new Promise((resolve, reject) => {
                    var uploadTask2 = storage.ref(`images/${image2.name}`).put(image2);  
                    uploadTask2.on(
                        "state_changed",
                        snapshot => {},
                        error => {
                            console.log(error);
                        },
                        async () => {
                            await storage
                                .ref("images")
                                .child(image2.name)
                                .getDownloadURL()
                                .then((url) => {
                                    console.log(url);
                                    console.log(image2.name);
                                    let publicURL = "https://storage.googleapis.com/react-hosting-73546.appspot.com/images/" + image2.name;
                                    setUrl2(publicURL);    
                                    resolve("image 2 uploaded");
                                });
                        }
                    )
                });

                promise2.then((response) => {
                    const promise3 = new Promise((resolve, reject) => {
                        var uploadTask3 = storage.ref(`images/${image3.name}`).put(image3);  
                        uploadTask3.on(
                            "state_changed",
                            snapshot => {},
                            error => {
                                console.log(error);
                            },
                            async () => {
                                await storage
                                    .ref("images")
                                    .child(image3.name)
                                    .getDownloadURL()
                                    .then((url) => {
                                        console.log(url);
                                        console.log(image3.name);
                                        let publicURL = "https://storage.googleapis.com/react-hosting-73546.appspot.com/images/" + image3.name;
                                        setUrl3(publicURL);
                                        resolve("image 3 uploaded");
                                    });
                            }
                        )
                    });

                    promise3.then((response) => {
                            var uploadTask4 = storage.ref(`images/${image4.name}`).put(image4);  
                            uploadTask4.on(
                                "state_changed",
                                snapshot => {},
                                error => {
                                    console.log(error);
                                },
                                async () => {
                                    await storage
                                        .ref("images")
                                        .child(image4.name)
                                        .getDownloadURL()
                                        .then((url) => {
                                            console.log(url);
                                            console.log(image4.name);
                                            let publicURL = "https://storage.googleapis.com/react-hosting-73546.appspot.com/images/" + image4.name;
                                            setUrl4(publicURL);
                                            
                                        });
                                }
                            )
                        
                    });    

                });    

            });

        };

            //Available Sizes
    const [available20, setAvailable20] = useState(false);
    const [available21, setAvailable21] = useState(false);
    const [available22, setAvailable22] = useState(false);
    const [available23, setAvailable23] = useState(false);
    const [available24, setAvailable24] = useState(false);
    const [available25, setAvailable25] = useState(false);
    const [available26, setAvailable26] = useState(false);
    const [available27, setAvailable27] = useState(false);
    const [available28, setAvailable28] = useState(false);
    const [available29, setAvailable29] = useState(false);
    const [available30, setAvailable30] = useState(false);
    const [available31, setAvailable31] = useState(false);
    const [available32, setAvailable32] = useState(false);
    const [available33, setAvailable33] = useState(false);
    const [available34, setAvailable34] = useState(false);
    const [available35, setAvailable35] = useState(false);
    const [available36, setAvailable36] = useState(false);
    const [available37, setAvailable37] = useState(false);
    const [available38, setAvailable38] = useState(false);
    const [available39, setAvailable39] = useState(false);
    const [available40, setAvailable40] = useState(false);
    const [available41, setAvailable41] = useState(false);
    const [available42, setAvailable42] = useState(false);
    const [available43, setAvailable43] = useState(false);
    const [available44, setAvailable44] = useState(false);
    const [available45, setAvailable45] = useState(false);
    const [available46, setAvailable46] = useState(false);

    //Functions Available Sizes
    const changeAvailableSize20 = () => {
        if(available20) setAvailable20(false);
        else setAvailable20(true);
    }
    const changeAvailableSize21 = () => {
        if(available21) setAvailable21(false);
        else setAvailable21(true);
    }
    const changeAvailableSize22 = () => {
        if(available22) setAvailable22(false);
        else setAvailable22(true);
    }
    const changeAvailableSize23 = () => {
        if(available23) setAvailable23(false);
        else setAvailable23(true);
    }
    const changeAvailableSize24 = () => {
        if(available24) setAvailable24(false);
        else setAvailable24(true);
    }
    const changeAvailableSize25 = () => {
        if(available25) setAvailable25(false);
        else setAvailable25(true);
    }
    const changeAvailableSize26 = () => {
        if(available26) setAvailable26(false);
        else setAvailable26(true);
    }
    const changeAvailableSize27 = () => {
        if(available27) setAvailable27(false);
        else setAvailable27(true);
    }
    const changeAvailableSize28 = () => {
        if(available28) setAvailable28(false);
        else setAvailable28(true);
    }
    const changeAvailableSize29 = () => {
        if(available29) setAvailable29(false);
        else setAvailable29(true);
    }
    const changeAvailableSize30 = () => {
        if(available30) setAvailable30(false);
        else setAvailable30(true);
    }
    const changeAvailableSize31 = () => {
        if(available31) setAvailable31(false);
        else setAvailable31(true);
    }
    const changeAvailableSize32 = () => {
        if(available32) setAvailable32(false);
        else setAvailable32(true);
    }
    const changeAvailableSize33 = () => {
        if(available33) setAvailable33(false);
        else setAvailable33(true);
    }
    const changeAvailableSize34 = () => {
        if(available34) setAvailable34(false);
        else setAvailable34(true);
    }
    const changeAvailableSize35 = () => {
        if(available35) setAvailable35(false);
        else setAvailable35(true);
    }
    const changeAvailableSize36 = () => {
        if(available36) setAvailable36(false);
        else setAvailable36(true);
    }
    const changeAvailableSize37 = () => {
        if(available37) setAvailable37(false);
        else setAvailable37(true);
    }
    const changeAvailableSize38 = () => {
        if(available38) setAvailable38(false);
        else setAvailable38(true);
    }
    const changeAvailableSize39 = () => {
        if(available39) setAvailable39(false);
        else setAvailable39(true);
    }
    const changeAvailableSize40 = () => {
        if(available40) setAvailable40(false);
        else setAvailable40(true);
    }
    const changeAvailableSize41 = () => {
        if(available41) setAvailable41(false);
        else setAvailable41(true);
    }
    const changeAvailableSize42 = () => {
        if(available42) setAvailable42(false);
        else setAvailable42(true);
    }
    const changeAvailableSize43 = () => {
        if(available43) setAvailable43(false);
        else setAvailable43(true);
    }
    const changeAvailableSize44 = () => {
        if(available44) setAvailable44(false);
        else setAvailable44(true);
    }
    const changeAvailableSize45 = () => {
        if(available45) setAvailable45(false);
        else setAvailable45(true);
    }
    const changeAvailableSize46 = () => {
        if(available46) setAvailable46(false);
        else setAvailable46(true);
    }
    
    useEffect(async () => {

        if(url1 && url2 && url3 && url4){
    
                // Prepare body
                let bodyHTTP = formValues;
                bodyHTTP["image1"] = url1;
                bodyHTTP["image2"] = url2;
                bodyHTTP["image3"] = url3;
                bodyHTTP["image4"] = url4;
                bodyHTTP["tags"] = tags;
                bodyHTTP["ava20"] = available20;
                bodyHTTP["ava21"] = available21;
                bodyHTTP["ava22"] = available22;
                bodyHTTP["ava23"] = available23;
                bodyHTTP["ava24"] = available24;
                bodyHTTP["ava25"] = available25;
                bodyHTTP["ava26"] = available26;
                bodyHTTP["ava27"] = available27;
                bodyHTTP["ava28"] = available28;
                bodyHTTP["ava29"] = available29;
                bodyHTTP["ava30"] = available30;
                bodyHTTP["ava31"] = available31;
                bodyHTTP["ava32"] = available32;
                bodyHTTP["ava33"] = available33;
                bodyHTTP["ava34"] = available34;
                bodyHTTP["ava35"] = available35;
                bodyHTTP["ava36"] = available36;
                bodyHTTP["ava37"] = available37;
                bodyHTTP["ava38"] = available38;
                bodyHTTP["ava39"] = available39;
                bodyHTTP["ava40"] = available40;
                bodyHTTP["ava41"] = available41;
                bodyHTTP["ava42"] = available42;
                bodyHTTP["ava43"] = available43;
                bodyHTTP["ava44"] = available44;
                bodyHTTP["ava45"] = available45;
                bodyHTTP["ava46"] = available46;
    
                try {
                    const response = await fetch(process.env.REACT_APP_BACKEND_URL + '/products', {
                        method: 'POST',
                        headers: {
                            'Content-Type': 'application/json',
                            'Authorization' : `Bearer ${auth.token}`
                        },
                        body: JSON.stringify(bodyHTTP)
                    });
                                
                    const responseData = await response.json();
                        console.log(responseData);
                        setLoading(false);
                        setResponseData(responseData);
                        setConfirmation(true);
                    } catch (err) {
                        console.log(err);
                    }
            }
    
    },[url4]);
    

    // Dialog
    function testSave(){
        chgVisibility();
    }
    const dialogVisibility = useSelector(state => state.dialogVisibility);
    const { visibility } = dialogVisibility;
    const dispatch = useDispatch();
    const chgVisibility = () => {
        dispatch(changeDialogVisibility(!visibility));
    }

    // TAGS
    const [tags,setTags] = useState([]);
    function addTag(e){
        if(e.key === 'Enter'){
            console.log(formValues.tag);
            setTags(tags => [...tags, formValues.tag]);
            setFormValues({...formValues, tag: ""});
        }
    }
    function removeTag(tag){
        let filteredArray = tags.filter(item => item !== tag)
        setTags(filteredArray);
    }

    // confirmation action dialog
    const [confirmation,setConfirmation] = useState(false);
    const [responseData,setResponseData] = useState(false);
    
    // Loading
    const [loading,setLoading] = useState(false);

    // Possibility of creating product only if some camps are filled
    const [filledCamps,setFilledCamps] = useState(false);

    useEffect(() => {
        if(formValues.title === "" || formValues.color === "" || formValues.sku === "" || image1 === null || image2 === null || image3 === null || image4 === null){
        setFilledCamps(false);
        }
        else{
        setFilledCamps(true);
        }
    },[formValues.title,formValues.color,formValues.sku,image1,image2,image3,image4]);

    // Return
    if(confirmation){
        return(
          <div className="editUser" style={{overflow:"auto"}}>
            <div className="confirmationDialog" style={{width:"500px",minHeight:"220px", backgroundColor:"white", margin:"auto", marginTop:"200px", overflow:"auto", paddingBottom:"20px"}}>
                <div className="div_deleteDialog_img">
                  <VscCheck className="deleteDialog_img2" />
                </div>
                {responseData.message.map((mes) => {
                  return <p style={{fontSize:"12px"}}>{mes}</p>
                })}
    
                <Link to="/admin/catalog"><button className="button_back" style={{marginLeft:"0px", marginTop:"20px",marginBottom:"0px"}}>Back to catalog</button></Link>
            </div>
          </div>
        )
      }

      else if(!loading){
        return (   
            <div className="admin_addproduct">
                <div className="admin_pages_title">
                    <div className="admin_pages_title_divResponsive2">
                        <h1>New Product</h1>
                        <div className="admin_catalog_div_button">
                            {filledCamps ?
                                <button className="button" style={{float:"right",marginTop:"40px"}} onClick={testSave}>New product</button>
                            :
                                <button className="button_disabled" style={{float:"right",marginTop:"40px"}} >New product</button>
                            }
                        </div>
                    </div>
                </div>

                <form>

                <div style={{width:"797px",height:"790px", marginLeft:"20px", marginBottom:"20px",float:"left"}}>
                        <div className="editProduct_name" style={{float:"left"}}>
                            <p>Title <span style={{color:"red", fontWeight:"bold"}}>*</span></p>
                            <input placeholder="Title" name="title" onChange={changeHandler} />
                        </div>
                        <div className="editProduct_name" style={{float:"left",marginLeft:"20px"}}>
                            <p>Color <span style={{color:"red", fontWeight:"bold"}}>*</span></p>
                            <input placeholder="Color" name="color" onChange={changeHandler} />
                        </div>
                        <div className="editProduct_name" style={{float:"left",marginLeft:"20px"}}>
                            <p>Sku <span style={{color:"red", fontWeight:"bold"}}>*</span></p>
                            <input placeholder="Sku" name="sku" onChange={changeHandler} />
                        </div>

                        <div style={{width:"100%",height:"685px", backgroundColor:"white",float:"left", boxShadow:"0px 0px 10px 2px rgb(222, 222, 222)", borderRadius:"4px"}}>
                            <p style={{marginLeft:"20px", fontSize:"17px",fontFamily: 'Source Code Pro'}}>Product Info</p>
                            <div className="editProduct_div2">
                                <div>
                                    <p>Description</p>
                                    <textarea  placeholder="Description" name="description" onChange={changeHandler} />
                                </div>
                                <div style={{marginLeft:"20px"}}>
                                    <p>Materials</p>
                                    <textarea  placeholder="Materials" name="materials" onChange={changeHandler} />
                                </div>
                            </div>

                            <div className="editProduct_div3" style={{height:"400px"}}>
                                <p>Media <span style={{color:"red", fontWeight:"bold"}}>*</span></p>
                                <div className="editProduct_div3_main">
                                    {image1 ? <img src={previewUrl} alt="logo" /> : <img src={imgg} alt="logo" />}
                                    <input id="image1" type="file" onChange={handleImageChange}></input>
                                    <label for="image1"><FiEdit className="addproduct_icon"/></label>
                                </div>
                                <div className="addProduct_div3_submain">
                                    <div>
                                        {image2 ? <img src={previewUrl2} alt="logo" className="addProduct_div3_submain_img1" /> : <img src={imgg} alt="logo" className="addProduct_div3_submain_img1" />}
                                        <input id="image2" type="file" onChange={handleImageChange2}></input>
                                    <label for="image2"><FiEdit className="addproduct_icon2"/></label>
                                    </div>
                                    <div>
                                        {image3 ? <img src={previewUrl3} alt="logo" className="addProduct_div3_submain_img1" /> : <img src={imgg} alt="logo" className="addProduct_div3_submain_img1" />}
                                        <input id="image3" type="file" onChange={handleImageChange3}></input>
                                        <label for="image3"><FiEdit className="addproduct_icon2"/></label>
                                    </div>
                                    <div>
                                        {image4 ? <img src={previewUrl4} alt="logo" className="addProduct_div3_submain_img1" /> : <img src={imgg} alt="logo" className="addProduct_div3_submain_img1" />}
                                        <input id="image4" type="file" onChange={handleImageChange4}></input>
                                        <label for="image4"><FiEdit className="addproduct_icon2"/></label>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>

                    <div style={{width:"320px", marginLeft:"20px",float:"left"}}>
                        <div className="editProduct_name" style={{width:"100%"}}>
                            <p>Vendor</p>
                            <input placeholder="Vendor" name="vendor" onChange={changeHandler} />
                        </div>
                        <div className="editProduct_name" style={{width:"100%"}}>
                            <p>Category</p>
                            <input placeholder="Category" name="category" onChange={changeHandler} />
                        </div>
                        <div className="editProduct_name_specialTAGS" style={{width:"100%"}}>
                            <p>Product Tags</p>
                            <input placeholder="Tags" value={formValues.tag} name="tag" onKeyDown={addTag} onChange={changeHandler}/>
                            {tags.map((tag) => {
                                return <p className="addProduct_tag" style={{display:"inline-block", padding:"3px", paddingLeft:"10px",paddingRight:"10px",borderRadius:"7px", marginRight:"0px"}} onClick={e => removeTag(tag)}>{tag}</p>
                            })
                            }
                        </div>
                    </div>

                    <div className="addProduct_quantitys">
                        <p className="addProduct_quantitys_title">Variable Product</p>
                        
                        <div className="admin_qtySizeData_div_horizontalScrollbar">
                            <div className="admin-qtySizeData-div">
                                <div className="admin-qtySizeData-div-size">
                                    <p>20</p>
                                </div>
                                <GridComponent name="stock20" placeholder="0" onChange={changeHandlerNumber} />
                                <GridComponent name="price20" placeholder="0.00" onChange={changeHandlerNumber} />
                                <GridComponent name="pvp20" placeholder="0.00" onChange={changeHandlerNumber} />
                                <GridComponent name="barcode20" placeholder="barcode" onChange={changeHandlerNumber} />
                                {available20 ? 
                                    <div className="admin_available_checkbuttom" onClick={changeAvailableSize20}></div>
                                    :
                                    <div className="admin_available_checkbuttom_false" onClick={changeAvailableSize20}></div>
                                }
                            </div>
                            <div className="admin-qtySizeData-div">
                                <div className="admin-qtySizeData-div-size">
                                    <p>21</p>
                                </div>
                                <GridComponent name="stock21" placeholder="0" onChange={changeHandlerNumber} />
                                <GridComponent name="price21" placeholder="0.00" onChange={changeHandlerNumber} />
                                <GridComponent name="pvp21" placeholder="0.00" onChange={changeHandlerNumber} />
                                <GridComponent name="barcode21" placeholder="barcode" onChange={changeHandlerNumber} />
                                {available21 ? 
                                <div className="admin_available_checkbuttom" onClick={changeAvailableSize21}>
                                </div>
                                :
                                <div className="admin_available_checkbuttom_false" onClick={changeAvailableSize21}>
                                </div>
                                }
                            </div>
                            <div className="admin-qtySizeData-div">
                                <div className="admin-qtySizeData-div-size">
                                    <p>22</p>
                                </div>
                                <GridComponent name="stock22" placeholder="0" onChange={changeHandlerNumber} />
                                <GridComponent name="price22" placeholder="0.00" onChange={changeHandlerNumber} />
                                <GridComponent name="pvp22" placeholder="0.00" onChange={changeHandlerNumber} />
                                <GridComponent name="barcode22" placeholder="barcode" onChange={changeHandlerNumber} />
                                {available22 ? 
                                <div className="admin_available_checkbuttom" onClick={changeAvailableSize22}>
                                </div>
                                :
                                <div className="admin_available_checkbuttom_false" onClick={changeAvailableSize22}>
                                </div>
                                }
                            </div>
                            <div className="admin-qtySizeData-div">
                                <div className="admin-qtySizeData-div-size">
                                    <p>23</p>
                                </div>
                                <GridComponent name="stock23" placeholder="0" onChange={changeHandlerNumber} />
                                <GridComponent name="price23" placeholder="0.00" onChange={changeHandlerNumber} />
                                <GridComponent name="pvp23" placeholder="0.00" onChange={changeHandlerNumber} />
                                <GridComponent name="barcode23" placeholder="barcode" onChange={changeHandlerNumber} />
                                {available23 ? 
                                <div className="admin_available_checkbuttom" onClick={changeAvailableSize23}>
                                </div>
                                :
                                <div className="admin_available_checkbuttom_false" onClick={changeAvailableSize23}>
                                </div>
                                }
                            </div>
                            <div className="admin-qtySizeData-div">
                                <div className="admin-qtySizeData-div-size">
                                    <p>24</p>
                                </div>
                                <GridComponent name="stock24" placeholder="0" onChange={changeHandlerNumber} />
                                <GridComponent name="price24" placeholder="0.00" onChange={changeHandlerNumber} />
                                <GridComponent name="pvp24" placeholder="0.00" onChange={changeHandlerNumber} />
                                <GridComponent name="barcode24" placeholder="barcode" onChange={changeHandlerNumber} />
                                {available24 ? 
                                <div className="admin_available_checkbuttom" onClick={changeAvailableSize24}>
                                </div>
                                :
                                <div className="admin_available_checkbuttom_false" onClick={changeAvailableSize24}>
                                </div>
                                }
                            </div>
                            <div className="admin-qtySizeData-div">
                                <div className="admin-qtySizeData-div-size">
                                    <p>25</p>
                                </div>
                                <GridComponent name="stock25" placeholder="0" onChange={changeHandlerNumber} />
                                <GridComponent name="price25" placeholder="0.00" onChange={changeHandlerNumber} />
                                <GridComponent name="pvp25" placeholder="0.00" onChange={changeHandlerNumber} />
                                <GridComponent name="barcode25" placeholder="barcode" onChange={changeHandlerNumber} />
                                {available25 ? 
                                <div className="admin_available_checkbuttom" onClick={changeAvailableSize25}>
                                </div>
                                :
                                <div className="admin_available_checkbuttom_false" onClick={changeAvailableSize25}>
                                </div>
                                }
                            </div>
                            <div className="admin-qtySizeData-div">
                                <div className="admin-qtySizeData-div-size">
                                    <p>26</p>
                                </div>
                                <GridComponent name="stock26" placeholder="0" onChange={changeHandlerNumber} />
                                <GridComponent name="price26" placeholder="0.00" onChange={changeHandlerNumber} />
                                <GridComponent name="pvp26" placeholder="0.00" onChange={changeHandlerNumber} />
                                <GridComponent name="barcode26" placeholder="barcode" onChange={changeHandlerNumber} />
                                {available26 ? 
                                <div className="admin_available_checkbuttom" onClick={changeAvailableSize26}>
                                </div>
                                :
                                <div className="admin_available_checkbuttom_false" onClick={changeAvailableSize26}>
                                </div>
                                }
                            </div>
                            <div className="admin-qtySizeData-div">
                                <div className="admin-qtySizeData-div-size">
                                    <p>27</p>
                                </div>
                                <GridComponent name="stock27" placeholder="0" onChange={changeHandlerNumber} />
                                <GridComponent name="price27" placeholder="0.00" onChange={changeHandlerNumber} />
                                <GridComponent name="pvp27" placeholder="0.00" onChange={changeHandlerNumber} />
                                <GridComponent name="barcode27" placeholder="barcode" onChange={changeHandlerNumber} />
                                {available27 ? 
                                <div className="admin_available_checkbuttom" onClick={changeAvailableSize27}>
                                </div>
                                :
                                <div className="admin_available_checkbuttom_false" onClick={changeAvailableSize27}>
                                </div>
                                }
                            </div>
                            <div className="admin-qtySizeData-div">
                                <div className="admin-qtySizeData-div-size">
                                    <p>28</p>
                                </div>
                                <GridComponent name="stock28" placeholder="0" onChange={changeHandlerNumber} />
                                <GridComponent name="price28" placeholder="0.00" onChange={changeHandlerNumber} />
                                <GridComponent name="pvp28" placeholder="0.00" onChange={changeHandlerNumber} />
                                <GridComponent name="barcode28" placeholder="barcode" onChange={changeHandlerNumber} />
                                {available28 ? 
                                <div className="admin_available_checkbuttom" onClick={changeAvailableSize28}>
                                </div>
                                :
                                <div className="admin_available_checkbuttom_false" onClick={changeAvailableSize28}>
                                </div>
                                }
                            </div>
                            <div className="admin-qtySizeData-div">
                                <div className="admin-qtySizeData-div-size">
                                    <p>29</p>
                                </div>
                                <GridComponent name="stock29" placeholder="0" onChange={changeHandlerNumber} />
                                <GridComponent name="price29" placeholder="0.00" onChange={changeHandlerNumber} />
                                <GridComponent name="pvp29" placeholder="0.00" onChange={changeHandlerNumber} />
                                <GridComponent name="barcode29" placeholder="barcode" onChange={changeHandlerNumber} />
                                {available29 ? 
                                <div className="admin_available_checkbuttom" onClick={changeAvailableSize29}>
                                </div>
                                :
                                <div className="admin_available_checkbuttom_false" onClick={changeAvailableSize29}>
                                </div>
                                }
                            </div>
                            <div className="admin-qtySizeData-div">
                                <div className="admin-qtySizeData-div-size">
                                    <p>30</p>
                                </div>
                                <GridComponent name="stock30" placeholder="0" onChange={changeHandlerNumber} />
                                <GridComponent name="price30" placeholder="0.00" onChange={changeHandlerNumber} />
                                <GridComponent name="pvp30" placeholder="0.00" onChange={changeHandlerNumber} />
                                <GridComponent name="barcode30" placeholder="barcode" onChange={changeHandlerNumber} />
                                {available30 ? 
                                <div className="admin_available_checkbuttom" onClick={changeAvailableSize30}>
                                </div>
                                :
                                <div className="admin_available_checkbuttom_false" onClick={changeAvailableSize30}>
                                </div>
                                }
                            </div>
                            <div className="admin-qtySizeData-div">
                                <div className="admin-qtySizeData-div-size">
                                    <p>31</p>
                                </div>
                                <GridComponent name="stock31" placeholder="0" onChange={changeHandlerNumber} />
                                <GridComponent name="price31" placeholder="0.00" onChange={changeHandlerNumber} />
                                <GridComponent name="pvp31" placeholder="0.00" onChange={changeHandlerNumber} />
                                <GridComponent name="barcode31" placeholder="barcode" onChange={changeHandlerNumber} />
                                {available31 ? 
                                <div className="admin_available_checkbuttom" onClick={changeAvailableSize31}>
                                </div>
                                :
                                <div className="admin_available_checkbuttom_false" onClick={changeAvailableSize31}>
                                </div>
                                }
                            </div>
                            <div className="admin-qtySizeData-div">
                                <div className="admin-qtySizeData-div-size">
                                    <p>32</p>
                                </div>
                                <GridComponent name="stock32" placeholder="0" onChange={changeHandlerNumber} />
                                <GridComponent name="price32" placeholder="0.00" onChange={changeHandlerNumber} />
                                <GridComponent name="pvp32" placeholder="0.00" onChange={changeHandlerNumber} />
                                <GridComponent name="barcode32" placeholder="barcode" onChange={changeHandlerNumber} />
                                {available32 ? 
                                <div className="admin_available_checkbuttom" onClick={changeAvailableSize32}>
                                </div>
                                :
                                <div className="admin_available_checkbuttom_false" onClick={changeAvailableSize32}>
                                </div>
                                }
                            </div>
                            <div className="admin-qtySizeData-div">
                                <div className="admin-qtySizeData-div-size">
                                    <p>33</p>
                                </div>
                                <GridComponent name="stock33" placeholder="0" onChange={changeHandlerNumber} />
                                <GridComponent name="price33" placeholder="0.00" onChange={changeHandlerNumber} />
                                <GridComponent name="pvp33" placeholder="0.00" onChange={changeHandlerNumber} />
                                <GridComponent name="barcode33" placeholder="barcode" onChange={changeHandlerNumber} />
                                {available33 ? 
                                <div className="admin_available_checkbuttom" onClick={changeAvailableSize33}>
                                </div>
                                :
                                <div className="admin_available_checkbuttom_false" onClick={changeAvailableSize33}>
                                </div>
                                }
                            </div>
                            <div className="admin-qtySizeData-div">
                                <div className="admin-qtySizeData-div-size">
                                    <p>34</p>
                                </div>
                                <GridComponent name="stock34" placeholder="0" onChange={changeHandlerNumber} />
                                <GridComponent name="price34" placeholder="0.00" onChange={changeHandlerNumber} />
                                <GridComponent name="pvp34" placeholder="0.00" onChange={changeHandlerNumber} />
                                <GridComponent name="barcode34" placeholder="barcode" onChange={changeHandlerNumber} />
                                {available34 ? 
                                <div className="admin_available_checkbuttom" onClick={changeAvailableSize34}>
                                </div>
                                :
                                <div className="admin_available_checkbuttom_false" onClick={changeAvailableSize34}>
                                </div>
                                }
                            </div>
                            <div className="admin-qtySizeData-div">
                                <div className="admin-qtySizeData-div-size">
                                    <p>35</p>
                                </div>
                                <GridComponent name="stock35" placeholder="0" onChange={changeHandlerNumber} />
                                <GridComponent name="price35" placeholder="0.00" onChange={changeHandlerNumber} />
                                <GridComponent name="pvp35" placeholder="0.00" onChange={changeHandlerNumber} />
                                <GridComponent name="barcode35" placeholder="barcode" onChange={changeHandlerNumber} />
                                {available35 ? 
                                <div className="admin_available_checkbuttom" onClick={changeAvailableSize35}>
                                </div>
                                :
                                <div className="admin_available_checkbuttom_false" onClick={changeAvailableSize35}>
                                </div>
                                }
                            </div>
                            <div className="admin-qtySizeData-div">
                                <div className="admin-qtySizeData-div-size">
                                    <p>36</p>
                                </div>
                                <GridComponent name="stock36" placeholder="0" onChange={changeHandlerNumber} />
                                <GridComponent name="price36" placeholder="0.00" onChange={changeHandlerNumber} />
                                <GridComponent name="pvp36" placeholder="0.00" onChange={changeHandlerNumber} />
                                <GridComponent name="barcode36" placeholder="barcode" onChange={changeHandlerNumber} />
                                {available36 ? 
                                <div className="admin_available_checkbuttom" onClick={changeAvailableSize36}>
                                </div>
                                :
                                <div className="admin_available_checkbuttom_false" onClick={changeAvailableSize36}>
                                </div>
                                }
                            </div>
                            <div className="admin-qtySizeData-div">
                                <div className="admin-qtySizeData-div-size">
                                    <p>37</p>
                                </div>
                                <GridComponent name="stock37" placeholder="0" onChange={changeHandlerNumber} />
                                <GridComponent name="price37" placeholder="0.00" onChange={changeHandlerNumber} />
                                <GridComponent name="pvp37" placeholder="0.00" onChange={changeHandlerNumber} />
                                <GridComponent name="barcode37" placeholder="barcode" onChange={changeHandlerNumber} />
                                {available37 ? 
                                <div className="admin_available_checkbuttom" onClick={changeAvailableSize37}>
                                </div>
                                :
                                <div className="admin_available_checkbuttom_false" onClick={changeAvailableSize37}>
                                </div>
                                }
                            </div>
                            <div className="admin-qtySizeData-div">
                                <div className="admin-qtySizeData-div-size">
                                    <p>38</p>
                                </div>
                                <GridComponent name="stock38" placeholder="0" onChange={changeHandlerNumber} />
                                <GridComponent name="price38" placeholder="0.00" onChange={changeHandlerNumber} />
                                <GridComponent name="pvp38" placeholder="0.00" onChange={changeHandlerNumber} />
                                <GridComponent name="barcode38" placeholder="barcode" onChange={changeHandlerNumber} />
                                {available38 ? 
                                <div className="admin_available_checkbuttom" onClick={changeAvailableSize38}>
                                </div>
                                :
                                <div className="admin_available_checkbuttom_false" onClick={changeAvailableSize38}>
                                </div>
                                }
                            </div>
                            <div className="admin-qtySizeData-div">
                                <div className="admin-qtySizeData-div-size">
                                    <p>39</p>
                                </div>
                                <GridComponent name="stock39" placeholder="0" onChange={changeHandlerNumber} />
                                <GridComponent name="price39" placeholder="0.00" onChange={changeHandlerNumber} />
                                <GridComponent name="pvp39" placeholder="0.00" onChange={changeHandlerNumber} />
                                <GridComponent name="barcode39" placeholder="barcode" onChange={changeHandlerNumber} />
                                {available39 ? 
                                <div className="admin_available_checkbuttom" onClick={changeAvailableSize39}>
                                </div>
                                :
                                <div className="admin_available_checkbuttom_false" onClick={changeAvailableSize39}>
                                </div>
                                }
                            </div>
                            <div className="admin-qtySizeData-div">
                                <div className="admin-qtySizeData-div-size">
                                    <p>40</p>
                                </div>
                                <GridComponent name="stock40" placeholder="0" onChange={changeHandlerNumber} />
                                <GridComponent name="price40" placeholder="0.00" onChange={changeHandlerNumber} />
                                <GridComponent name="pvp40" placeholder="0.00" onChange={changeHandlerNumber} />
                                <GridComponent name="barcode40" placeholder="barcode" onChange={changeHandlerNumber} />
                                {available40 ? 
                                <div className="admin_available_checkbuttom" onClick={changeAvailableSize40}>
                                </div>
                                :
                                <div className="admin_available_checkbuttom_false" onClick={changeAvailableSize40}>
                                </div>
                                }
                            </div>
                            <div className="admin-qtySizeData-div">
                                <div className="admin-qtySizeData-div-size">
                                    <p>41</p>
                                </div>
                                <GridComponent name="stock41" placeholder="0" onChange={changeHandlerNumber} />
                                <GridComponent name="price41" placeholder="0.00" onChange={changeHandlerNumber} />
                                <GridComponent name="pvp41" placeholder="0.00" onChange={changeHandlerNumber} />
                                <GridComponent name="barcode41" placeholder="barcode" onChange={changeHandlerNumber} />
                                {available41 ? 
                                <div className="admin_available_checkbuttom" onClick={changeAvailableSize41}>
                                </div>
                                :
                                <div className="admin_available_checkbuttom_false" onClick={changeAvailableSize41}>
                                </div>
                                }
                            </div>
                            <div className="admin-qtySizeData-div">
                                <div className="admin-qtySizeData-div-size">
                                    <p>42</p>
                                </div>
                                <GridComponent name="stock42" placeholder="0" onChange={changeHandlerNumber} />
                                <GridComponent name="price42" placeholder="0.00" onChange={changeHandlerNumber} />
                                <GridComponent name="pvp42" placeholder="0.00" onChange={changeHandlerNumber} />
                                <GridComponent name="barcode42" placeholder="barcode" onChange={changeHandlerNumber} />
                                {available42 ? 
                                <div className="admin_available_checkbuttom" onClick={changeAvailableSize42}>
                                </div>
                                :
                                <div className="admin_available_checkbuttom_false" onClick={changeAvailableSize42}>
                                </div>
                                }
                            </div>
                            <div className="admin-qtySizeData-div">
                                <div className="admin-qtySizeData-div-size">
                                    <p>43</p>
                                </div>
                                <GridComponent name="stock43" placeholder="0" onChange={changeHandlerNumber} />
                                <GridComponent name="price43" placeholder="0.00" onChange={changeHandlerNumber} />
                                <GridComponent name="pvp43" placeholder="0.00" onChange={changeHandlerNumber} />
                                <GridComponent name="barcode43" placeholder="barcode" onChange={changeHandlerNumber} />
                                {available43 ? 
                                <div className="admin_available_checkbuttom" onClick={changeAvailableSize43}>
                                </div>
                                :
                                <div className="admin_available_checkbuttom_false" onClick={changeAvailableSize43}>
                                </div>
                                }
                            </div>
                            <div className="admin-qtySizeData-div">
                                <div className="admin-qtySizeData-div-size">
                                    <p>44</p>
                                </div>
                                <GridComponent name="stock44" placeholder="0" onChange={changeHandlerNumber} />
                                <GridComponent name="price44" placeholder="0.00" onChange={changeHandlerNumber} />
                                <GridComponent name="pvp44" placeholder="0.00" onChange={changeHandlerNumber} />
                                <GridComponent name="barcode44" placeholder="barcode" onChange={changeHandlerNumber} />
                                {available44 ? 
                                <div className="admin_available_checkbuttom" onClick={changeAvailableSize44}>
                                </div>
                                :
                                <div className="admin_available_checkbuttom_false" onClick={changeAvailableSize44}>
                                </div>
                                }
                            </div>
                            <div className="admin-qtySizeData-div">
                                <div className="admin-qtySizeData-div-size">
                                    <p>45</p>
                                </div>
                                <GridComponent name="stock45" placeholder="0" onChange={changeHandlerNumber} />
                                <GridComponent name="price45" placeholder="0.00" onChange={changeHandlerNumber} />
                                <GridComponent name="pvp45" placeholder="0.00" onChange={changeHandlerNumber} />
                                <GridComponent name="barcode45" placeholder="barcode" onChange={changeHandlerNumber} />
                                {available45 ? 
                                <div className="admin_available_checkbuttom" onClick={changeAvailableSize45}>
                                </div>
                                :
                                <div className="admin_available_checkbuttom_false" onClick={changeAvailableSize45}>
                                </div>
                                }
                            </div>
                            <div className="admin-qtySizeData-div">
                                <div className="admin-qtySizeData-div-size">
                                    <p>46</p>
                                </div>
                                <GridComponent name="stock46" placeholder="0" onChange={changeHandlerNumber} />
                                <GridComponent name="price46" placeholder="0.00" onChange={changeHandlerNumber} />
                                <GridComponent name="pvp46" placeholder="0.00" onChange={changeHandlerNumber} />
                                <GridComponent name="barcode46" placeholder="barcode" onChange={changeHandlerNumber} />
                                {available46 ? 
                                <div className="admin_available_checkbuttom" onClick={changeAvailableSize46}>
                                </div>
                                :
                                <div className="admin_available_checkbuttom_false" onClick={changeAvailableSize46}>
                                </div>
                                }
                            </div>
                        </div>
                        <Div height="210px" float="left">
                            <p className="adminAddProduct-legend">Size</p>
                            <p className="adminAddProduct-legend">Stock</p>
                            <p className="adminAddProduct-legend">Cost Price</p>
                            <p className="adminAddProduct-legend">PVP Price</p>
                            <p className="adminAddProduct-legend">Barcode</p>
                            <p className="adminAddProduct-legend">Available</p>
                        </Div>
                    </div>
                
                </form>
                {!visibility ?
                    <DeleteDialog
                        type="save" 
                        action={PostToFirebase} 
                        title="CREATE PRODUCT"
                        text="Are you sure you want to create product?" 
                    />
                    :
                    <span></span>
                }
            </div>
        );
    }
    else{
        return(
            <div className="createProduct_load_div">
                <h2>Product is being created, do not close window.</h2>
                <Spi />
            </div>
        );
    }

};

export default AdminAddProduct;