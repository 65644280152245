

// Packages
import React, { useState, useEffect } from "react";

// Components
import { Link } from 'react-router-dom';

// Functions

const Discount = () => {
   
    // Return
        return(
            <div className="admin-div_produtos-products">
                <input className="admin_catalog_search" placeholder="Search discounts" />
                <div className="admin_pages_title">
                    <div className="admin_pages_title_divResponsive2">
                        <h1>Discounts</h1>
                        <div className="admin_catalog_div_button">
                            <Link to="/admin/discounts/createDiscount"><button className="button" style={{float:"right",marginTop:"40px"}} >Create Discount</button></Link>
                        </div>
                    </div>
                </div>
                <div style={{width:"1170px",margin:"auto"}}>
                    <div style={{width:"555px",height:"140px",boxShadow:"0px 0px 10px 2px rgb(222, 222, 222)",backgroundColor:"white",borderRadius:"4px",float:"left",marginLeft:"20px", marginBottom:"20px"}}>
                        <div style={{width:"50%",height:"50px",float:"left"}}>
                            <p style={{fontSize:"20px", marginLeft:"10px",marginTop:"10px",fontFamily:"Source Code Pro"}}>Discount name</p>
                        </div>
                        <div style={{width:"50%",height:"50px",float:"left"}}>
                            <p style={{fontSize:"20px",textAlign:"right",marginRight:"10px",marginTop:"10px",color:"green",fontFamily:"Source Code Pro"}}>5€</p>
                        </div>
                        <p style={{fontSize:"15px", marginLeft:"10px",marginTop:"10px",fontFamily:"Source Code Pro", marginBottom:"25px"}}>Type: Specific products</p>
                        <button className="button_delete" style={{height:"30px",marginLeft:"10px"}}>Delete</button>
                    </div>

                </div>
            </div>
        );
}

export default Discount;