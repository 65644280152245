import React, { useState, useEffect } from "react";
import {useParams} from 'react-router-dom';

import {Link} from 'react-router-dom';

import './Quantity.css';

import Spi from "../../../../shared/animations/Spi";
import QtyFullSize from "../components/QtyFullSize";
import MainDiv from '../../../../shared/containers/MainDiv';
import FullLine from "../components/FullLine";

// Functions
import { GETrequest } from '../../../../shared/functions/requests';

const Quantity = props => {

    // Retrieve product sku from URL param
    const productName = useParams().pname;

    const [loadedData, setLoadedData] = useState();

    useEffect(() => {
        GETrequest('/products/getByName/' + productName).then((res) => {
            setLoadedData(res);
        })
    }, [productName]);   

    if(loadedData){
        return(
            <MainDiv>
                <p className="quantitySize-generalInfo-cat">{loadedData[0].category}</p>
                <p className="quantitySize-generalInfo-title">{loadedData[0].title}</p>
                <p className="quantitySize-generalInfo-info">Select the sizes and quantities to order</p>
                <FullLine />
                {loadedData.map(product => {
                    return product.active ?
                    <QtyFullSize key={product.sku} product={product} />
                    :
                    <span></span>
                })}
                <div className="quantitySize-div-totalbuttom">
                    <div className="quantitySize-div-totalbuttom-buttom">
                        <Link to="/user/catalog"><button className="button" style={{width:"100%"}}>CONTINUE SHOPPING</button></Link>
                    </div>  
                </div>
            </MainDiv>
        );
    }
    else{
        return <Spi />
    }
};

export default Quantity;