import './style.css';

import Table2 from '../../components/Table2';

//Packages
import React, {useEffect, useState, useContext} from 'react';
import { Authcontext } from '../../../../login/context/auth-context';
import { FiCalendar } from "react-icons/fi";

//Components
import Spi from '../../../../shared/animations/Spi';


const DashboardClients = () => {

    const auth = useContext(Authcontext);

    //Fetch users
    const [loadedData, setLoadedData] = useState();

    useEffect(() => {
        const sendRequest = async () => {
            try{
                const response = await fetch(process.env.REACT_APP_BACKEND_URL + '/users/get', {
                    method: 'GET',
                    headers: {
                        'Authorization': `Bearer ${auth.token}`
                    }
                });

                const responseData = await response.json();
    
                setLoadedData(responseData.users);
            } catch(err){
                console.log(err);
            }
        };
        sendRequest();
    }, [auth.token]);

    if(loadedData){

        let users;
        users = loadedData.sort(function(a,b) {
            if(a.spent < b.spent) return 1;
            if(a.spent > b.spent) return -1;
            return 0;
        });

        return(
            <div className="dashboard">
                <div className="dashboard_overview_mainDiv_titleDiv">
                <div className="dashboard_overview_titleDiv">
                    <h1>Clients</h1>
                    <div className="dashboard_optionsDiv"><FiCalendar className="dashboard_optionsDiv_icon" /><p>All Time</p></div>
                </div>
                </div>
                <div className="dashmoard_mainDiv">
                    <Table2 users={users} />
                </div>
            </div>
        )
    }else{
        return <Spi />
    }
}

export default DashboardClients;