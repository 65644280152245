import styles from "./Table1.module.css";

const Table1 = props => {
    
    if(props.products.length > 0){
        if(props.title === 'Stock'){

            //const [stockSort, setStockSort] = useState(false);

            let products;
            products = props.products.sort(function(a,b) {
                if(a.totalStock < b.totalStock) return 1;
                if(a.totalStock > b.totalStock) return -1;
                return 0;
            });

            const changeStockSort = () => {
                products = props.products.sort(function(a,b) {
                    if(a.totalStock > b.totalStock) return 1;
                    if(a.totalStock < b.totalStock) return -1;
                    return 0;
                });

                console.log(products);
                console.log("products");
            }

            return(
                <div className={styles.main}>
                     <div className={styles.tableTitleDiv}>
                        <p className={styles.tableTitle}>Product</p>
                    </div>
                    <div className={styles.tableTitleDiv2}>
                        <p className={styles.tableTitle2} onClick={changeStockSort}>{props.title}</p>
                    </div> 
                    {products.map((product) => (
                        <div>
                            <div className={styles.tableEntry}>
                                <img src={product.image1} alt=""></img>
                                <p className={styles.title}>{product.title} {product.color}</p>
                                <p className={styles.sku}>{product.sku}</p>
                                <p className={styles.stock}></p>
                            </div>
                            <div className={styles.tableEntry2}>
                                <p className={styles.title2}>{product.totalStock}</p>
                            </div>        
                        </div>
                    ))} 
                               
                </div>
            )
        }else if(props.title === 'Sales'){

            let products = props.products.sort(function(a,b) {
                if(a.sales < b.sales) return 1;
                if(a.sales > b.sales) return -1;
                return 0;
            });

            return(
                <div className={styles.main}>
                     <div className={styles.tableTitleDiv}>
                        <p className={styles.tableTitle}>Product</p>
                    </div>
                    <div className={styles.tableTitleDiv2}>
                        <p className={styles.tableTitle2}>{props.title}</p>
                    </div> 
                    {products.map((product) => (
                        <div>
                            <div className={styles.tableEntry}>
                                <img src={product.image1} alt=""></img>
                                <p className={styles.title}>{product.title} {product.color}</p>
                                <p className={styles.sku}>{product.sku}</p>
                                <p className={styles.stock}></p>
                            </div>
                            <div className={styles.tableEntry2}>
                                <p className={styles.title2}>{parseFloat(product.sales).toFixed(2)}€</p>
                            </div>        
                        </div>
                    ))} 
                               
                </div>
            )
        }else{

            /*let products = props.products.sort(function(a,b) {
                if(a.unitsSold < b.unitsSold) return 1;
                if(a.unitsSold > b.unitsSold) return -1;
                return 0;
            });*/

            return(
                <div className={styles.main}>
                     <div className={styles.tableTitleDiv}>
                        <p className={styles.tableTitle}>Product</p>
                    </div>
                    <div className={styles.tableTitleDiv2}>
                        <p className={styles.tableTitle2}>{props.title}</p>
                    </div> 
                    {props.products.map((product) => (
                        <div>
                            <div className={styles.tableEntry}>
                                <img src={product.image1} alt=""></img>
                                <p className={styles.title}>{product.title} {product.color}</p>
                                <p className={styles.sku}>{product.sku}</p>
                                <p className={styles.stock}></p>
                            </div>
                            <div className={styles.tableEntry2}>
                                <p className={styles.title2}>{product.unitsSold}</p>
                            </div>        
                        </div>
                    ))} 
                               
                </div>
            )
        }
    }else return(<div></div>)
    
}

export default Table1;