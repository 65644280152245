//Style
import './AdminEditUser.css';

//Packages
import React, { useState, useContext, useEffect } from "react";
import 'react-toastify/dist/ReactToastify.css';
import { Authcontext } from "../../../../login/context/auth-context";
import { useParams } from "react-router";
import { Link } from 'react-router-dom';
import { VscCheck } from "react-icons/vsc";

//Components
import Loading from '../../../shared/loading/Loading';

// To organize
import DeleteDialog from '../../../../shared/DeleteDialog';
import { changeDialogVisibility } from '../../../../redux/actions/dialogActions';
import { useSelector, useDispatch } from 'react-redux';

// HTTP Request
import { GETauthResourceRequest } from '../../../../shared/functions/requests';

const AdminEditUser = (props) => {

  const auth = useContext(Authcontext);

  const userEmail = useParams().uid;
  const [formValues, setFormValues] = useState();
  const [responseData,setResponseData] = useState();

  //Fetch user
  useEffect(() => {

    GETauthResourceRequest(auth,'/users/get',userEmail).then((res) => {
      setFormValues(res[0]);
    })

  }, [auth, userEmail]);

  //Handle change form
  const changeHandler = e => {
    setFormValues({...formValues, [e.target.name]: e.target.value})
  }

  // Confirmation Dialog
  const [loading,setLoading] = useState(false);
  const [confirmation, setConfirmation] = useState(false);

  //Patch to database
  const postToDatabse = async event => {
    setLoading(true);
    //signUp(formValues.email, formValues.password);
    const url = `${process.env.REACT_APP_BACKEND_URL}/users/editUser/byEmail`;
    try {
      const response = await fetch(url, {
        method: 'PATCH',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${auth.token}`
        },
        body: JSON.stringify({
          email: formValues.email,
          companyName: formValues.companyName,
          customerId: formValues.customerId,
          discount: formValues.discount,
          phoneNumber: formValues.phoneNumber,
          billingAdress: formValues.billingAdress,
          billingAdressCity: formValues.billingAdressCity,
          billingAdressCountry: formValues.billingAdressCountry,
          billingAdressZipCode: formValues.billingAdressZipCode,
          shippingAdress1: formValues.shippingAdress1,
          shippingAdress1City: formValues.shippingAdress1City,
          shippingAdress1Country: formValues.shippingAdress1Country,
          shippingAdress1ZipCode: formValues.shippingAdress1ZipCode,
          shippingAdress2: formValues.shippingAdress2,
          shippingAdress2City: formValues.shippingAdress2City,
          shippingAdress2Country: formValues.shippingAdress2Country,
          shippingAdress2ZipCode: formValues.shippingAdress2ZipCode,
          shippingAdress3: formValues.shippingAdress3,
          shippingAdress3City: formValues.shippingAdress3City,
          shippingAdress3Country: formValues.shippingAdress3Country,
          shippingAdress3ZipCode: formValues.shippingAdress3ZipCode
        })
      });
      const resData = await response.json();
      setResponseData(resData);

    }  catch (err) {
      console.log(err);
    } finally{
      setLoading(false);
      setConfirmation(true);
    }
  }
  
  const deleteUser = async e => {
    try {
      const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/users/delete/${formValues.email}`, {
        method: 'DELETE',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${auth.token}`
        }
      });
      const resData = await response.json();
      setResponseData(resData);
    }catch(err){
      console.log("erro");
    } finally{
      setLoading(false);
      setConfirmation(true);
    }
  }

  // Dialog
  const [deleteDialog,setdeleteDialog] = useState();
    function testDelete(){
      chgVisibility();
      setdeleteDialog(true);
    }
    const dialogVisibility = useSelector(state => state.dialogVisibility);
    const { visibility } = dialogVisibility;
    const dispatch = useDispatch();
    const chgVisibility = () => {
        dispatch(changeDialogVisibility(!visibility));
    }
  
  // Save dialog
  function testSave(){
    chgVisibility();
    setdeleteDialog(false);
  }

  // Return
  if(confirmation){
    return(
      <div className="editUser" style={{overflow:"auto"}}>
        <div className="confirmationDialog" style={{width:"500px",height:"240px", backgroundColor:"white", margin:"auto", marginTop:"200px", overflow:"auto"}}>
            <div className="div_deleteDialog_img">
              <VscCheck className="deleteDialog_img2" />
            </div>
          <p>{responseData.message}</p>
          {responseData.message === "User successfully updated" ?
            <Link to="/admin/users"><button className="button_back" style={{marginLeft:"9px", marginTop:"20px"}}>Back to users</button></Link>
          :
            <Link to="/admin/users"><button className="button_back" style={{marginLeft:"0px", marginTop:"20px"}}>Back to users</button></Link>
          }
          {responseData.message === "User successfully updated" ?
            <button className="button" style={{marginLeft:"50px", marginTop:"20px"}} onClick={e => setConfirmation(false)}>Check user</button>
          :
            <span></span>
          }
        </div>
      </div>
    )
  }
  else if(formValues && !loading){
    return (
        <div className="editUser">
            <div className="admin_editUser_titleDiv">
              <div className="admin_editUser_responsiveTitle">
                <h1>Edit User</h1>
                <button className="button_save" style={{float:"right",marginTop:"55px", marginLeft:"10px"}} onClick={testSave} type="button">Save</button>
                <button className="button_delete" style={{float:"right",marginTop:"55px"}} onClick={testDelete} type="button">Delete</button>
              </div>
            </div>
          <div className="div_main_principal_tipo">
          <form>
            <div className="editUser_email">
              <p>Email</p>
              <input defaultValue={formValues.email} onChange={changeHandler} name="email" placeholder="Email"></input>
            </div>
            <div className="editUser_email" style={{width:"220px"}}>
              <p style={{marginLeft:"30px"}}>Discount</p>
              <input style={{width:"130px",float:"left",marginLeft:"30px"}} defaultValue={formValues.discount} onChange={changeHandler} name="discount" placeholder="Discount"></input>
              <p style={{display:"inline-block", marginLeft:"10px",fontSize:"17px",float:"left",marginTop:"7px"}}>%</p>
            </div>
            <div className="company_details_div">
              <div className="company_details_div_1">
                <p>Company Details</p>
              </div>
              <div className="company_details_div_companyName">
                <p>Company Name</p>
                <input defaultValue={formValues.companyName} onChange={changeHandler} name="companyName" placeholder="Company Name"></input>
              </div>
              <div className="company_details_div_companyName">
                <p>Customer ID</p>
                <input defaultValue={formValues.customerId} onChange={changeHandler} name="customerId" placeholder="Customer ID" ></input>
              </div>
              <div className="company_details_div_companyName">
                <p>Phone Number</p>
                <input defaultValue={formValues.phoneNumber} onChange={changeHandler} name="phoneNumber" placeholder="Phone number" ></input>
              </div>
              <div className="company_details_div_companyName">
                <p>Billing Address</p>
                <input defaultValue={formValues.billingAdress} onChange={changeHandler} name="billingAdress" placeholder="Billing Adress" ></input>
              </div>
              <div className="company_details_div_cityCountry">
                <p>City</p>
                <input defaultValue={formValues.billingAdressCity} onChange={changeHandler} name="billingAdressCity" placeholder="City" ></input>
              </div>
              <div className="company_details_div_cityCountry">
                <p>Country</p>
                <input defaultValue={formValues.billingAdressCountry} onChange={changeHandler} name="billingAdressCountry" placeholder="Country" ></input>
              </div>
              <div className="company_details_div_companyName">
                <p>Zip Code</p>
                <input  defaultValue={formValues.billingAdressZipCode} onChange={changeHandler} name="billingAdressZipCode" placeholder="Zip code" ></input>
              </div>
            </div>

            <div className="shippingAdress_div">
              <div className="company_details_div_1">
                <p>Shipping Address 01</p>
              </div>
              <div className="company_details_div_companyName">
                <p>Address</p>
                <input defaultValue={formValues.shippingAdress1} onChange={changeHandler} name="shippingAdress1" placeholder="Adress" ></input>
              </div>
              <div className="company_details_div_cityCountry">
                <p>City</p>
                <input defaultValue={formValues.shippingAdress1City} onChange={changeHandler} name="shippingAdress1City" placeholder="City" ></input>
              </div>
              <div className="company_details_div_cityCountry">
                <p>Country</p>
                <input defaultValue={formValues.shippingAdress1Country} onChange={changeHandler} name="shippingAdress1Country" placeholder="Country" ></input>
              </div>
              <div className="company_details_div_companyName">
                <p>Zip Code</p>
                <input defaultValue={formValues.shippingAdress1ZipCode} onChange={changeHandler} name="shippingAdress1ZipCode" placeholder="Zip code" ></input>
              </div>
            </div>

            <div className="shippingAdress_div">
              <div className="company_details_div_1">
                <p>Shipping Address 02</p>
              </div>
              <div className="company_details_div_companyName">
                <p>Address</p>
                <input  defaultValue={formValues.shippingAdress2} onChange={changeHandler} name="shippingAdress2" placeholder="Adress" ></input>
              </div>
              <div className="company_details_div_cityCountry">
                <p>City</p>
                <input defaultValue={formValues.shippingAdress2City} onChange={changeHandler} name="shippingAdress2City" placeholder="City" ></input>
              </div>
              <div className="company_details_div_cityCountry">
                <p>Country</p>
                <input defaultValue={formValues.shippingAdress2Country} onChange={changeHandler} name="shippingAdress2Country" placeholder="Country" ></input>
              </div>
              <div className="company_details_div_companyName">
                <p>Zip Code</p>
                <input defaultValue={formValues.shippingAdress2ZipCode} onChange={changeHandler} name="shippingAdress2ZipCode" width="85%" placeholder="Zip code" ></input>
              </div>
            </div>

            <div className="shippingAdress_div">
              <div className="company_details_div_1">
                <p>Shipping Address 03</p>
              </div>
              <div className="company_details_div_companyName">
                <p>Address</p>
                <input defaultValue={formValues.shippingAdress3} onChange={changeHandler} name="shippingAdress3" placeholder="Adress" ></input>
              </div>
              <div className="company_details_div_cityCountry">
                <p>City</p>
                <input defaultValue={formValues.shippingAdress3City} onChange={changeHandler} name="shippingAdress3City" placeholder="City" ></input>
              </div>
              <div className="company_details_div_cityCountry">
                <p>Country</p>
                <input defaultValue={formValues.shippingAdress3Country} onChange={changeHandler} name="shippingAdress3Country" placeholder="Country" ></input>
              </div>
              <div className="company_details_div_companyName">
                <p>Zip Code</p>
                <input defaultValue={formValues.shippingAdress3ZipCode} onChange={changeHandler} name="shippingAdress3ZipCode" width="85%" placeholder="Zip code" ></input>
              </div>
            </div>
          </form>
          {!visibility ?
            (deleteDialog ? 
              <DeleteDialog
                type="delete" 
                action={deleteUser} 
                title="DELETE USER"
                text="This action will delete this user from B2B. Are you sure you want to delete it?" 
              /> 
              :
              <DeleteDialog
                type="save" 
                action={postToDatabse} 
                title="SAVE USER"
                text="Are you sure you want to save user information?" 
              />
            )
            :
            <span></span>
          }
          </div>
        </div>
    );
  } else return(
    <Loading/>
  )
};

export default AdminEditUser;