import React, { useState,useContext } from "react";
import './ImportProducts.css';
import { Authcontext } from "../../../login/context/auth-context";
import Loading from "../../shared/loading/Loading";

const ImportProducts = () => {

  const auth = useContext(Authcontext);

  // File and data
    const [file, setFile] = useState();
    const [array, setArray] = useState();
    const [head,setHead] =useState()
    const [fileName,setFileName] = useState("No file chosen");

  // Manage file
    const fileReader = new FileReader();
    const handleOnChange = (e) => {
      if(e.target.files[0]){
        setFile(e.target.files[0]);
        setFileName(e.target.files[0].name);  
      }
    };
  
    const csvFileToArray = string => {
      const csvHeader = string.slice(0, string.indexOf("\n")).split("\t");
      setHead(csvHeader);

      const csvRows = string.slice(string.indexOf("\n") + 1).split("\n");
  
      const array = csvRows.map(i => {
        const values = i.split("\t");
        const obj = csvHeader.reduce((object, header, index) => {
          object[header] = values[index];
          return object;
        }, {});
        return obj;
      });
  
      setArray(array);
      console.log("csvRows");
      console.log(array);
    };
  
    const handleOnSubmit = (e) => {
      e.preventDefault();

      if (file) {

        fileReader.onload = function (event) {
          const text = event.target.result;
          console.log(text);
          csvFileToArray(text);
        };

        fileReader.readAsText(file);
      }

    }

    // Correct excel header
    const correctHeader = ["title","color","sku","vendor","category","description","materials","tags","image1","image2","image3","image4",
                           "cost20","cost21","cost22","cost23","cost24","cost25","cost26","cost27","cost28","cost29","cost30","cost31","cost32","cost33","cost34","cost35","cost36","cost37","cost38","cost39","cost40","cost41","cost42","cost43","cost44","cost45","cost46",
                           "pvp20","pvp21","pvp22","pvp23","pvp24","pvp25","pvp26","pvp27","pvp28","pvp29","pvp30","pvp31","pvp32","pvp33","pvp34","pvp35","pvp36","pvp37","pvp38","pvp39","pvp40","pvp41","pvp42","pvp43","pvp44","pvp45","pvp46",
                           "stock20","stock21","stock22","stock23","stock24","stock25","stock26","stock27","stock28","stock29","stock30","stock31","stock32","stock33","stock34","stock35","stock36","stock37","stock38","stock39","stock40","stock41","stock42","stock43","stock44","stock45","stock46",
                           "barcode20","barcode21","barcode22","barcode23","barcode24","barcode25","barcode26","barcode27","barcode28","barcode29","barcode30","barcode31","barcode32","barcode33","barcode34","barcode35","barcode36","barcode37","barcode38","barcode39","barcode40","barcode41","barcode42","barcode43","barcode44","barcode45","barcode46",
                           "sku20","sku21","sku22","sku23","sku24","sku25","sku26","sku27","sku28","sku29","sku30","sku31","sku32","sku33","sku34","sku35","sku36","sku37","sku38","sku39","sku40","sku41","sku42","sku43","sku44","sku45","sku46\r"
                          ];

    // Error found
    const errors = [];

    async function checkExcel(){
      // Check if table has right header
      for(let i=0;i<head.length;i++){
        if(head[i] !== correctHeader[i]) errors.push({right:correctHeader[i],wrong:head[i]});
      }

      if(errors.length === 0){
        setLoading(true);

        console.log("No errors found");
        for(let j=0;j<array.length;j++){

          // Check available sizes
          let ava20, ava21, ava22, ava23, ava24, ava25, ava26, ava27, ava28, ava29, ava30, ava31, ava32, ava33, ava34, ava35, ava36, ava37, ava38, ava39, ava40, ava41, ava42, ava43, ava44, ava45, ava46;
          if(array[j].stock20) ava20 = true; else ava20 = false;
          if(array[j].stock21) ava21 = true; else ava21 = false;
          if(array[j].stock22) ava22 = true; else ava22 = false;
          if(array[j].stock23) ava23 = true; else ava23 = false;
          if(array[j].stock24) ava24 = true; else ava24 = false;
          if(array[j].stock25) ava25 = true; else ava25 = false;
          if(array[j].stock26) ava26 = true; else ava26 = false;
          if(array[j].stock27) ava27 = true; else ava27 = false;
          if(array[j].stock28) ava28 = true; else ava28 = false;
          if(array[j].stock29) ava29 = true; else ava29 = false;
          if(array[j].stock30) ava30 = true; else ava30 = false;
          if(array[j].stock31) ava31 = true; else ava31 = false;
          if(array[j].stock32) ava32 = true; else ava32 = false;
          if(array[j].stock33) ava33 = true; else ava33 = false;
          if(array[j].stock34) ava34 = true; else ava34 = false;
          if(array[j].stock35) ava35 = true; else ava35 = false;
          if(array[j].stock36) ava36 = true; else ava36 = false;
          if(array[j].stock37) ava37 = true; else ava37 = false;
          if(array[j].stock38) ava38 = true; else ava38 = false;
          if(array[j].stock39) ava39 = true; else ava39 = false;
          if(array[j].stock40) ava40 = true; else ava40 = false;
          if(array[j].stock41) ava41 = true; else ava41 = false;
          if(array[j].stock42) ava42 = true; else ava42 = false;
          if(array[j].stock43) ava43 = true; else ava43 = false;
          if(array[j].stock44) ava44 = true; else ava44 = false;
          if(array[j].stock45) ava45 = true; else ava45 = false;
          if(array[j].stock46) ava46 = true; else ava46 = false;

          // Define URL images
          let image1 = "https://storage.googleapis.com/react-hosting-73546.appspot.com/images/" + array[j].image1 + ".jpg";
          let image2 = "https://storage.googleapis.com/react-hosting-73546.appspot.com/images/" + array[j].image2 + ".jpg";
          let image3 = "https://storage.googleapis.com/react-hosting-73546.appspot.com/images/" + array[j].image3 + ".jpg";
          let image4 = "https://storage.googleapis.com/react-hosting-73546.appspot.com/images/" + array[j].image4 + ".jpg";

          // Tags
          let tags = array[j].tags.split(",");
          console.log(tags);


          let userBody = JSON.stringify({
            title: array[j].title,
            color: array[j].color,
            sku: array[j].sku,
            vendor: array[j].vendor,
            category: array[j].category,
            description: array[j].description,
            materials: array[j].materials,
            image1: image1,
            image2: image2,
            image3: image3,
            image4: image4,
            tags: tags,
            ava20: ava20,
            ava21: ava21,
            ava22: ava22,
            ava23: ava23,
            ava24: ava24,
            ava25: ava25,
            ava26: ava26,
            ava27: ava27,
            ava28: ava28,
            ava29: ava29,
            ava30: ava30,
            ava31: ava31,
            ava32: ava32,
            ava33: ava33,
            ava34: ava34,
            ava35: ava35,
            ava36: ava36,
            ava37: ava37,
            ava38: ava38,
            ava39: ava39,
            ava40: ava40,
            ava41: ava41,
            ava42: ava42,
            ava43: ava43,
            ava44: ava44,
            ava45: ava45,
            ava46: ava46,
            cost20: array[j].cost20,
            cost21: array[j].cost21,
            cost22: array[j].cost22,
            cost23: array[j].cost23,
            cost24: array[j].cost24,
            cost25: array[j].cost25,
            cost26: array[j].cost26,
            cost27: array[j].cost27,
            cost28: array[j].cost28,
            cost29: array[j].cost29,
            cost30: array[j].cost30,
            cost31: array[j].cost31,
            cost32: array[j].cost32,
            cost33: array[j].cost33,
            cost34: array[j].cost34,
            cost35: array[j].cost35,
            cost36: array[j].cost36,
            cost37: array[j].cost37,
            cost38: array[j].cost38,
            cost39: array[j].cost39,
            cost40: array[j].cost40,
            cost41: array[j].cost41,
            cost42: array[j].cost42,
            cost43: array[j].cost43,
            cost44: array[j].cost44,
            cost45: array[j].cost45,
            cost46: array[j].cost46,
            pvp20: array[j].pvp20,
            pvp21: array[j].pvp21,
            pvp22: array[j].pvp22,
            pvp23: array[j].pvp23,
            pvp24: array[j].pvp24,
            pvp25: array[j].pvp25,
            pvp26: array[j].pvp26,
            pvp27: array[j].pvp27,
            pvp28: array[j].pvp28,
            pvp29: array[j].pvp29,
            pvp30: array[j].pvp30,
            pvp31: array[j].pvp31,
            pvp32: array[j].pvp32,
            pvp33: array[j].pvp33,
            pvp34: array[j].pvp34,
            pvp35: array[j].pvp35,
            pvp36: array[j].pvp36,
            pvp37: array[j].pvp37,
            pvp38: array[j].pvp38,
            pvp39: array[j].pvp39,
            pvp40: array[j].pvp40,
            pvp41: array[j].pvp41,
            pvp42: array[j].pvp42,
            pvp43: array[j].pvp43,
            pvp44: array[j].pvp44,
            pvp45: array[j].pvp45,
            pvp46: array[j].pvp46,
            stock20: array[j].stock20,
            stock21: array[j].stock21,
            stock22: array[j].stock22,
            stock23: array[j].stock23,
            stock24: array[j].stock24,
            stock25: array[j].stock25,
            stock26: array[j].stock26,
            stock27: array[j].stock27,
            stock28: array[j].stock28,
            stock29: array[j].stock29,
            stock30: array[j].stock30,
            stock31: array[j].stock31,
            stock32: array[j].stock32,
            stock33: array[j].stock33,
            stock34: array[j].stock34,
            stock35: array[j].stock35,
            stock36: array[j].stock36,
            stock37: array[j].stock37,
            stock38: array[j].stock38,
            stock39: array[j].stock39,
            stock40: array[j].stock40,
            stock41: array[j].stock41,
            stock42: array[j].stock42,
            stock43: array[j].stock43,
            stock44: array[j].stock44,
            stock45: array[j].stock45,
            stock46: array[j].stock46,
            barcode20: array[j].barcode20,
            barcode21: array[j].barcode21,
            barcode22: array[j].barcode22,
            barcode23: array[j].barcode23,
            barcode24: array[j].barcode24,
            barcode25: array[j].barcode25,
            barcode26: array[j].barcode26,
            barcode27: array[j].barcode27,
            barcode28: array[j].barcode28,
            barcode29: array[j].barcode29,
            barcode30: array[j].barcode30,
            barcode31: array[j].barcode31,
            barcode32: array[j].barcode32,
            barcode33: array[j].barcode33,
            barcode34: array[j].barcode34,
            barcode35: array[j].barcode35,
            barcode36: array[j].barcode36,
            barcode37: array[j].barcode37,
            barcode38: array[j].barcode38,
            barcode39: array[j].barcode39,
            barcode40: array[j].barcode40,
            barcode41: array[j].barcode41,
            barcode42: array[j].barcode42,
            barcode43: array[j].barcode43,
            barcode44: array[j].barcode44,
            barcode45: array[j].barcode45,
            barcode46: array[j].barcode46,
            sku20: array[j].sku20,
            sku21: array[j].sku21,
            sku22: array[j].sku22,
            sku23: array[j].sku23,
            sku24: array[j].sku24,
            sku25: array[j].sku25,
            sku26: array[j].sku26,
            sku27: array[j].sku27,
            sku28: array[j].sku28,
            sku29: array[j].sku29,
            sku30: array[j].sku30,
            sku31: array[j].sku31,
            sku32: array[j].sku32,
            sku33: array[j].sku33,
            sku34: array[j].sku34,
            sku35: array[j].sku35,
            sku36: array[j].sku36,
            sku37: array[j].sku37,
            sku38: array[j].sku38,
            sku39: array[j].sku39,
            sku40: array[j].sku40,
            sku41: array[j].sku41,
            sku42: array[j].sku42,
            sku43: array[j].sku43,
            sku44: array[j].sku44,
            sku45: array[j].sku45,
            sku46: array[j].sku46
          })
          await post(userBody);
          console.log(userBody);
        }
        setLoading(false);
      } 
      else{
        console.log(errors);
      }
    }

  // HTTP request POST
  const post = async userBody => {
    try {
      const response = await fetch(process.env.REACT_APP_BACKEND_URL + '/products', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${auth.token}`
        },
        body: userBody
      });
      const responseData = await response.json();
      console.log(responseData);
      let responseArray = responses;
      responseArray.push(responseData.message);
      setResponses(responseArray);
      
    }  catch (err) {
      console.log(err);
    }
  };

  // Loading
  const [loading,setLoading] = useState(false);

  // Response
  const [responses,setResponses] = useState([]);

  if(!loading){
    return (
      <div style={{marginLeft:"230px",backgroundColor:"#F4F3F8", minHeight:"100vh"}}>
        <div style={{width:"100%",height:"90px",backgroundColor:"white",overflow:"auto"}}>
          <h1 style={{marginLeft:"20px",marginTop:"30px", fontFamily: 'Source Code Pro',fontWeight:"500", fontSize:"38px"}}>Import products</h1>
        </div>
        <form>
          <input id="idInput" type={"file"} style={{display:"none"}} accept={".tsv"}onChange={handleOnChange}/>
          <label className="inputUsers_excel" htmlFor="idInput">Choose csv file</label>
          <p style={{textAlign:"center"}}>{fileName}</p>
          <button className="button" style={{display:"block", margin:"auto"}} onClick={(e) => { handleOnSubmit(e);}}>Import file</button>
        </form>
        <br />

        {array ?
          <React.Fragment>
            <div style={{width:"1000px", overflow:"scroll", margin:"auto", border:"1px solid black"}}>
              <table>
                <thead>
                  <tr key={"header"}>
                    {head.map((key) => (
                      <th>{key}</th>
                    ))}
                  </tr>
                </thead>
        
                <tbody>
                  {array.map((item) => (
                    <tr key={item.id}>
                      {Object.values(item).map((val) => (
                        <td>{val}</td>
                      ))}
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
            <button className="button_save" style={{display:"block", margin:"auto", marginTop:"20px"}} onClick={checkExcel}>Create products</button>
          </React.Fragment> 
        :
        <span></span>
        }

      </div>
    );
  }
  else{
    return(
      <Loading/>
    )
  }
}

export default ImportProducts;