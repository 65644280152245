//Style
import './PurchaseConfirmation.css';

//Components
import Header from '../../../../user/shared/header/Header';

const PurchaseConfirmation = props => {

    return(
        <div>
            <Header />
            <div className="purchaseConfirmation">
                <p className="purchaseConfirmation-thanks">Thank you</p>
                <p className="purchaseConfirmation-order">Your order will be reviewd and we will keep in touch with you</p>
            </div>
        </div>
    )
}

export default PurchaseConfirmation;