const GridComponent = props => {
    return(
        <div 
            style={{
                width:"95%", 
                height:"30px", 
                border:"1px solid #D4D2D6", 
                overflow:"hidden", marginTop:"4px", 
                borderRadius:"3px"
            }} 
        >
            <input 
                name={props.name} 
                placeholder={props.placeholder} 
                onChange={props.onChange}
                style={{
                    width:"90%",
                    border: "0",
                    outline: "none",
                    fontSize: "13px",
                    marginTop: "6px",
                    textAlign: "center",
                    fontFamily: "Source Code Pro",
                    fontWeight: "500",
                }}
            >
            </input>
        </div>
    )
}

export default GridComponent;