//Style
import './UserCatalog.css';

//Packages
import React, { useEffect, useState } from "react";
import {useSelector, useDispatch} from 'react-redux';
import { getProducts as listProducts } from '../../../../redux/actions/productActions';

//Custom hooks

//Components
import Product from '../components/Product';
import Spi from '../../../../shared/animations/Spi';
import Search from '../../../../shared/formElements/Search';

// -- FUNCTION
const UserCatalog = props => {

    //Redux - fetch all products to shopping cart
    const [stateProducts, setStateProducts] = useState([]);
    const dispatch = useDispatch();
    const getProducts = useSelector((state) => state.getProducts);
    let { products, loading } = getProducts;
    useEffect(() => {
        setStateProducts(products);
    }, [products]);

    useEffect(() => {
        dispatch(listProducts());
    }, [dispatch]);

    //Filter by category
    const [checkbox1, setCheckbox1] = useState(0);
    const [checkbox2, setCheckbox2] = useState(0);
    const [checkbox3, setCheckbox3] = useState(0);
    
    //Filter by gender
    const [checkbox4, setCheckbox4] = useState(0);
    const [checkbox5, setCheckbox5] = useState(0);

    const styleCheck = {
        backgroundColor : "#BCA291"
      }
    function handleCheckbox1(){
        if(checkbox1 === 1){
            setCheckbox1(0);
            setStateProducts(products);
        }
        else{
            setCheckbox1(1);
            setCheckbox2(0);
            setCheckbox3(0);
            if(checkbox4 === 1){
                setStateProducts(products.filter(p => {return p.category.split(" ")[0] === 'Boots' && p.category.split(" ")[1] === 'Boy'}));
            }
            else if(checkbox5 === 1){
                setStateProducts(products.filter(p => {return p.category.split(" ")[0] === 'Boots' && p.category.split(" ")[1] === 'Girl'}));
            }
            else {
                setStateProducts(products.filter(p => p.category.split(" ")[0] === 'Boots'));
            }
        }
    }
    function handleCheckbox2(){
        if(checkbox2 === 1){
            setCheckbox2(0);
            setStateProducts(products);
        }
        else{
            setCheckbox2(1);
            setCheckbox3(0);
            setCheckbox1(0);
            if(checkbox4 === 1){
                setStateProducts(products.filter(p => {return p.category.split(" ")[0] === 'Sneakers' && p.category.split(" ")[1] === 'Boy'}));
            }
            else if(checkbox5 === 1){
                setStateProducts(products.filter(p => {return p.category.split(" ")[0] === 'Sneakers' && p.category.split(" ")[1] === 'Girl'}));
            }
            else {
                setStateProducts(products.filter(p => p.category.split(" ")[0] === 'Sneakers'));
            }
        }
    }
    function handleCheckbox3(){
        if(checkbox3 === 1){
            setCheckbox3(0);
            setStateProducts(products);
        }
        else{
            setCheckbox3(1);
            setCheckbox2(0);
            setCheckbox1(0);
            if(checkbox4 === 1){
                setStateProducts(products.filter(p => {return p.category.split(" ")[0] === 'Sandals' && p.category.split(" ")[1] === 'Boy'}));
            }
            else if(checkbox5 === 1){
                setStateProducts(products.filter(p => {return p.category.split(" ")[0] === 'Sandals' && p.category.split(" ")[1] === 'Girl'}));
            }
            else {
                setStateProducts(products.filter(p => p.category.split(" ")[0] === 'Sandals'));
            }
        }
    }

    //Functions gender
    function handleCheckbox4(){
        if(checkbox4 === 1){
            setCheckbox4(0);
            if(checkbox1 === 1){
                setStateProducts(products.filter(p => p.category.split(" ")[0] === 'Boots'));
            }
            else if(checkbox2 === 1){
                setStateProducts(products.filter(p => p.category.split(" ")[0] === 'Sneakers'));
            }
            else if(checkbox3 === 1){
                setStateProducts(products.filter(p => p.category.split(" ")[0] === 'Sandals'));
            }
            else setStateProducts(products);
        }
        else{
            setCheckbox4(1);
            setCheckbox5(0);
            if(checkbox1 === 1){
                setStateProducts(products.filter(p => {return p.category.split(" ")[0] === 'Boots' && p.category.split(" ")[1] === 'Boy'}));
            }
            else if(checkbox2 === 1){
                setStateProducts(products.filter(p => {return p.category.split(" ")[0] === 'Sneakers' && p.category.split(" ")[1] === 'Boy'}));
            }
            else if(checkbox3 === 1){
                setStateProducts(products.filter(p => {return p.category.split(" ")[0] === 'Sandals' && p.category.split(" ")[1] === 'Boy'}));
            }
            else {
                setStateProducts(products.filter(p => p.category.split(" ")[1] === 'Boy'));
            }
        }
    }
    
    function handleCheckbox5(){
        if(checkbox5 === 1){
            setCheckbox5(0);
            if(checkbox1 === 1){
                setStateProducts(products.filter(p => p.category.split(" ")[0] === 'Boots'));
            }
            else if(checkbox2 === 1){
                setStateProducts(products.filter(p => p.category.split(" ")[0] === 'Sneakers'));
            }
            else if(checkbox3 === 1){
                setStateProducts(products.filter(p => p.category.split(" ")[0] === 'Sandals'));
            }
            else setStateProducts(products);
        }
        else{
            setCheckbox5(1);
            setCheckbox4(0);
            if(checkbox1 === 1){
                setStateProducts(products.filter(p => {return p.category.split(" ")[0] === 'Boots' && p.category.split(" ")[1] === 'Girl'}));
            }
            else if(checkbox2 === 1){
                setStateProducts(products.filter(p => {return p.category.split(" ")[0] === 'Sneakers' && p.category.split(" ")[1] === 'Girl'}));
            }
            else if(checkbox3 === 1){
                setStateProducts(products.filter(p => {return p.category.split(" ")[0] === 'Sandals' && p.category.split(" ")[1] === 'Girl'}));
            }
            else {
                setStateProducts(products.filter(p => p.category.split(" ")[1] === 'Girl'));
            }        }
    }

    //Filter by search
    function handleFilterSearch(e){
        setStateProducts(products.filter(p => p.title.toUpperCase().startsWith(e.target.value.toUpperCase()) ||
        p.color.toUpperCase().startsWith(e.target.value.toUpperCase()) ||
        p.sku.toUpperCase().startsWith(e.target.value.toUpperCase())
        
        ));
    }

    // -- RETURN
    if(loading){
        return <Spi />;
    }else{
        return(
                <div className="div_produtos">
                    <div className="div_produtos-categories">
                        <p className="div_produtos-categories-p-categoriesTitle">Categories</p>
                        <div className="div_produtos-categories-category">
                            {checkbox1===1 ? <div onClick={handleCheckbox1} style={styleCheck} className="div_produtos_checkbox"></div> : <div onClick={handleCheckbox1} className="div_produtos_checkbox"></div>}
                            <p className="div_produtos-categories-p">Boots</p>
                        </div>
                        <div className="div_produtos-categories-category">
                        {checkbox2===1 ? <div onClick={handleCheckbox2} style={styleCheck} className="div_produtos_checkbox"></div> : <div onClick={handleCheckbox2} className="div_produtos_checkbox"></div>}
                            <p className="div_produtos-categories-p">Sneakers</p>
                        </div>
                        <div className="div_produtos-categories-category">
                        {checkbox3===1 ? <div onClick={handleCheckbox3} style={styleCheck} className="div_produtos_checkbox"></div> : <div onClick={handleCheckbox3} className="div_produtos_checkbox"></div>}
                            <p className="div_produtos-categories-p">Sandals</p>
                        </div>
                        <p className="div_produtos-categories-p-categoriesTitle2">Gender</p>
                        <div className="div_produtos-categories-category">
                        {checkbox4===1 ? <div onClick={handleCheckbox4} style={styleCheck} className="div_produtos_checkbox"></div> : <div onClick={handleCheckbox4} className="div_produtos_checkbox"></div>}
                            <p className="div_produtos-categories-p">Boy</p>
                        </div>
                        <div className="div_produtos-categories-category">
                        {checkbox5===1 ? <div onClick={handleCheckbox5} style={styleCheck} className="div_produtos_checkbox"></div> : <div onClick={handleCheckbox5} className="div_produtos_checkbox"></div>}
                            <p className="div_produtos-categories-p">Girl</p>
                        </div>
                    </div>
                    <Search onChange={handleFilterSearch} />
                    <div className="div_produtos-products">
                        {stateProducts.sort((a, b) => a.sku < b.sku ? 1 : -1).map((p) => {
                            return p.active ? (
                                <Product 
                                key={p.sku}
                                product={p}
                            />
                            ) :
                            <div></div>
                        }
                        )}
                    </div>
                </div>
        );
    }
}

export default UserCatalog;