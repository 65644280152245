import './DeleteDialog.css';

import { VscError, VscCheck } from "react-icons/vsc";

import { changeDialogVisibility } from '../redux/actions/dialogActions';
import { useSelector, useDispatch } from 'react-redux';

function DeleteDialog(props){

    function testDelete(){
        chgVisibility();
    }

    const dialogVisibility = useSelector(state => state.dialogVisibility);
    const { visibility } = dialogVisibility;

    const dispatch = useDispatch();
    const chgVisibility = () => {
        dispatch(changeDialogVisibility(!visibility));
    }

    function goto(){
        chgVisibility();
        props.action();
    }

    return(
        <div style={{
            position: "fixed",
            top: "0",
            left: "0",
            right: "0",
            bottom: "0",
            backgroundColor: "rgba(0,0,0,0.5)"
        }}>
            <div style={{
                justifyContent: "center",
                position: "absolute",
                top: "50%",
                left: "50%",
                transform: "translate(-50%,-50%)",
                width: "400px",
                height: "230px",
                borderRadius: "4px",
            }} className="deleteDialog_div">
                <div className="div_deleteDialog_img">
                    {props.type==="delete" ?
                        <VscError className="deleteDialog_img" />
                        :
                        <VscCheck className="deleteDialog_img2" />
                    }
                </div>
                <h3>{props.title}</h3>
                <p style={{
                    textAlign: "center",
                    marginTop: "5px"
                }}>{props.text}</p>

                {props.type === 'delete' ? 
                <button className="deleteDialog_delete_button" onClick={goto}>DELETE</button> :
                <button className="deleteDialog_save_button" onClick={goto}>SAVE</button>
                }
                <button className="deleteDialog_cancel_button" onClick={testDelete}>CANCEL</button>

            </div>
        </div>
    )

}

export default DeleteDialog;