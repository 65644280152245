import './UserOrdersOrder.css';

import {Link} from 'react-router-dom';

const UserOrdersOrder = props => {

    return(
                <Link to={`/user/orders/products/${props.orderId}`} style={{textDecoration: 'none'}}>
                    <div className="userOrdersOrder-div">
                        <div className="userOrdersOrder-div-id">
                            <p className="adminOrdersOrder-div-div3-title">{props.orderId}</p>
                        </div>
                        <div className="userOrdersOrder-div-date">
                            <p className="adminOrdersOrder-div-div3-data">{props.date}</p>
                        </div>
                        <div className="userOrdersOrder-div-fulfilled">
                            <p className="adminOrdersOrder-div-div3-title-f">{props.quantity}</p>
                        </div>
                        <div className="userOrdersOrder-div-total">
                            <p className="adminOrdersOrder-div-div3-title-f">{props.cost}€</p>
                        </div>
                    </div>
                </Link>
    );
};

export default UserOrdersOrder;