// Style
import './Dashboard.css';

import React from 'react';
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';

import SmallComponent from '../components/SmallComponent';
import BigComponent from '../components/BigComponent';

import { FiCalendar } from "react-icons/fi";

import { useState, useEffect, useContext } from 'react';
import { Authcontext } from '../../../../login/context/auth-context';

import Loading from '../../../shared/loading/Loading';

// Fuctions
import { GETauthrequest } from '../../../../shared/functions/requests';

const DashboardSales = () => {

  const auth = useContext(Authcontext);
  //Fetch dashboard overview data
  const [loadedData, setLoadedData] = useState();
  
  useEffect(() => {
    GETauthrequest(auth, '/dashboard/sales').then((res) => {
      setLoadedData(res);
    })
  }, [auth]);

    const data = [
        {
          name: 'Monday',
          sales: 300,
        },
        {
          name: 'Tuesday',
          sales: 723,
        },
        {
          name: 'Wednesday',
          sales: 382,
        },
        {
          name: 'Thursday',
          sales: 0,
        },
        {
          name: 'Friday',
          sales: 549,
        },
        {
          name: 'Saturday',
          sales: 123,
        },
        {
          name: 'Sunday',
          sales: 900,
        },
      ];

  if(loadedData){
        return(
          <div className="dashboard">
  
            <div className="dashboard_overview_mainDiv_titleDiv">
              <div className="dashboard_overview_titleDiv">
                <h1>Sales</h1>
                <div className="dashboard_optionsDiv"><FiCalendar className="dashboard_optionsDiv_icon" /><p>All Time</p></div>
              </div>
            </div>
  
            <div className="dashmoard_mainDiv">
              <div className="dashboard_overview_left">
                <div className="dashboard_overview_left_smallComponents">
                  <SmallComponent title="Orders" value={loadedData.numberOrders}/>
                  <SmallComponent title="Sales" value={loadedData.valueOrders}/>
                  <SmallComponent title="Average Order Value" value={parseFloat(loadedData.valueOrders / loadedData.numberOrders).toFixed(2)}/>
                </div>
                <div className="dashboard_overview_left_graphic">
                  <div className="dashboard_overview_left_graphic_div">
                    <ResponsiveContainer width="98%" height="98%">
                      <LineChart
                        width={500}
                        height={300}
                        data={data}
                        margin={{
                          top: 25,
                          right: 30,
                          left: 10,
                          bottom: 5,
                        }}
                      >
                        <CartesianGrid strokeDasharray="3 3" />
                        <XAxis dataKey="name" />
                        <YAxis />
                        <Tooltip />
                        <Legend />
                        <Line type="monotone" dataKey="sales" stroke="#82ca9d" activeDot={{ r: 8 }} />
                      </LineChart>
                    </ResponsiveContainer>
                  </div>
                </div>
              </div>
              <div className="dashboard_overview_right">
                <BigComponent 
                  title="Most Sold Products"
                  shoe1={loadedData.products[0].title + ' ' + loadedData.products[0].color}
                  shoe2={loadedData.products[1].title + ' ' + loadedData.products[1].color}
                  shoe3={loadedData.products[2].title + ' ' + loadedData.products[2].color} 
                  shoe4={loadedData.products[3].title + ' ' + loadedData.products[3].color} 
                  shoe5={loadedData.products[4].title + ' ' + loadedData.products[4].color}
                  value1={parseFloat(loadedData.products[0].sales).toFixed(2)}
                  value2={parseFloat(loadedData.products[1].sales).toFixed(2)}
                  value3={parseFloat(loadedData.products[2].sales).toFixed(2)}
                  value4={parseFloat(loadedData.products[3].sales).toFixed(2)}
                  value5={parseFloat(loadedData.products[4].sales).toFixed(2)}
                />
                <BigComponent 
                  title="Best Costumers" 
                  shoe1={loadedData.users[0] ? loadedData.users[0].email : "."}
                  shoe2={loadedData.users[1] ? loadedData.users[1].email : "."}
                  shoe3={loadedData.users[2] ? loadedData.users[2].email : "."} 
                  shoe4={loadedData.users[3] ? loadedData.users[3].email : "."} 
                  shoe5={loadedData.users[4] ? loadedData.users[4].email : "."}
                  value1={loadedData.users[0] ? parseFloat(loadedData.users[0].spent).toFixed(2) : "0"}
                  value2={loadedData.users[1] ? parseFloat(loadedData.users[1].spent).toFixed(2) : "0"}
                  value3={loadedData.users[2] ? parseFloat(loadedData.users[2].spent).toFixed(2) : "0"}
                  value4={loadedData.users[3] ? parseFloat(loadedData.users[3].spent).toFixed(2) : "0"}
                  value5={loadedData.users[4] ? parseFloat(loadedData.users[4].spent).toFixed(2) : "0"}
                />
              </div>
            </div> 
  
          </div>
  
      )
      }else return(
        <Loading/>  
      )
    
}

export default DashboardSales;