import './AdminOrdersOrder.css';

import {Link} from 'react-router-dom';

const AdminOrdersOrder = props => {

    if(props.b2b) {
        return(
                <Link to={`/admin/orders/products/${props.orderId}`} style={{textDecoration: 'none'}}>
                    <div className="adminOrdersOrder-div">
                        <div className="adminOrdersOrder-onequarter1">
                            <p className="adminOrdersOrder-onequarter1-p2">{props.orderId}</p>
                        </div>
                        <div className="adminOrdersOrder-onequarter2">
                            <p className="adminOrdersOrder-onequarter1-p2">{props.email}</p>
                        </div>
                        <div className="adminOrdersOrder-onequarter3">
                            <p className="adminOrdersOrder-onequarter1-p2">{props.date}</p>
                        </div>
                        <div className="adminOrdersOrder-onequarter4">
                            <p className="adminOrdersOrder-onequarter1-p2">{props.quantity}</p>
                        </div>
                        <div className="adminOrdersOrder-onequarter5">
                            <p className="adminOrdersOrder-onequarter1-p2">{parseFloat(props.cost).toFixed(2)}€</p>
                        </div>
                        <div className="adminOrdersOrder-onequarter6">
                            <p className="adminOrdersOrder-onequarter1-p2">{props.payment}</p>
                        </div>
                        <div className="adminOrdersOrder-onequarter7">
                            <p className="adminOrdersOrder-onequarter1-p2">{props.status}</p>
                        </div>
                    </div>
                </Link>
    );}
    else{
        return(
            <div className="adminOrdersOrder-div">
                <div className="adminOrdersOrder-onequarter1">
                    <p className="adminOrdersOrder-onequarter1-p2">{props.orderId}</p>
                </div>
                <div className="adminOrdersOrder-onequarter2">
                    <p className="adminOrdersOrder-onequarter1-p2">{props.email}</p>
                </div>
                <div className="adminOrdersOrder-onequarter3">
                    <p className="adminOrdersOrder-onequarter1-p2">{props.date}</p>
                </div>
                <div className="adminOrdersOrder-onequarter4">
                    <p className="adminOrdersOrder-onequarter1-p2">{props.quantity}</p>
                </div>
                <div className="adminOrdersOrder-onequarter5">
                    <p className="adminOrdersOrder-onequarter1-p2">{parseFloat(props.cost).toFixed(2)}€</p>
                </div>
                <div className="adminOrdersOrder-onequarter6">
                        <p className="adminOrdersOrder-onequarter1-p2">{props.payment}</p>
                    </div>
                <div className="adminOrdersOrder-onequarter7">
                    <p className="adminOrdersOrder-onequarter1-p5">{props.status}</p>
                </div>
            </div>
        );
    }
};

export default AdminOrdersOrder;