// Style
import './AdminCatalog.css';

// Packages
import React, { useState, useEffect } from "react";

// Components
import Product from '../components/Product';
import { Link } from 'react-router-dom';
import Loading from '../../../shared/loading/Loading';

// Functions
import { GETrequest } from '../../../../shared/functions/requests';

import { changeDialogVisibility } from '../../../../redux/actions/dialogActions';
import { useDispatch } from 'react-redux';

const AdminCatalog = () => {

    const [loadedData, setLoadedData] = useState([]);
    const [products, setProducts] = useState([]);

    // Problema dialog mantia inativa navbar quando se volta atras
    const dispatch = useDispatch();

    // Fetch products
    useEffect(() => {
        // Problema dialog mantia inativa navbar quando se volta atras
        dispatch(changeDialogVisibility(true));

        GETrequest('/products/get').then((res) => {
            setProducts(res);
            setLoadedData(res);
        });
    }, [dispatch]);

    // Filter by search
    function handleFilterSearch(e){
        setLoadedData(products.filter(p => 
            p.title.toUpperCase().startsWith(e.target.value.toUpperCase()) ||
            p.color.toUpperCase().startsWith(e.target.value.toUpperCase()) ||
            p.sku.toUpperCase().startsWith(e.target.value.toUpperCase())
        
        ));
    }

    /*users= users.filter(function(item) {
        for (var key in filter) {
          if (item[key] === undefined || item[key] != filter[key])
            return false;
        }
        return true;
      });
    */
   
    // Return
    if(loadedData){
        return(
            <div className="admin-div_produtos-products">
                <input className="admin_catalog_search" placeholder="Search products by name, color or SKU" onChange={handleFilterSearch}/>
                <div className="admin_pages_title">
                    <div className="admin_pages_title_divResponsive2">
                        <h1>Products</h1>
                        <div className="admin_catalog_div_button">
                            <Link to="/admin/catalog/addproduct"><button className="button" style={{float:"right",marginTop:"40px"}} >Add product</button></Link>
                            <Link to="/admin/catalog/importProducts"><button className="button_back" style={{float:"right",marginTop:"40px",marginRight:"20px"}}>Import csv</button></Link>
                            <Link to="/admin/catalog/importImages"><button className="button_back" style={{float:"right",marginTop:"40px",marginRight:"20px"}}>Import Images</button></Link>
                        </div>
                    </div>
                </div>
                <div className="admin_catalog_responsive">
                    {loadedData.sort((a, b) => a.sku < b.sku ? 1 : -1).map((p) => (
                        <Product key={p.sku} product={p}/>
                    ))}
                </div>
            </div>
        );
    }else{
        return(
            <Loading/>   
        )
    }
}

export default AdminCatalog;