//Style
import "./UserAccount.css";

//Packages
import React, { useEffect, useState, useContext } from 'react';
import { Authcontext } from "../../../login/context/auth-context";
import { VscError, VscCheck } from "react-icons/vsc";

//Components
import Input from "../../../shared/formElements/Input";
import UserFooter from "../../shared/footer/UserFooter";

const UserAccount = (props) => {

  const [visibility, setVisibility] = useState(false);

  const auth = useContext(Authcontext);

  const [password, setPassword] = useState('');
  const [newPassword1, setnewPassword1] = useState('');
  const [newPassword2, setnewPassword2] = useState('');
  const [permission, setPermission] = useState(false);

  useEffect(() => {
    if( password !== '' && newPassword1 !== '' && newPassword1 === newPassword2){
      setPermission(true);
    }
    else if(newPassword1 !== newPassword2){
      setPermission(false);
    }
    else if( newPassword1 === '' || password === '' || newPassword2 === ''){
      setPermission(false);
    }
  }, [newPassword2, newPassword1, password]);

  const PostToDatabase = async e => {
    setVisibility(false);
    e.preventDefault();
    const url = process.env.REACT_APP_BACKEND_URL + "/users/changePassword";
    console.log("password: " + password);
        try {
            const response = await fetch(url, {
              method: 'PATCH',
              headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${auth.token}`
              },
              body: JSON.stringify({
                email: auth.email,
                password: password,
                newPassword: newPassword2
              })
            });
            console.log(response);
            setPermission(false);
          }  catch (err) {
            console.log(err);
          }
  }

  // Save dialog
  function testSave(){
    setVisibility(true);
  }

  function dialogCancel(){
    setVisibility(false);
  }

  // -- RETURN
    return (
      <React.Fragment>
      <div style={{width:"100%", height:"500px" }}>
        <div className="admin_account_div" style={{marginTop:"120px"}}>
            <p className="adminAccount-p" >Current Password</p>
            <Input changeHandler={(e) => {setPassword(e.target.value)}} width="344px" marginBottom="20px" placeholder="Current Password" />
            <p className="UserAccount-p" >New Password</p>
            <Input changeHandler={(e) => {setnewPassword1(e.target.value)}} width="344px" marginBottom="20px" placeholder="New Password" />
            <p className="UserAccount-p" >Repeat New Password</p>
            <Input changeHandler={(e) => {setnewPassword2(e.target.value)}} width="344px" marginBottom="25px" placeholder="Repeat New Password" />
            {permission ?
              <div className="admin_account_button">
                <button className="button" onClick={testSave}>Change password</button>
              </div> 
            :
            <div className="admin_account_button">
              <button className="button_disabled" disabled="true" >Change password</button>
            </div>
            }
        </div>

      </div>
      <UserFooter />
      {visibility ?
              <div style={{
                position: "fixed",
                top: "0",
                left: "0",
                right: "0",
                bottom: "0",
                backgroundColor: "rgba(0,0,0,0.5)"
              }}>
                <div style={{
                    justifyContent: "center",
                    position: "absolute",
                    top: "50%",
                    left: "50%",
                    transform: "translate(-50%,-50%)",
                    width: "400px",
                    height: "230px",
                    borderRadius: "4px",
                }} className="deleteDialog_div">
                    <div className="div_deleteDialog_img">
                        {props.type==="delete" ?
                            <VscError className="deleteDialog_img" />
                            :
                            <VscCheck className="deleteDialog_img2" />
                        }
                    </div>
                    <h3>Are you sure you want to update password?</h3>
                    <p style={{
                        textAlign: "center",
                        marginTop: "5px"
                    }}>{props.text}</p>

                    <button className="deleteDialog_save_button" onClick={PostToDatabase}>CHANGE</button>
                    
                    <button className="deleteDialog_cancel_button" onClick={dialogCancel}>CANCEL</button>
    
                </div>
              </div>
              :
              <span></span>
        }
      </React.Fragment>
    );
};

export default UserAccount;