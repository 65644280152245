//Style
import './Product.css';

//Packages
import { Link } from 'react-router-dom';
import { useState } from 'react';
import { FiEdit, FiTrash2 } from "react-icons/fi";

// -- FUNCTION
const Product = props => {

    const [show, setShow] = useState(false);

    const prices2 = [];
    if(props.product.price20 !== 0 && Number(props.product.price20)) prices2.push(props.product.price20);
    if(props.product.price21 !== 0 && Number(props.product.price21)) prices2.push(props.product.price21);
    if(props.product.price22 !== 0 && Number(props.product.price22)) prices2.push(props.product.price22);
    if(props.product.price23 !== 0 && Number(props.product.price23)) prices2.push(props.product.price23);
    if(props.product.price24 !== 0 && Number(props.product.price24)) prices2.push(props.product.price24);
    if(props.product.price25 !== 0 && Number(props.product.price25)) prices2.push(props.product.price25);
    if(props.product.price26 !== 0 && Number(props.product.price26)) prices2.push(props.product.price26);
    if(props.product.price27 !== 0 && Number(props.product.price27)) prices2.push(props.product.price27);
    if(props.product.price28 !== 0 && Number(props.product.price28)) prices2.push(props.product.price28);
    if(props.product.price29 !== 0 && Number(props.product.price29)) prices2.push(props.product.price29);
    if(props.product.price30 !== 0 && Number(props.product.price30)) prices2.push(props.product.price30);
    if(props.product.price31 !== 0 && Number(props.product.price31)) prices2.push(props.product.price31);
    if(props.product.price32 !== 0 && Number(props.product.price32)) prices2.push(props.product.price32);
    if(props.product.price33 !== 0 && Number(props.product.price33)) prices2.push(props.product.price33);
    if(props.product.price34 !== 0 && Number(props.product.price34)) prices2.push(props.product.price34);
    if(props.product.price35 !== 0 && Number(props.product.price35)) prices2.push(props.product.price35);
    if(props.product.price36 !== 0 && Number(props.product.price36)) prices2.push(props.product.price36);
    if(props.product.price37 !== 0 && Number(props.product.price37)) prices2.push(props.product.price37);
    if(props.product.price38 !== 0 && Number(props.product.price38)) prices2.push(props.product.price38);
    if(props.product.price39 !== 0 && Number(props.product.price39)) prices2.push(props.product.price39);
    if(props.product.price40 !== 0 && Number(props.product.price40)) prices2.push(props.product.price40);
    if(props.product.price41 !== 0 && Number(props.product.price41)) prices2.push(props.product.price41);
    if(props.product.price42 !== 0 && Number(props.product.price42)) prices2.push(props.product.price42);
    if(props.product.price43 !== 0 && Number(props.product.price43)) prices2.push(props.product.price43);
    if(props.product.price44 !== 0 && Number(props.product.price44)) prices2.push(props.product.price44);
    if(props.product.price45 !== 0 && Number(props.product.price45)) prices2.push(props.product.price45);
    if(props.product.price46 !== 0 && Number(props.product.price46)) prices2.push(props.product.price46);
    const maxPrice = parseFloat(Math.max.apply(null, prices2)).toFixed(2);
    const minPrice = parseFloat(Math.min.apply(null, prices2)).toFixed(2);

    var categoryName = props.product.category.split(" ")[0];

    // -- RETURN STATEMENT
    return(
        <Link to={`/admin/catalog/editproduct/${props.product.sku}`}>
            <div className="admin-div-product" onMouseEnter={() => setShow(true)} onMouseLeave={() => setShow(false)} >
                <div className="admin-imagem_produto">
                    <img src={props.product.image1} alt="logo" onLoad={console.log("Image loaded")}/>
                </div>
                {show ? <div className="admin-icons-edit_trash"> <FiEdit className="admin-icons-edit_trash-edit" /> <FiTrash2 className="admin-icons-edit_trash-trash" /> </div> :
                (<div>                
                    <div className="sku_and_categorie">
                        <p className="div_produto-categorie">{categoryName}</p>
                        <p className="div_produto-sku">{"SKU " + props.product.sku }</p>
                    </div>
                <p className="admin-div_produto-title">{props.product.title + " " + props.product.color}</p>
                <p className="admin-div_produto-price">{minPrice}€ - {maxPrice}€</p>
                {props.product.shopifySynchronized ? 
                    <div className="Shopify_synchronized_div"><div className="admin-div_produto-shopify-circle"></div><p className="admin-div_produto-shopify">Synced with Shopify</p></div>
                    :
                    <div className="Shopify_synchronized_div"><div className="not_admin-div_produto-shopify-circle"></div><p className="not_admin-div_produto-shopify">Not synced with Shopify</p></div>
                }
                </div>)
                }
            </div>
        </Link>
    )
}

export default Product;