//Style
import './UserOrders.css';

//Packages
import React, { useState, useEffect, useContext } from "react";

//Custom hooks
import { Authcontext } from '../../../../login/context/auth-context';

//Components
import UserOrdersOrder from '../components/UserOrdersOrder';
import Spi from '../../../../shared/animations/Spi';
import MainDiv from '../../../../shared/containers/MainDiv';
import Div from '../../../../shared/containers/Div';
import Title from '../../../../shared/formElements/Title';

// -- FUNCTION
const UserOrders = () => {

    const auth = useContext(Authcontext);
    
    //Fecth orders
    const [loadedData, setLoadedData] = useState();
    useEffect(() => {
        const url = process.env.REACT_APP_BACKEND_URL + '/orders/perUser/' + auth.email;
        const sendRequest = async () => {
            try{
                const response = await fetch(url, {
                    method: 'GET',
                    headers: {
                        'Authorization': `Bearer ${auth.token}`
                    }
                });
                const responseData = await response.json();
                setLoadedData(responseData.orders.reverse());
            } catch(err){
                console.log(err);
            }
        }
        sendRequest();
    }, [auth.token, auth.email]);

    // -- RETURN
    if(loadedData){
        return(
            <MainDiv>
                <Title text="Order History" />
                <Div width="100%" height="60px">
                        <div className="userOrdersOrder-div-id">
                            <p className="adminOrdersOrder-div-div3-title2">Order ID</p>
                        </div>
                        <div className="userOrdersOrder-div-date">
                            <p className="adminOrdersOrder-div-div3-data2">Date</p>
                        </div>
                        <div className="userOrdersOrder-div-fulfilled">
                            <p className="adminOrdersOrder-div-div3-title-f2">Quantity</p>
                        </div>
                        <div className="userOrdersOrder-div-total">
                            <p className="adminOrdersOrder-div-div3-title-f2">Total</p>
                        </div>
                </Div>
                    {loadedData.map((o) => (
                        <UserOrdersOrder orderId={o.orderId} date={o.date} cost={o.cost} quantity={o.quantity}/>
                    ))}
            </MainDiv>

        );        
    }else{
        return <Spi />
    }
}

export default UserOrders;