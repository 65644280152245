import './UserOrderProductsSpecific.css';

import ShopcartOrderCom from '../../../../user/catalog/shopcart/components/ShopcartOrderCom';

const UserOrderProductsSpecific = props => {
    return(
        <div className="qtyfullsize">
            <div className="quantitySize-image">
                <p className="quantitySize-image-sku">{props.product.title} {props.product.color}</p>
                <div className="quantitySize-image-divimg">
                    <img src={props.product.image} alt="" />
                </div>
                <p className="quantitySize-image-sku">{props.product.sku}</p>
            </div>
            <div className="USER_ORDER_PRODUCT_quantitysList">
                <div className="USER_ORDER_quantitySize">
                    {props.product.ava24 ? <ShopcartOrderCom price={props.product.pri24} qty={props.product.qty24} pvp={props.product.pvp24} size="24"/> : <div></div> }
                    {props.product.ava25 ? <ShopcartOrderCom price={props.product.pri25} qty={props.product.qty25} pvp={props.product.pvp25} size="25"/> : <div></div> }
                    {props.product.ava26 ? <ShopcartOrderCom price={props.product.pri26} qty={props.product.qty26} pvp={props.product.pvp26} size="26"/> : <div></div> }
                    {props.product.ava27 ? <ShopcartOrderCom price={props.product.pri27} qty={props.product.qty27} pvp={props.product.pvp27} size="27"/> : <div></div> }
                    {props.product.ava28 ? <ShopcartOrderCom price={props.product.pri28} qty={props.product.qty28} pvp={props.product.pvp28} size="28"/> : <div></div> }
                    {props.product.ava29 ? <ShopcartOrderCom price={props.product.pri29} qty={props.product.qty29} pvp={props.product.pvp29} size="29"/> : <div></div> }
                    {props.product.ava30 ? <ShopcartOrderCom price={props.product.pri30} qty={props.product.qty30} pvp={props.product.pvp30} size="30"/> : <div></div> }
                    {props.product.ava31 ? <ShopcartOrderCom price={props.product.pri31} qty={props.product.qty31} pvp={props.product.pvp31} size="31"/> : <div></div> }
                    {props.product.ava32 ? <ShopcartOrderCom price={props.product.pri32} qty={props.product.qty32} pvp={props.product.pvp32} size="32"/> : <div></div> }
                    {props.product.ava33 ? <ShopcartOrderCom price={props.product.pri33} qty={props.product.qty33} pvp={props.product.pvp33} size="33"/> : <div></div> }
                    {props.product.ava34 ? <ShopcartOrderCom price={props.product.pri34} qty={props.product.qty34} pvp={props.product.pvp34} size="34"/> : <div></div> }
                    {props.product.ava35 ? <ShopcartOrderCom price={props.product.pri35} qty={props.product.qty35} pvp={props.product.pvp35} size="35"/> : <div></div> }
                    {props.product.ava36 ? <ShopcartOrderCom price={props.product.pri36} qty={props.product.qty36} pvp={props.product.pvp36} size="36"/> : <div></div> }
                    {props.product.ava37 ? <ShopcartOrderCom price={props.product.pri37} qty={props.product.qty37} pvp={props.product.pvp37} size="37"/> : <div></div> }
                    {props.product.ava38 ? <ShopcartOrderCom price={props.product.pri38} qty={props.product.qty38} pvp={props.product.pvp38} size="38"/> : <div></div> }
                    {props.product.ava39 ? <ShopcartOrderCom price={props.product.pri39} qty={props.product.qty39} pvp={props.product.pvp39} size="39"/> : <div></div> }
                    {props.product.ava40 ? <ShopcartOrderCom price={props.product.pri40} qty={props.product.qty40} pvp={props.product.pvp40} size="40"/> : <div></div> }
                    {props.product.ava41 ? <ShopcartOrderCom price={props.product.pri41} qty={props.product.qty41} pvp={props.product.pvp41} size="41"/> : <div></div> }
                    {props.product.ava42 ? <ShopcartOrderCom price={props.product.pri42} qty={props.product.qty42} pvp={props.product.pvp42} size="42"/> : <div></div> }
                    {props.product.ava43 ? <ShopcartOrderCom price={props.product.pri43} qty={props.product.qty43} pvp={props.product.pvp43} size="43"/> : <div></div> }
                </div>
                <div className="USER_ORDER_quantitySize_moreInfo">
                    <p className="shopcartOrder-qty">Total: {parseFloat(props.product.total).toFixed(2)} €</p>
                    <p className="shopcartOrder-qty">Quantity: {props.product.quantity}</p>
                </div>
            </div>
        </div>
    )
}

export default UserOrderProductsSpecific;