//Style
import './Spi.css';

//Packages
import { ScaleLoader } from 'react-spinners'

// -- FUNCTION
const SpiLogin = () => {

    // -- RETURN
    return(
        <div className="divSpinnerLogin">
                 <ScaleLoader
                    color="#052F56"
                    height={18}
                />
        </div>
    )
}

export default SpiLogin;