//Style
import './AdminEditProduct.css';

//Packages
import React, {useState, useEffect, useContext} from "react";
import { useParams } from "react-router";
import { Authcontext } from '../../../login/context/auth-context';

//Components
import Div from '../../../shared/containers/Div';

import DeleteDialog from '../../../shared/DeleteDialog';
import { changeDialogVisibility } from '../../../redux/actions/dialogActions';
import { useSelector, useDispatch } from 'react-redux';
import { VscCheck } from "react-icons/vsc";
import { Link } from 'react-router-dom';
import Loading from '../../shared/loading/Loading';

import { FiEdit } from "react-icons/fi";
import { storage } from "../../../firebase";

// -- FUNCTION
const AdminEditProduct = () => {

    const auth = useContext(Authcontext);

    const productId = useParams().pid;
    const [formValues, setFormValues] = useState();
    const [active, setActive] = useState();

    //Fetch product
    useEffect(() => {
        const sendRequest = async () => {
            try{
                const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/products/getById/${productId}`, {
                    method: 'GET'
                });
                const responseData = await response.json();

                setFormValues(responseData[0]);
                setLoading(false);
                setAvailable20(responseData[0].ava20);
                setAvailable21(responseData[0].ava21);
                setAvailable22(responseData[0].ava22);
                setAvailable23(responseData[0].ava23);
                setAvailable24(responseData[0].ava24);
                setAvailable25(responseData[0].ava25);
                setAvailable26(responseData[0].ava26);
                setAvailable27(responseData[0].ava27);
                setAvailable28(responseData[0].ava28);
                setAvailable29(responseData[0].ava29);
                setAvailable30(responseData[0].ava30);
                setAvailable31(responseData[0].ava31);
                setAvailable32(responseData[0].ava32);
                setAvailable33(responseData[0].ava33);
                setAvailable34(responseData[0].ava34);
                setAvailable35(responseData[0].ava35);
                setAvailable36(responseData[0].ava36);
                setAvailable37(responseData[0].ava37);
                setAvailable38(responseData[0].ava38);
                setAvailable39(responseData[0].ava39);
                setAvailable40(responseData[0].ava40);
                setAvailable41(responseData[0].ava41);
                setAvailable42(responseData[0].ava42);
                setAvailable43(responseData[0].ava43);
                setAvailable44(responseData[0].ava44);
                setAvailable45(responseData[0].ava45);
                setAvailable46(responseData[0].ava46);

                setActive(responseData[0].active);
                setTags(responseData[0].tags);

            } catch (err) {
                console.log(err);
            }
        };
        sendRequest();
    }, [productId]);

    //Handle change form
    const changeHandler = e => {
        setFormValues({...formValues, [e.target.name]: e.target.value})
    };

    const changeHandlerNumber = e => {
        setFormValues({...formValues, [e.target.name]: Number(e.target.value)})
    };


    // Send images to firebase
    const postToDatabase = async e => {
        console.log("entrou")
        setLoading(true);
        const promise1 = new Promise((resolve, reject) => {
            if(image1) sendImage(image1,1,resolve);
            else resolve(0);
        });
        const promise2 = new Promise((resolve, reject) => {
            if(image2) sendImage(image2,2,resolve);
            else resolve(0);
        });
        const promise3 = new Promise((resolve, reject) => {
            if(image3) sendImage(image3,3,resolve);
            else resolve(0);
        });
        const promise4 = new Promise((resolve, reject) => {
            if(image4) sendImage(image4,4,resolve);
            else resolve(0);
        });

        Promise.all([promise1, promise2, promise3, promise4]).then(async (values) => {
            console.log(values);

            console.log("Sending request");
            const url = process.env.REACT_APP_BACKEND_URL + "/products/editProduct/" + productId;
            
            let bodyHTTP = formValues;
            if(values[0] !== 0) bodyHTTP["image1"] = values[0];
            if(values[1] !== 0) bodyHTTP["image2"] = values[1];
            if(values[2] !== 0) bodyHTTP["image3"] = values[2];
            if(values[3] !== 0) bodyHTTP["image4"] = values[3];

            delete bodyHTTP._id;

            bodyHTTP["tags"] = tags;
            bodyHTTP["active"] = active;
            bodyHTTP["ava22"] = available22;
            bodyHTTP["ava23"] = available23;
            bodyHTTP["ava24"] = available24;
            bodyHTTP["ava25"] = available25;
            bodyHTTP["ava26"] = available26;
            bodyHTTP["ava27"] = available27;
            bodyHTTP["ava28"] = available28;
            bodyHTTP["ava29"] = available29;
            bodyHTTP["ava30"] = available30;
            bodyHTTP["ava31"] = available31;
            bodyHTTP["ava32"] = available32;
            bodyHTTP["ava33"] = available33;
            bodyHTTP["ava34"] = available34;
            bodyHTTP["ava35"] = available35;
            bodyHTTP["ava36"] = available36;
            bodyHTTP["ava37"] = available37;
            bodyHTTP["ava38"] = available38;
            bodyHTTP["ava39"] = available39;
            bodyHTTP["ava40"] = available40;
            bodyHTTP["ava41"] = available41;
            bodyHTTP["ava42"] = available42;
            bodyHTTP["ava43"] = available43;
            bodyHTTP["ava44"] = available44;
            bodyHTTP["ava45"] = available45;
            bodyHTTP["ava46"] = available46;
            
            
            try {
                const response = await fetch(url, {
                  method: 'PATCH',
                  headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${auth.token}`
                  },
                  body: JSON.stringify(bodyHTTP)
                });
                const resData = await response.json();
                setResponseData(resData);
                setLoading(false);
                setConfirmation(true);
              }  catch (err) {
                console.log(err);
              }
              
             console.log(bodyHTTP);

          });
    }


    //Post to database
    const postToDatabase2 = async e => {
        setLoading(true);
        const url = process.env.REACT_APP_BACKEND_URL + "/products/syncStocks";

        try {
            const response = await fetch(url, {
              method: 'PATCH',
              headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${auth.token}`
              },
              body: JSON.stringify(formValues)
            });
            const resData = await response.json();
            setResponseData(resData);
            setLoading(false);
            setConfirmation(true);
          }  catch (err) {
            console.log(err);
          }
    }

    //Available Sizes
    const [available20, setAvailable20] = useState(false);
    const [available21, setAvailable21] = useState(false);
    const [available22, setAvailable22] = useState(false);
    const [available23, setAvailable23] = useState(false);
    const [available24, setAvailable24] = useState(false);
    const [available25, setAvailable25] = useState(false);
    const [available26, setAvailable26] = useState(false);
    const [available27, setAvailable27] = useState(false);
    const [available28, setAvailable28] = useState(false);
    const [available29, setAvailable29] = useState(false);
    const [available30, setAvailable30] = useState(false);
    const [available31, setAvailable31] = useState(false);
    const [available32, setAvailable32] = useState(false);
    const [available33, setAvailable33] = useState(false);
    const [available34, setAvailable34] = useState(false);
    const [available35, setAvailable35] = useState(false);
    const [available36, setAvailable36] = useState(false);
    const [available37, setAvailable37] = useState(false);
    const [available38, setAvailable38] = useState(false);
    const [available39, setAvailable39] = useState(false);
    const [available40, setAvailable40] = useState(false);
    const [available41, setAvailable41] = useState(false);
    const [available42, setAvailable42] = useState(false);
    const [available43, setAvailable43] = useState(false);
    const [available44, setAvailable44] = useState(false);
    const [available45, setAvailable45] = useState(false);
    const [available46, setAvailable46] = useState(false);

    //Functions Available Sizes
    const changeAvailableSize20 = () => {
        if(available20) setAvailable20(false);
        else setAvailable20(true);
    }
    const changeAvailableSize21 = () => {
        if(available21) setAvailable21(false);
        else setAvailable21(true);
    }
    const changeAvailableSize22 = () => {
        if(available22) setAvailable22(false);
        else setAvailable22(true);
    }
    const changeAvailableSize23 = () => {
        if(available23) setAvailable23(false);
        else setAvailable23(true);
    }
    const changeAvailableSize24 = () => {
        if(available24) setAvailable24(false);
        else setAvailable24(true);
    }
    const changeAvailableSize25 = () => {
        if(available25) setAvailable25(false);
        else setAvailable25(true);
    }
    const changeAvailableSize26 = () => {
        if(available26) setAvailable26(false);
        else setAvailable26(true);
    }
    const changeAvailableSize27 = () => {
        if(available27) setAvailable27(false);
        else setAvailable27(true);
    }
    const changeAvailableSize28 = () => {
        if(available28) setAvailable28(false);
        else setAvailable28(true);
    }
    const changeAvailableSize29 = () => {
        if(available29) setAvailable29(false);
        else setAvailable29(true);
    }
    const changeAvailableSize30 = () => {
        if(available30) setAvailable30(false);
        else setAvailable30(true);
    }
    const changeAvailableSize31 = () => {
        if(available31) setAvailable31(false);
        else setAvailable31(true);
    }
    const changeAvailableSize32 = () => {
        if(available32) setAvailable32(false);
        else setAvailable32(true);
    }
    const changeAvailableSize33 = () => {
        if(available33) setAvailable33(false);
        else setAvailable33(true);
    }
    const changeAvailableSize34 = () => {
        if(available34) setAvailable34(false);
        else setAvailable34(true);
    }
    const changeAvailableSize35 = () => {
        if(available35) setAvailable35(false);
        else setAvailable35(true);
    }
    const changeAvailableSize36 = () => {
        if(available36) setAvailable36(false);
        else setAvailable36(true);
    }
    const changeAvailableSize37 = () => {
        if(available37) setAvailable37(false);
        else setAvailable37(true);
    }
    const changeAvailableSize38 = () => {
        if(available38) setAvailable38(false);
        else setAvailable38(true);
    }
    const changeAvailableSize39 = () => {
        if(available39) setAvailable39(false);
        else setAvailable39(true);
    }
    const changeAvailableSize40 = () => {
        if(available40) setAvailable40(false);
        else setAvailable40(true);
    }
    const changeAvailableSize41 = () => {
        if(available41) setAvailable41(false);
        else setAvailable41(true);
    }
    const changeAvailableSize42 = () => {
        if(available42) setAvailable42(false);
        else setAvailable42(true);
    }
    const changeAvailableSize43 = () => {
        if(available43) setAvailable43(false);
        else setAvailable43(true);
    }
    const changeAvailableSize44 = () => {
        if(available44) setAvailable44(false);
        else setAvailable44(true);
    }
    const changeAvailableSize45 = () => {
        if(available45) setAvailable45(false);
        else setAvailable45(true);
    }
    const changeAvailableSize46 = () => {
        if(available46) setAvailable46(false);
        else setAvailable46(true);
    }

    const deleteProduct = async e => {
        setLoading(true);
        try {
          const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/products/delete/${productId}`, {
            method: 'DELETE',
            headers: {
              'Content-Type': 'application/json',
              'Authorization': `Bearer ${auth.token}`
            }
          });
          const resData = await response.json();
          setResponseData(resData);
          setLoading(false);
          setDeletingFunc(true);
          setConfirmation(true);
          console.log(resData);
        }catch(err){
          console.log("erro");
        }

    }

      const changeActive = e => {
          console.log("esta a dar: " + e);
          if(e === "true") setActive(true);
          else setActive(false);
          /*if(active) setActive(false);
          else setActive(true);*/
      }

    // Delete dialog 

    const [deleteDialog,setdeleteDialog] = useState();

    function testDelete(){
        chgVisibility();
        setdeleteDialog(true);
    }

    const dialogVisibility = useSelector(state => state.dialogVisibility);
    const { visibility } = dialogVisibility;

    const dispatch = useDispatch();
    const chgVisibility = () => {
        dispatch(changeDialogVisibility(!visibility));
    }

    // Save dialog
    function testSave(){
        chgVisibility();
        setdeleteDialog(false);
    }


    // TAGS
    const [tags,setTags] = useState([]);
    function addTag(e){
        if(e.key === 'Enter'){
            console.log(formValues.tag);
            setTags(tags => [...tags, formValues.tag]);
            setFormValues({...formValues, tag: ""});
        }
    }
    function removeTag(tag){
        let filteredArray = tags.filter(item => item !== tag)
        setTags(filteredArray);
    }

    // Confirmation
    const [confirmation,setConfirmation] = useState(false);
    const [responseData,setResponseData] = useState(false);
    const [deletingFunc, setDeletingFunc] = useState(false);

    // Loading
    const [loading,setLoading] = useState(true);



    // Update images

    const [previewUrl, setPreviewUrl] = useState();
    const [previewUrl2, setPreviewUrl2] = useState();
    const [previewUrl3, setPreviewUrl3] = useState();
    const [previewUrl4, setPreviewUrl4] = useState();

    //Image State
    const [image1, setImage1] = useState(null);
    const [image2, setImage2] = useState(null);
    const [image3, setImage3] = useState(null);
    const [image4, setImage4] = useState(null);
    
    const handleImageChange = e => {
        if(e.target.files[0]) setImage1(e.target.files[0]);
        else setImage1(null);
    }
    
    const handleImageChange2 = e => {
        if(e.target.files[0]) setImage2(e.target.files[0]);
        else setImage2(null);
    }
    const handleImageChange3 = e => {
        if(e.target.files[0]) setImage3(e.target.files[0]);
        else setImage3(null);
    }
    const handleImageChange4 = e => {
        if(e.target.files[0]) setImage4(e.target.files[0]);
        else setImage4(null);
    }

    useEffect(() => {
        if (!image1) return;
        const fileReader = new FileReader();
        fileReader.onload = () => { setPreviewUrl(fileReader.result); };
        fileReader.readAsDataURL(image1);
    }, [image1]);

    useEffect(() => {
        if (!image2) return;
        const fileReader = new FileReader();
        fileReader.onload = () => { setPreviewUrl2(fileReader.result); };
        fileReader.readAsDataURL(image2);
    }, [image2]);

    useEffect(() => {
        if (!image3) return;
        const fileReader = new FileReader();
        fileReader.onload = () => { setPreviewUrl3(fileReader.result); };
        fileReader.readAsDataURL(image3);
    }, [image3]);

    useEffect(() => {
        if (!image4) return;
        const fileReader = new FileReader();
        fileReader.onload = () => { setPreviewUrl4(fileReader.result); };
        fileReader.readAsDataURL(image4);
    }, [image4]);

    async function sendImage(image1, imageNumber,resolve){
        var uploadTask = storage.ref(`images/${image1.name}`).put(image1);  
        uploadTask.on(
            "state_changed",
            snapshot => {},
            error => { console.log(error); },
            () => {
                storage.ref("images").child(image1.name).getDownloadURL().then(url => {
                    console.log(url);
                    let publicURL = "https://storage.googleapis.com/react-hosting-73546.appspot.com/images/" + image1.name;
                    resolve(publicURL);
                });
            }
        )
    }

      // Return
    if(confirmation){
        return(
      <div className="editUser" style={{overflow:"auto"}}>
        <div className="confirmationDialog" style={{width:"500px",minHeight:"220px", backgroundColor:"white", margin:"auto", marginTop:"200px", overflow:"auto", paddingBottom:"20px"}}>
            <div className="div_deleteDialog_img">
              <VscCheck className="deleteDialog_img2" />
            </div>
          {responseData.message.map((mes) => {
              return <p style={{fontSize:"12px"}}>{mes}</p>
          })}

          {!deletingFunc ?
            <Link to="/admin/catalog"><button className="button_back" style={{marginLeft:"0px", marginTop:"20px",marginBottom:"0px"}}>Back to catalog</button></Link>
          :
            <Link to="/admin/catalog"><button className="button_back" style={{marginLeft:"9px", marginTop:"20px"}}>Back to catalog</button></Link>
          }
          {!deletingFunc ?
            <button className="button" style={{marginLeft:"50px", marginTop:"20px"}} onClick={e => setConfirmation(false)}>Check product</button>
          :
            <span></span>
          }
        </div>
      </div>
    )
  }

    // Return
    else if(formValues && !loading){
        return(
            <div className="editProduct">
                <div className="admin_pages_title">
                    <div className="admin_pages_title_divResponsive2">
                        <h1>Edit Product</h1>
                        <div className="admin_catalog_div_button">
                            <button className="button_save" style={{float:"right",marginTop:"40px"}} type="button" onClick={testSave}>Save</button>
                            <button className="button_delete" style={{float:"right",marginTop:"40px",marginRight:"15px"}} type="button" onClick={testDelete}>Delete</button>
                        </div>
                    </div>
                </div>
                <div className="div_main_principal_tipo">
                    <form>

                    <div style={{width:"797px",height:"785px", marginLeft:"20px", marginBottom:"20px",float:"left"}}>
                        <div className="editProduct_name" style={{float:"left"}}>
                            <p>Title</p>
                            <input defaultValue={formValues.title} name="title" onChange={changeHandler} />
                        </div>
                        <div className="editProduct_name" style={{float:"left",marginLeft:"20px"}}>
                            <p>Color</p>
                            <input defaultValue={formValues.color} name="color" onChange={changeHandler} />
                        </div>
                        <div className="editProduct_name" style={{float:"left",marginLeft:"20px"}}>
                            <p>Sku</p>
                            <p style={{marginTop:"17px",fontWeight:"700"}}>{formValues.sku}</p>
                        </div>

                        <div style={{width:"100%",height:"682px", backgroundColor:"white",float:"left", boxShadow:"0px 0px 10px 2px rgb(222, 222, 222)", borderRadius:"4px"}}>
                            <p style={{marginLeft:"20px", fontSize:"17px",fontFamily: 'Source Code Pro'}}>Product Info</p>
                            <div className="editProduct_div2">
                                <div>
                                    <p>Description</p>
                                    <textarea  placeholder="Description" defaultValue={formValues.description} name="description" onChange={changeHandler} />
                                </div>
                                <div style={{marginLeft:"20px"}}>
                                    <p>Materials</p>
                                    <textarea  placeholder="Materials" defaultValue={formValues.materials} name="materials" onChange={changeHandler} />
                                </div>
                            </div>


                            <div className="editProduct_div3" style={{height:"400px"}}>
                                <p>Media</p>
                                <div className="editProduct_div3_main">
                                    {image1 ? <img src={previewUrl} alt="logo" /> : <img src={formValues.image1} alt="logo" />}
                                    <input id="image1" type="file" onChange={handleImageChange}></input>
                                    <label for="image1"><FiEdit className="addproduct_icon"/></label>
                                </div>
                                <div className="addProduct_div3_submain">
                                    <div>
                                        {image2 ? <img src={previewUrl2} alt="logo" className="addProduct_div3_submain_img1" /> : <img src={formValues.image2} alt="logo" className="addProduct_div3_submain_img1" />}
                                        <input id="image2" type="file" onChange={handleImageChange2}></input>
                                    <label for="image2"><FiEdit className="addproduct_icon2"/></label>
                                    </div>
                                    <div>
                                        {image3 ? <img src={previewUrl3} alt="logo" className="addProduct_div3_submain_img1" /> : <img src={formValues.image3} alt="logo" className="addProduct_div3_submain_img1" />}
                                        <input id="image3" type="file" onChange={handleImageChange3}></input>
                                        <label for="image3"><FiEdit className="addproduct_icon2"/></label>
                                    </div>
                                    <div>
                                        {image4 ? <img src={previewUrl4} alt="logo" className="addProduct_div3_submain_img1" /> : <img src={formValues.image4} alt="logo" className="addProduct_div3_submain_img1" />}
                                        <input id="image4" type="file" onChange={handleImageChange4}></input>
                                        <label for="image4"><FiEdit className="addproduct_icon2"/></label>
                                    </div>
                                </div>
                            </div>



                            
                        </div>

                    </div>

                    <div style={{width:"320px", marginLeft:"20px",float:"left"}}>
                        <div className="editProduct_name" style={{width:"100%",height:"130px"}}>
                            <p style={{marginBottom:"20px"}}>Status per Channel</p>

                            {active ? 
                                <div>
                                    <div style={{width:"9px",height:"9px",borderRadius:"10px",backgroundColor:"green", display:"inline-block", marginLeft:"20px"}}></div>
                                    <p style={{display:"inline-block", marginLeft:"5px", color:"grey"}}>B2B Store</p>
                                    <select style={{width:"80px",height:"30px",marginLeft:"100px",color:"grey"}}
                                    onChange={e => changeActive(e.target.value)}
                                    value={active}>
                                        <option value="true" onChange={changeActive}>Active</option>
                                        <option value="false" onChange={changeActive}>Draft</option>
                                    </select>
                                </div>
                            :
                                <div>
                                    <div style={{width:"9px",height:"9px",borderRadius:"10px",backgroundColor:"grey", display:"inline-block", marginLeft:"20px"}}></div>
                                    <p style={{display:"inline-block", marginLeft:"5px", color:"grey"}}>B2B Store</p>
                                    <select style={{width:"80px",height:"30px",marginLeft:"100px",color:"grey"}}
                                    onChange={e => changeActive(e.target.value)}
                                    value={active}>
                                        <option value="false" onChange={changeActive}>Draft</option>                                        
                                        <option value="true" onChange={changeActive}>Active</option>
                                    </select>
                                </div>
                            }

                            {/*
                            <div>
                            <div style={{width:"9px",height:"9px",borderRadius:"10px",backgroundColor:"green", display:"inline-block", marginLeft:"20px"}}></div>
                            <p style={{display:"inline-block", marginLeft:"5px", color:"grey"}}>Shopify Store</p>
                            <select style={{width:"80px",height:"30px",marginLeft:"67px",color:"grey"}}>
                                <option value="fruit">Active</option>
                                <option value="vegetable">Draft</option>
                            </select>
                            </div>
                            */}
  
                        </div>
                        <div className="editProduct_name" style={{width:"100%"}}>
                            <p>Vendor</p>
                            <input placeholder="Vendor" defaultValue={formValues.vendor}  name="vendor" onChange={changeHandler}/>
                        </div>
                        <div className="editProduct_name" style={{width:"100%"}}>
                            <p>Category</p>
                            <input defaultValue={formValues.category} name="category" onChange={changeHandler} />
                        </div>
                        <div className="editProduct_name_specialTAGS" style={{width:"100%"}}>
                            <p>Product Tags</p>
                            <input placeholder="Tags" value={formValues.tag} name="tag" onKeyDown={addTag} onChange={changeHandler}/>
                            {tags.map((tag) => {
                                return <p className="addProduct_tag" style={{display:"inline-block", padding:"3px", paddingLeft:"10px",paddingRight:"10px",borderRadius:"7px", marginRight:"0px"}} onClick={e => removeTag(tag)}>{tag}</p>
                            })
                            }
                        </div>
                        <div className="editProduct_name" style={{width:"100%"}}>
                            <p>Stock Info</p>
                            <input placeholder="Stock Info" defaultValue={formValues.stockInfo} name="stockInfo" onChange={changeHandler} />
                        </div>
                    </div>

                    <div className="editProduct_quantitys">      
                        <p className="addProduct_quantitys_title" style={{display:"inline-block"}}>Variable Product</p>  
                        <button className="button" style={{marginLeft:"20px",height:"22px"}} type="button" onClick={postToDatabase2}>Synchronize stocks</button>                
                        <div className="admin_qtySizeData_div_horizontalScrollbar" >
                            <div className="admin-qtySizeData-div">
                                <div className="admin-qtySizeData-div-size">
                                    <p>20</p>
                                </div>
                                <div className="admin-qtySizeData-div-size">
                                    <input defaultValue={formValues.stock20} name="stock20" placeholder="0" onChange={changeHandlerNumber}></input>
                                </div>
                                <div className="admin-qtySizeData-div-size">
                                    <input defaultValue={formValues.price20} name="price20" placeholder="0.00" onChange={changeHandlerNumber}></input>
                                </div>
                                <div className="admin-qtySizeData-div-size">
                                    <input defaultValue={formValues.pvp20} name="pvp20" placeholder="0.00" onChange={changeHandlerNumber}></input>
                                </div>
                                <div className="admin-qtySizeData-div-size">
                                    <input defaultValue={formValues.barcode20} name="barcode20" placeholder="barcode" onChange={changeHandlerNumber}></input>
                                </div>
                                {available20 ? 
                                <div className="admin_available_checkbuttom" onClick={changeAvailableSize20}>
                                </div>
                                :
                                <div className="admin_available_checkbuttom_false" onClick={changeAvailableSize20}>
                                </div>
                                }
                            </div>
                            <div className="admin-qtySizeData-div">
                                <div className="admin-qtySizeData-div-size">
                                    <p>21</p>
                                </div>
                                <div className="admin-qtySizeData-div-size">
                                    <input defaultValue={formValues.stock21} name="stock21" placeholder="0" onChange={changeHandlerNumber}></input>
                                </div>
                                <div className="admin-qtySizeData-div-size">
                                    <input defaultValue={formValues.price21} name="price21" placeholder="0.00" onChange={changeHandlerNumber}></input>
                                </div>
                                <div className="admin-qtySizeData-div-size">
                                    <input defaultValue={formValues.pvp21} name="pvp21" placeholder="0.00" onChange={changeHandlerNumber}></input>
                                </div>
                                <div className="admin-qtySizeData-div-size">
                                    <input defaultValue={formValues.barcode21} name="barcode21" placeholder="barcode" onChange={changeHandlerNumber}></input>
                                </div>
                                {available21 ? 
                                <div className="admin_available_checkbuttom" onClick={changeAvailableSize21}>
                                </div>
                                :
                                <div className="admin_available_checkbuttom_false" onClick={changeAvailableSize21}>
                                </div>
                                }
                            </div>
                            <div className="admin-qtySizeData-div">
                                <div className="admin-qtySizeData-div-size">
                                    <p>22</p>
                                </div>
                                <div className="admin-qtySizeData-div-size">
                                    <input defaultValue={formValues.stock22} name="stock22" placeholder="0" onChange={changeHandlerNumber}></input>
                                </div>
                                <div className="admin-qtySizeData-div-size">
                                    <input defaultValue={formValues.price22} name="price22" placeholder="0.00" onChange={changeHandlerNumber}></input>
                                </div>
                                <div className="admin-qtySizeData-div-size">
                                    <input defaultValue={formValues.pvp22} name="pvp22" placeholder="0.00" onChange={changeHandlerNumber}></input>
                                </div>
                                <div className="admin-qtySizeData-div-size">
                                    <input defaultValue={formValues.barcode22} name="barcode22" placeholder="barcode" onChange={changeHandlerNumber}></input>
                                </div>
                                {available22 ? 
                                <div className="admin_available_checkbuttom" onClick={changeAvailableSize22}>
                                </div>
                                :
                                <div className="admin_available_checkbuttom_false" onClick={changeAvailableSize22}>
                                </div>
                                }
                            </div>
                            <div className="admin-qtySizeData-div">
                                <div className="admin-qtySizeData-div-size">
                                    <p>23</p>
                                </div>
                                <div className="admin-qtySizeData-div-size">
                                    <input defaultValue={formValues.stock23} name="stock23" placeholder="0" onChange={changeHandlerNumber}></input>
                                </div>
                                <div className="admin-qtySizeData-div-size">
                                    <input defaultValue={formValues.price23} name="price23" placeholder="0.00" onChange={changeHandlerNumber}></input>
                                </div>
                                <div className="admin-qtySizeData-div-size">
                                    <input defaultValue={formValues.pvp23} name="pvp23" placeholder="0.00" onChange={changeHandlerNumber}></input>
                                </div>
                                <div className="admin-qtySizeData-div-size">
                                    <input defaultValue={formValues.barcode23} name="barcode23" placeholder="barcode" onChange={changeHandlerNumber}></input>
                                </div>
                                {available23 ? 
                                <div className="admin_available_checkbuttom" onClick={changeAvailableSize23}>
                                </div>
                                :
                                <div className="admin_available_checkbuttom_false" onClick={changeAvailableSize23}>
                                </div>
                                }
                            </div>
                            <div className="admin-qtySizeData-div">
                                <div className="admin-qtySizeData-div-size">
                                    <p>24</p>
                                </div>
                                <div className="admin-qtySizeData-div-size">
                                    <input defaultValue={formValues.stock24} name="stock24" placeholder="0" onChange={changeHandlerNumber}></input>
                                </div>
                                <div className="admin-qtySizeData-div-size">
                                    <input defaultValue={formValues.price24} name="price24" placeholder="0.00" onChange={changeHandlerNumber}></input>
                                </div>
                                <div className="admin-qtySizeData-div-size">
                                    <input defaultValue={formValues.pvp24} name="pvp24" placeholder="0.00" onChange={changeHandlerNumber}></input>
                                </div>
                                <div className="admin-qtySizeData-div-size">
                                    <input defaultValue={formValues.barcode24} name="barcode24" placeholder="barcode" onChange={changeHandlerNumber}></input>
                                </div>
                                {available24 ? 
                                <div className="admin_available_checkbuttom" onClick={changeAvailableSize24}>
                                </div>
                                :
                                <div className="admin_available_checkbuttom_false" onClick={changeAvailableSize24}>
                                </div>
                                }
                            </div>
                            <div className="admin-qtySizeData-div">
                                <div className="admin-qtySizeData-div-size">
                                    <p>25</p>
                                </div>
                                <div className="admin-qtySizeData-div-size">
                                    <input defaultValue={formValues.stock25} name="stock25" placeholder="0" onChange={changeHandlerNumber}></input>
                                </div>
                                <div className="admin-qtySizeData-div-size">
                                    <input defaultValue={formValues.price25} name="price25" placeholder="0.00" onChange={changeHandlerNumber}></input>
                                </div>
                                <div className="admin-qtySizeData-div-size">
                                    <input defaultValue={formValues.pvp25} name="pvp25" placeholder="0.00" onChange={changeHandlerNumber}></input>
                                </div>
                                <div className="admin-qtySizeData-div-size">
                                    <input defaultValue={formValues.barcode25} name="barcode25" placeholder="barcode" onChange={changeHandlerNumber}></input>
                                </div>
                                {available25 ? 
                                <div className="admin_available_checkbuttom" onClick={changeAvailableSize25}>
                                </div>
                                :
                                <div className="admin_available_checkbuttom_false" onClick={changeAvailableSize25}>
                                </div>
                                }
                            </div>
                            <div className="admin-qtySizeData-div">
                                <div className="admin-qtySizeData-div-size">
                                    <p>26</p>
                                </div>
                                <div className="admin-qtySizeData-div-size">
                                    <input defaultValue={formValues.stock26} name="stock26" placeholder="0" onChange={changeHandlerNumber}></input>
                                </div>
                                <div className="admin-qtySizeData-div-size">
                                    <input defaultValue={formValues.price26} name="price26" placeholder="0.00" onChange={changeHandlerNumber}></input>
                                </div>
                                <div className="admin-qtySizeData-div-size">
                                    <input defaultValue={formValues.pvp26} name="pvp26" placeholder="0.00" onChange={changeHandlerNumber}></input>
                                </div>
                                <div className="admin-qtySizeData-div-size">
                                    <input defaultValue={formValues.barcode26} name="barcode26" placeholder="barcode" onChange={changeHandlerNumber}></input>
                                </div>
                                {available26 ? 
                                <div className="admin_available_checkbuttom" onClick={changeAvailableSize26}>
                                </div>
                                :
                                <div className="admin_available_checkbuttom_false" onClick={changeAvailableSize26}>
                                </div>
                                }
                            </div>
                            <div className="admin-qtySizeData-div">
                                <div className="admin-qtySizeData-div-size">
                                    <p>27</p>
                                </div>
                                <div className="admin-qtySizeData-div-size">
                                    <input defaultValue={formValues.stock27} name="stock27" placeholder="0" onChange={changeHandlerNumber}></input>
                                </div>
                                <div className="admin-qtySizeData-div-size">
                                    <input defaultValue={formValues.price27} name="price27" placeholder="0.00" onChange={changeHandlerNumber}></input>
                                </div>
                                <div className="admin-qtySizeData-div-size">
                                    <input defaultValue={formValues.pvp27} name="pvp27" placeholder="0.00" onChange={changeHandlerNumber}></input>
                                </div>
                                <div className="admin-qtySizeData-div-size">
                                    <input defaultValue={formValues.barcode27} name="barcode27" placeholder="barcode" onChange={changeHandlerNumber}></input>
                                </div>
                                {available27 ? 
                                <div className="admin_available_checkbuttom" onClick={changeAvailableSize27}>
                                </div>
                                :
                                <div className="admin_available_checkbuttom_false" onClick={changeAvailableSize27}>
                                </div>
                                }
                            </div>
                            <div className="admin-qtySizeData-div">
                                <div className="admin-qtySizeData-div-size">
                                    <p>28</p>
                                </div>
                                <div className="admin-qtySizeData-div-size">
                                    <input defaultValue={formValues.stock28} name="stock28" placeholder="0" onChange={changeHandlerNumber}></input>
                                </div>
                                <div className="admin-qtySizeData-div-size">
                                    <input defaultValue={formValues.price28} name="price28" placeholder="0.00" onChange={changeHandlerNumber}></input>
                                </div>
                                <div className="admin-qtySizeData-div-size">
                                    <input defaultValue={formValues.pvp28} name="pvp28" placeholder="0.00" onChange={changeHandlerNumber}></input>
                                </div>
                                <div className="admin-qtySizeData-div-size">
                                    <input defaultValue={formValues.barcode28} name="barcode28" placeholder="barcode" onChange={changeHandlerNumber}></input>
                                </div>
                                {available28 ? 
                                <div className="admin_available_checkbuttom" onClick={changeAvailableSize28}>
                                </div>
                                :
                                <div className="admin_available_checkbuttom_false" onClick={changeAvailableSize28}>
                                </div>
                                }
                            </div>
                            <div className="admin-qtySizeData-div">
                                <div className="admin-qtySizeData-div-size">
                                    <p>29</p>
                                </div>
                                <div className="admin-qtySizeData-div-size">
                                    <input defaultValue={formValues.stock29} name="stock29" placeholder="0" onChange={changeHandlerNumber}></input>
                                </div>
                                <div className="admin-qtySizeData-div-size">
                                    <input defaultValue={formValues.price29} name="price29" placeholder="0.00" onChange={changeHandlerNumber}></input>
                                </div>
                                <div className="admin-qtySizeData-div-size">
                                    <input defaultValue={formValues.pvp29} name="pvp29" placeholder="0.00" onChange={changeHandlerNumber}></input>
                                </div>
                                <div className="admin-qtySizeData-div-size">
                                    <input defaultValue={formValues.barcode29} name="barcode29" placeholder="barcode" onChange={changeHandlerNumber}></input>
                                </div>
                                {available29 ? 
                                <div className="admin_available_checkbuttom" onClick={changeAvailableSize29}>
                                </div>
                                :
                                <div className="admin_available_checkbuttom_false" onClick={changeAvailableSize29}>
                                </div>
                                }
                            </div>
                            <div className="admin-qtySizeData-div">
                                <div className="admin-qtySizeData-div-size">
                                    <p>30</p>
                                </div>
                                <div className="admin-qtySizeData-div-size">
                                    <input defaultValue={formValues.stock30} name="stock30" placeholder="0" onChange={changeHandlerNumber}></input>
                                </div>
                                <div className="admin-qtySizeData-div-size">
                                    <input defaultValue={formValues.price30} name="price30" placeholder="0.00" onChange={changeHandlerNumber}></input>
                                </div>
                                <div className="admin-qtySizeData-div-size">
                                    <input defaultValue={formValues.pvp30} name="pvp30" placeholder="0.00" onChange={changeHandlerNumber}></input>
                                </div>
                                <div className="admin-qtySizeData-div-size">
                                    <input defaultValue={formValues.barcode30} name="barcode30" placeholder="barcode" onChange={changeHandlerNumber}></input>
                                </div>
                                {available30 ? 
                                <div className="admin_available_checkbuttom" onClick={changeAvailableSize30}>
                                </div>
                                :
                                <div className="admin_available_checkbuttom_false" onClick={changeAvailableSize30}>
                                </div>
                                }
                            </div>
                            <div className="admin-qtySizeData-div">
                                <div className="admin-qtySizeData-div-size">
                                    <p>31</p>
                                </div>
                                <div className="admin-qtySizeData-div-size">
                                    <input defaultValue={formValues.stock31} name="stock31" placeholder="0" onChange={changeHandlerNumber}></input>
                                </div>
                                <div className="admin-qtySizeData-div-size">
                                    <input defaultValue={formValues.price31} name="price31" placeholder="0.00" onChange={changeHandlerNumber}></input>
                                </div>
                                <div className="admin-qtySizeData-div-size">
                                    <input defaultValue={formValues.pvp31} name="pvp31" placeholder="0.00" onChange={changeHandlerNumber}></input>
                                </div>
                                <div className="admin-qtySizeData-div-size">
                                    <input defaultValue={formValues.barcode31} name="barcode31" placeholder="barcode" onChange={changeHandlerNumber}></input>
                                </div>
                                {available31 ? 
                                <div className="admin_available_checkbuttom" onClick={changeAvailableSize31}>
                                </div>
                                :
                                <div className="admin_available_checkbuttom_false" onClick={changeAvailableSize31}>
                                </div>
                                }
                            </div>
                            <div className="admin-qtySizeData-div">
                                <div className="admin-qtySizeData-div-size">
                                    <p>32</p>
                                </div>
                                <div className="admin-qtySizeData-div-size">
                                    <input defaultValue={formValues.stock32} name="stock32" placeholder="0" onChange={changeHandlerNumber}></input>
                                </div>
                                <div className="admin-qtySizeData-div-size">
                                    <input defaultValue={formValues.price32} name="price32" placeholder="0.00" onChange={changeHandlerNumber}></input>
                                </div>
                                <div className="admin-qtySizeData-div-size">
                                    <input defaultValue={formValues.pvp32} name="pvp32" placeholder="0.00" onChange={changeHandlerNumber}></input>
                                </div>
                                <div className="admin-qtySizeData-div-size">
                                    <input defaultValue={formValues.barcode32} name="barcode32" placeholder="barcode" onChange={changeHandlerNumber}></input>
                                </div>
                                {available32 ? 
                                <div className="admin_available_checkbuttom" onClick={changeAvailableSize32}>
                                </div>
                                :
                                <div className="admin_available_checkbuttom_false" onClick={changeAvailableSize32}>
                                </div>
                                }
                            </div>
                            <div className="admin-qtySizeData-div">
                                <div className="admin-qtySizeData-div-size">
                                    <p>33</p>
                                </div>
                                <div className="admin-qtySizeData-div-size">
                                    <input defaultValue={formValues.stock33} name="stock33" placeholder="0" onChange={changeHandlerNumber}></input>
                                </div>
                                <div className="admin-qtySizeData-div-size">
                                    <input defaultValue={formValues.price33} name="price33" placeholder="0.00" onChange={changeHandlerNumber}></input>
                                </div>
                                <div className="admin-qtySizeData-div-size">
                                    <input defaultValue={formValues.pvp33} name="pvp33" placeholder="0.00" onChange={changeHandlerNumber}></input>
                                </div>
                                <div className="admin-qtySizeData-div-size">
                                    <input defaultValue={formValues.barcode33} name="barcode33" placeholder="barcode" onChange={changeHandlerNumber}></input>
                                </div>
                                {available33 ? 
                                <div className="admin_available_checkbuttom" onClick={changeAvailableSize33}>
                                </div>
                                :
                                <div className="admin_available_checkbuttom_false" onClick={changeAvailableSize33}>
                                </div>
                                }
                            </div>
                            <div className="admin-qtySizeData-div">
                                <div className="admin-qtySizeData-div-size">
                                    <p>34</p>
                                </div>
                                <div className="admin-qtySizeData-div-size">
                                    <input defaultValue={formValues.stock34} name="stock34" placeholder="0" onChange={changeHandlerNumber}></input>
                                </div>
                                <div className="admin-qtySizeData-div-size">
                                    <input defaultValue={formValues.price34} name="price34" placeholder="0.00" onChange={changeHandlerNumber}></input>
                                </div>
                                <div className="admin-qtySizeData-div-size">
                                    <input defaultValue={formValues.pvp34} name="pvp34" placeholder="0.00" onChange={changeHandlerNumber}></input>
                                </div>
                                <div className="admin-qtySizeData-div-size">
                                    <input defaultValue={formValues.barcode34} name="barcode34" placeholder="barcode" onChange={changeHandlerNumber}></input>
                                </div>
                                {available34 ? 
                                <div className="admin_available_checkbuttom" onClick={changeAvailableSize34}>
                                </div>
                                :
                                <div className="admin_available_checkbuttom_false" onClick={changeAvailableSize34}>
                                </div>
                                }
                            </div>
                            <div className="admin-qtySizeData-div">
                                <div className="admin-qtySizeData-div-size">
                                    <p>35</p>
                                </div>
                                <div className="admin-qtySizeData-div-size">
                                    <input defaultValue={formValues.stock35} name="stock35" placeholder="0" onChange={changeHandlerNumber}></input>
                                </div>
                                <div className="admin-qtySizeData-div-size">
                                    <input defaultValue={formValues.price35} name="price35" placeholder="0.00" onChange={changeHandlerNumber}></input>
                                </div>
                                <div className="admin-qtySizeData-div-size">
                                    <input defaultValue={formValues.pvp35} name="pvp35" placeholder="0.00" onChange={changeHandlerNumber}></input>
                                </div>
                                <div className="admin-qtySizeData-div-size">
                                    <input defaultValue={formValues.barcode35} name="barcode35" placeholder="barcode" onChange={changeHandlerNumber}></input>
                                </div>
                                {available35 ? 
                                <div className="admin_available_checkbuttom" onClick={changeAvailableSize35}>
                                </div>
                                :
                                <div className="admin_available_checkbuttom_false" onClick={changeAvailableSize35}>
                                </div>
                                }
                            </div>
                            <div className="admin-qtySizeData-div">
                                <div className="admin-qtySizeData-div-size">
                                    <p>36</p>
                                </div>
                                <div className="admin-qtySizeData-div-size">
                                    <input defaultValue={formValues.stock36} name="stock36" placeholder="0" onChange={changeHandlerNumber}></input>
                                </div>
                                <div className="admin-qtySizeData-div-size">
                                    <input defaultValue={formValues.price36} name="price36" placeholder="0.00" onChange={changeHandlerNumber}></input>
                                </div>
                                <div className="admin-qtySizeData-div-size">
                                    <input defaultValue={formValues.pvp36} name="pvp36" placeholder="0.00" onChange={changeHandlerNumber}></input>
                                </div>
                                <div className="admin-qtySizeData-div-size">
                                    <input defaultValue={formValues.barcode36} name="barcode36" placeholder="barcode" onChange={changeHandlerNumber}></input>
                                </div>
                                {available36 ? 
                                <div className="admin_available_checkbuttom" onClick={changeAvailableSize36}>
                                </div>
                                :
                                <div className="admin_available_checkbuttom_false" onClick={changeAvailableSize36}>
                                </div>
                                }
                            </div>
                            <div className="admin-qtySizeData-div">
                                <div className="admin-qtySizeData-div-size">
                                    <p>37</p>
                                </div>
                                <div className="admin-qtySizeData-div-size">
                                    <input defaultValue={formValues.stock37} name="stock37" placeholder="0" onChange={changeHandlerNumber}></input>
                                </div>
                                <div className="admin-qtySizeData-div-size">
                                    <input defaultValue={formValues.price37} name="price37" placeholder="0.00" onChange={changeHandlerNumber}></input>
                                </div>
                                <div className="admin-qtySizeData-div-size">
                                    <input defaultValue={formValues.pvp37} name="pvp37" placeholder="0.00" onChange={changeHandlerNumber}></input>
                                </div>
                                <div className="admin-qtySizeData-div-size">
                                    <input defaultValue={formValues.barcode37} name="barcode37" placeholder="barcode" onChange={changeHandlerNumber}></input>
                                </div>
                                {available37 ? 
                                <div className="admin_available_checkbuttom" onClick={changeAvailableSize37}>
                                </div>
                                :
                                <div className="admin_available_checkbuttom_false" onClick={changeAvailableSize37}>
                                </div>
                                }
                            </div>
                            <div className="admin-qtySizeData-div">
                                <div className="admin-qtySizeData-div-size">
                                    <p>38</p>
                                </div>
                                <div className="admin-qtySizeData-div-size">
                                    <input defaultValue={formValues.stock38} name="stock38" placeholder="0" onChange={changeHandlerNumber}></input>
                                </div>
                                <div className="admin-qtySizeData-div-size">
                                    <input defaultValue={formValues.price38} name="price38" placeholder="0.00" onChange={changeHandlerNumber}></input>
                                </div>
                                <div className="admin-qtySizeData-div-size">
                                    <input defaultValue={formValues.pvp38} name="pvp38" placeholder="0.00" onChange={changeHandlerNumber}></input>
                                </div>
                                <div className="admin-qtySizeData-div-size">
                                    <input defaultValue={formValues.barcode38} name="barcode38" placeholder="barcode" onChange={changeHandlerNumber}></input>
                                </div>
                                {available38 ? 
                                <div className="admin_available_checkbuttom" onClick={changeAvailableSize38}>
                                </div>
                                :
                                <div className="admin_available_checkbuttom_false" onClick={changeAvailableSize38}>
                                </div>
                                }
                            </div>
                            <div className="admin-qtySizeData-div">
                                <div className="admin-qtySizeData-div-size">
                                    <p>39</p>
                                </div>
                                <div className="admin-qtySizeData-div-size">
                                    <input defaultValue={formValues.stock39} name="stock39" placeholder="0" onChange={changeHandlerNumber}></input>
                                </div>
                                <div className="admin-qtySizeData-div-size">
                                    <input defaultValue={formValues.price39} name="price39" placeholder="0.00" onChange={changeHandlerNumber}></input>
                                </div>
                                <div className="admin-qtySizeData-div-size">
                                    <input defaultValue={formValues.pvp39} name="pvp39" placeholder="0.00" onChange={changeHandlerNumber}></input>
                                </div>
                                <div className="admin-qtySizeData-div-size">
                                    <input defaultValue={formValues.barcode39} name="barcode39" placeholder="barcode" onChange={changeHandlerNumber}></input>
                                </div>
                                {available39 ? 
                                <div className="admin_available_checkbuttom" onClick={changeAvailableSize39}>
                                </div>
                                :
                                <div className="admin_available_checkbuttom_false" onClick={changeAvailableSize39}>
                                </div>
                                }
                            </div>
                            <div className="admin-qtySizeData-div">
                                <div className="admin-qtySizeData-div-size">
                                    <p>40</p>
                                </div>
                                <div className="admin-qtySizeData-div-size">
                                    <input defaultValue={formValues.stock40} name="stock40" placeholder="0" onChange={changeHandlerNumber}></input>
                                </div>
                                <div className="admin-qtySizeData-div-size">
                                    <input defaultValue={formValues.price40} name="price40" placeholder="0.00" onChange={changeHandlerNumber}></input>
                                </div>
                                <div className="admin-qtySizeData-div-size">
                                    <input defaultValue={formValues.pvp40} name="pvp40" placeholder="0.00" onChange={changeHandlerNumber}></input>
                                </div>
                                <div className="admin-qtySizeData-div-size">
                                    <input defaultValue={formValues.barcode40} name="barcode40" placeholder="barcode" onChange={changeHandlerNumber}></input>
                                </div>
                                {available40 ? 
                                <div className="admin_available_checkbuttom" onClick={changeAvailableSize40}>
                                </div>
                                :
                                <div className="admin_available_checkbuttom_false" onClick={changeAvailableSize40}>
                                </div>
                                }
                            </div>
                            <div className="admin-qtySizeData-div">
                                <div className="admin-qtySizeData-div-size">
                                    <p>41</p>
                                </div>
                                <div className="admin-qtySizeData-div-size">
                                    <input defaultValue={formValues.stock41} name="stock41" placeholder="0" onChange={changeHandlerNumber}></input>
                                </div>
                                <div className="admin-qtySizeData-div-size">
                                    <input defaultValue={formValues.price41} name="price41" placeholder="0.00" onChange={changeHandlerNumber}></input>
                                </div>
                                <div className="admin-qtySizeData-div-size">
                                    <input defaultValue={formValues.pvp41} name="pvp41" placeholder="0.00" onChange={changeHandlerNumber}></input>
                                </div>
                                <div className="admin-qtySizeData-div-size">
                                    <input defaultValue={formValues.barcode41} name="barcode41" placeholder="barcode" onChange={changeHandlerNumber}></input>
                                </div>
                                {available41 ? 
                                <div className="admin_available_checkbuttom" onClick={changeAvailableSize41}>
                                </div>
                                :
                                <div className="admin_available_checkbuttom_false" onClick={changeAvailableSize41}>
                                </div>
                                }
                            </div>
                            <div className="admin-qtySizeData-div">
                                <div className="admin-qtySizeData-div-size">
                                    <p>42</p>
                                </div>
                                <div className="admin-qtySizeData-div-size">
                                    <input defaultValue={formValues.stock42} name="stock42" placeholder="0" onChange={changeHandlerNumber}></input>
                                </div>
                                <div className="admin-qtySizeData-div-size">
                                    <input defaultValue={formValues.price42} name="price42" placeholder="0.00" onChange={changeHandlerNumber}></input>
                                </div>
                                <div className="admin-qtySizeData-div-size">
                                    <input defaultValue={formValues.pvp42} name="pvp42" placeholder="0.00" onChange={changeHandlerNumber}></input>
                                </div>
                                <div className="admin-qtySizeData-div-size">
                                    <input defaultValue={formValues.barcode42} name="barcode42" placeholder="barcode" onChange={changeHandlerNumber}></input>
                                </div>
                                {available42 ? 
                                <div className="admin_available_checkbuttom" onClick={changeAvailableSize42}>
                                </div>
                                :
                                <div className="admin_available_checkbuttom_false" onClick={changeAvailableSize42}>
                                </div>
                                }
                            </div>
                            <div className="admin-qtySizeData-div">
                                <div className="admin-qtySizeData-div-size">
                                    <p>43</p>
                                </div>
                                <div className="admin-qtySizeData-div-size">
                                    <input defaultValue={formValues.stock43} name="stock43" placeholder="0" onChange={changeHandlerNumber}></input>
                                </div>
                                <div className="admin-qtySizeData-div-size">
                                    <input defaultValue={formValues.price43} name="price43" placeholder="0.00" onChange={changeHandlerNumber}></input>
                                </div>
                                <div className="admin-qtySizeData-div-size">
                                    <input defaultValue={formValues.pvp43} name="pvp43" placeholder="0.00" onChange={changeHandlerNumber}></input>
                                </div>
                                <div className="admin-qtySizeData-div-size">
                                    <input defaultValue={formValues.barcode43} name="barcode43" placeholder="barcode" onChange={changeHandlerNumber}></input>
                                </div>
                                {available43 ? 
                                <div className="admin_available_checkbuttom" onClick={changeAvailableSize43}>
                                </div>
                                :
                                <div className="admin_available_checkbuttom_false" onClick={changeAvailableSize43}>
                                </div>
                                }
                            </div>
                            <div className="admin-qtySizeData-div">
                                <div className="admin-qtySizeData-div-size">
                                    <p>44</p>
                                </div>
                                <div className="admin-qtySizeData-div-size">
                                    <input defaultValue={formValues.stock44} name="stock44" placeholder="0" onChange={changeHandlerNumber}></input>
                                </div>
                                <div className="admin-qtySizeData-div-size">
                                    <input defaultValue={formValues.price44} name="price44" placeholder="0.00" onChange={changeHandlerNumber}></input>
                                </div>
                                <div className="admin-qtySizeData-div-size">
                                    <input defaultValue={formValues.pvp44} name="pvp44" placeholder="0.00" onChange={changeHandlerNumber}></input>
                                </div>
                                <div className="admin-qtySizeData-div-size">
                                    <input defaultValue={formValues.barcode44} name="barcode44" placeholder="barcode" onChange={changeHandlerNumber}></input>
                                </div>
                                {available44 ? 
                                <div className="admin_available_checkbuttom" onClick={changeAvailableSize44}>
                                </div>
                                :
                                <div className="admin_available_checkbuttom_false" onClick={changeAvailableSize44}>
                                </div>
                                }
                            </div>
                            <div className="admin-qtySizeData-div">
                                <div className="admin-qtySizeData-div-size">
                                    <p>45</p>
                                </div>
                                <div className="admin-qtySizeData-div-size">
                                    <input defaultValue={formValues.stock45} name="stock45" placeholder="0" onChange={changeHandlerNumber}></input>
                                </div>
                                <div className="admin-qtySizeData-div-size">
                                    <input defaultValue={formValues.price45} name="price45" placeholder="0.00" onChange={changeHandlerNumber}></input>
                                </div>
                                <div className="admin-qtySizeData-div-size">
                                    <input defaultValue={formValues.pvp45} name="pvp45" placeholder="0.00" onChange={changeHandlerNumber}></input>
                                </div>
                                <div className="admin-qtySizeData-div-size">
                                    <input defaultValue={formValues.barcode45} name="barcode45" placeholder="barcode" onChange={changeHandlerNumber}></input>
                                </div>
                                {available45 ? 
                                <div className="admin_available_checkbuttom" onClick={changeAvailableSize45}>
                                </div>
                                :
                                <div className="admin_available_checkbuttom_false" onClick={changeAvailableSize45}>
                                </div>
                                }
                            </div>
                            <div className="admin-qtySizeData-div">
                                <div className="admin-qtySizeData-div-size">
                                    <p>46</p>
                                </div>
                                <div className="admin-qtySizeData-div-size">
                                    <input defaultValue={formValues.stock46} name="stock46" placeholder="0" onChange={changeHandlerNumber}></input>
                                </div>
                                <div className="admin-qtySizeData-div-size">
                                    <input defaultValue={formValues.price46} name="price46" placeholder="0.00" onChange={changeHandlerNumber}></input>
                                </div>
                                <div className="admin-qtySizeData-div-size">
                                    <input defaultValue={formValues.pvp46} name="pvp46" placeholder="0.00" onChange={changeHandlerNumber}></input>
                                </div>
                                <div className="admin-qtySizeData-div-size">
                                    <input defaultValue={formValues.barcode46} name="barcode46" placeholder="barcode" onChange={changeHandlerNumber}></input>
                                </div>
                                {available46 ? 
                                <div className="admin_available_checkbuttom" onClick={changeAvailableSize46}>
                                </div>
                                :
                                <div className="admin_available_checkbuttom_false" onClick={changeAvailableSize46}>
                                </div>
                                }
                            </div>
                        </div>
                        <Div height="210px" float="left">
                            <p className="adminAddProduct-legend">Size</p>
                            <p className="adminAddProduct-legend">Stock</p>
                            <p className="adminAddProduct-legend">Cost Price</p>
                            <p className="adminAddProduct-legend">PVP Price</p>
                            <p className="adminAddProduct-legend">Barcode</p>
                            <p className="adminAddProduct-legend">Available</p>
                        </Div>
                    </div>
                </form>
                {!visibility ? 
                    (deleteDialog ? 
                        <DeleteDialog
                            type="delete" 
                            action={deleteProduct} 
                            title="DELETE PRODUCT"
                            text="This action will delete your product from B2B and Shopify. Are you sure you want to delete it?" 
                        /> 
                        :
                        <DeleteDialog
                        type="save" 
                        action={postToDatabase} 
                        title="SAVE PRODUCT"
                        text="Are you sure you want to save this product?" 
                    />
                    )
                    :
                    <span></span>
                }
                </div>
            </div>
        
        );
    } else {
        return <Loading />
    }
};

export default AdminEditProduct;