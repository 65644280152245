//Style
import './AdminOrders.css';

//Packages
import React, {useState, useEffect, useContext} from 'react';
import { Authcontext } from '../../../../login/context/auth-context';

//Components
import AdminOrdersOrder from '../components/AdminOrdersOrder';
import Loading from '../../../shared/loading/Loading';

// Functions
import { GETauthrequest } from '../../../../shared/functions/requests';

// -- FUNCTION
const AdminOrders = props => {

    const auth = useContext(Authcontext);

    //B2B or Shopify orders
    const [B2B, setB2B] = useState(true);

    const [loadedData, setLoadedData] = useState();
    const [orders, setOrders] = useState();

    useEffect(() => {
        setB2B(true);
        GETauthrequest(auth,'/orders/admin').then((res) => {
            setOrders(res.orders.reverse());
            setLoadedData(res.orders);
        })
    }, [auth]);

    // Filter by search
    function handleFilterSearch(e){
        setLoadedData(orders.filter(p => p.email.toUpperCase().startsWith(e.target.value.toUpperCase())));
    }

    // Shopify orders
    function shopifyOrders(){
        setB2B(false);
        GETauthrequest(auth,'/orders/shopifyOrders').then((res) => {
            setOrders(res.orders);
            setLoadedData(res.orders);
        })
    }

    // B2B orders
    function b2bOrders(){
        setB2B(true);
        GETauthrequest(auth,'/orders/admin').then((res) => {
            setOrders(res.orders.reverse());
            setLoadedData(res.orders);
        })
    }

    // -- RETURN
    if(loadedData){
        return(
            <div className="admin_orders">
                <input className="admin_catalog_search" placeholder="Search Orders" onChange={handleFilterSearch}/>
                <div className="admin_pages_title">
                    <div className="admin_pages_title_divResponsive">
                        <h1>Orders</h1>
                        <button style={{marginRight:"5px", marginTop:"40px", float:"right"}} className="button" onClick={shopifyOrders}>Shopify</button>
                        <button style={{marginRight:"5px", marginTop:"40px", float:"right"}} className="button" onClick={b2bOrders}>B2B</button>
                    </div>
                </div>    
                <div className="admin_orders_div">
                    <div className="admin_orders_header_div">
                        <div className="adminOrdersOrder-onequarter1t">
                            <p className="adminOrdersOrder-onequarter1-p">Order ID</p>
                        </div>
                        <div className="adminOrdersOrder-onequarter2t">
                            <p className="adminOrdersOrder-onequarter1-p">Client</p>
                        </div>
                        <div className="adminOrdersOrder-onequarter3t">
                            <p className="adminOrdersOrder-onequarter1-p">Date</p>
                        </div>
                        <div className="adminOrdersOrder-onequarter4t">
                            <p className="adminOrdersOrder-onequarter1-p">Quantity</p>
                        </div>
                        <div className="adminOrdersOrder-onequarter5t">
                            <p className="adminOrdersOrder-onequarter1-p">Price</p>
                        </div>
                        <div className="adminOrdersOrder-onequarter6t">
                            <p className="adminOrdersOrder-onequarter1-p">Payment</p>
                        </div>
                        <div className="adminOrdersOrder-onequarter7t">
                            <p className="adminOrdersOrder-onequarter1-p">Status</p>
                        </div>
                    </div>  
                    {loadedData.map((o) => (
                        <AdminOrdersOrder b2b={B2B} orderId={o.orderId} view={o.view} date={o.date} cost={o.cost} quantity={o.quantity} email={o.email} payment={o.payment} status={o.status} />
                    ))}         
                </div>
            </div>
        );
    }else{
        return(
            <Loading/>  
        )
    }
}

export default AdminOrders;