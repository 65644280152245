//Style
import './UserProduct.css';

//Packages
import React, { useState, useEffect } from "react";
import {useParams} from 'react-router-dom';

//Components
import Imagens from "../components/Imagens";
import Informacao from '../components/Informacao';
import Spi from '../../../../shared/animations/Spi';

// Function
import { GETrequest } from '../../../../shared/functions/requests';

import {useSelector} from 'react-redux';

const UserProduct = props => {

    const productId = useParams().pid;
    const [loadedData, setLoadedData] = useState();

    useEffect(() => {
        GETrequest('/products/getById/' + productId).then((res) => {
            setLoadedData(res);
        })
    }, [productId]); 
    
        // Get user discount
        const discountValue = useSelector(state => state.userInfo);
        const { value } = discountValue;
        const discount = (100-value)/100;
        console.log("valor disconto: " + discount);
    
    var prices2 = [];
    var maxPrice = 0;
    var minPrice = 0;
    if(loadedData){
        if(loadedData[0].price20 !== 0 && Number(loadedData[0].price20)) prices2.push(loadedData[0].price20*discount);
        if(loadedData[0].price21 !== 0 && Number(loadedData[0].price21)) prices2.push(loadedData[0].price21*discount);
        if(loadedData[0].price22 !== 0 && Number(loadedData[0].price22)) prices2.push(loadedData[0].price22*discount);
        if(loadedData[0].price23 !== 0 && Number(loadedData[0].price23)) prices2.push(loadedData[0].price23*discount);
        if(loadedData[0].price24 !== 0 && Number(loadedData[0].price24)) prices2.push(loadedData[0].price24*discount);
        if(loadedData[0].price25 !== 0 && Number(loadedData[0].price25)) prices2.push(loadedData[0].price25*discount);
        if(loadedData[0].price26 !== 0 && Number(loadedData[0].price26)) prices2.push(loadedData[0].price26*discount);
        if(loadedData[0].price27 !== 0 && Number(loadedData[0].price27)) prices2.push(loadedData[0].price27*discount);
        if(loadedData[0].price28 !== 0 && Number(loadedData[0].price28)) prices2.push(loadedData[0].price28*discount);
        if(loadedData[0].price29 !== 0 && Number(loadedData[0].price29)) prices2.push(loadedData[0].price29*discount);
        if(loadedData[0].price30 !== 0 && Number(loadedData[0].price30)) prices2.push(loadedData[0].price30*discount);
        if(loadedData[0].price31 !== 0 && Number(loadedData[0].price31)) prices2.push(loadedData[0].price31*discount);
        if(loadedData[0].price32 !== 0 && Number(loadedData[0].price32)) prices2.push(loadedData[0].price32*discount);
        if(loadedData[0].price33 !== 0 && Number(loadedData[0].price33)) prices2.push(loadedData[0].price33*discount);
        if(loadedData[0].price34 !== 0 && Number(loadedData[0].price34)) prices2.push(loadedData[0].price34*discount);
        if(loadedData[0].price35 !== 0 && Number(loadedData[0].price35)) prices2.push(loadedData[0].price35*discount);
        if(loadedData[0].price36 !== 0 && Number(loadedData[0].price36)) prices2.push(loadedData[0].price36*discount);
        if(loadedData[0].price37 !== 0 && Number(loadedData[0].price37)) prices2.push(loadedData[0].price37*discount);
        if(loadedData[0].price38 !== 0 && Number(loadedData[0].price38)) prices2.push(loadedData[0].price38*discount);
        if(loadedData[0].price39 !== 0 && Number(loadedData[0].price39)) prices2.push(loadedData[0].price39*discount);
        if(loadedData[0].price40 !== 0 && Number(loadedData[0].price40)) prices2.push(loadedData[0].price40*discount);
        if(loadedData[0].price41 !== 0 && Number(loadedData[0].price41)) prices2.push(loadedData[0].price41*discount);
        if(loadedData[0].price42 !== 0 && Number(loadedData[0].price42)) prices2.push(loadedData[0].price42*discount);
        if(loadedData[0].price43 !== 0 && Number(loadedData[0].price43)) prices2.push(loadedData[0].price43*discount);
        if(loadedData[0].price44 !== 0 && Number(loadedData[0].price44)) prices2.push(loadedData[0].price44*discount);
        if(loadedData[0].price45 !== 0 && Number(loadedData[0].price45)) prices2.push(loadedData[0].price45*discount);
        if(loadedData[0].price46 !== 0 && Number(loadedData[0].price46)) prices2.push(loadedData[0].price46*discount);
        maxPrice = parseFloat(Math.max.apply(null, prices2)).toFixed(2);
        minPrice = parseFloat(Math.min.apply(null, prices2)).toFixed(2);
    }    

    // -- RETURN
    if(loadedData){
        return(
            <div className="userProduct-div">
                <Imagens 
                    img1={loadedData[0].image1}
                    img2={loadedData[0].image2}
                    img3={loadedData[0].image3}
                    img4={loadedData[0].image4}
                />
                <Informacao 
                    pid={productId}
                    category={loadedData[0].category}
                    title={loadedData[0].title}
                    maxPrice={maxPrice}
                    minPrice={minPrice}
                    description={loadedData[0].description}
                    materials={loadedData[0].materials}
                    stockInfo={loadedData[0].stockInfo}
                />
            </div>
        );
    } else{
        return <Spi />
    }
};

export default UserProduct;