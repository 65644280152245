import styles from './style.module.css';

const Component2 = props => {
    return(
        <div className={styles.component2}>
            <div className={styles.bucket1}>
                <p>Notes:</p>
                <p>{props.order.orderNote}</p>
            </div>
            <div className={styles.bucket2}>
                <p>Shipping to:</p>
                <p>{props.order.shippingAdress}</p>
            </div>
            <div className={styles.bucket3}>
                <p>Quantity(items): <span>{props.order.quantity}</span></p>
                <div></div>
                <p>Total: <span>{props.order.cost}€</span></p>
            </div>
        </div>    
    )
}

export default Component2;