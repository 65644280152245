import Spi from '../../../shared/animations/Spi';

const Loading = () => {
    return(
        <div style={{marginLeft:"230px",height:"700px",overflow:"hidden"}}>
            <div style={{width:"70px",height:"100px",margin:"auto",marginTop:"250px"}}>
                <Spi />
            </div>
        </div>    
    );
}

export default Loading;