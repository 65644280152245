import './MainDiv.css';

const MainDiv = props => {
    return(
        <div className="container-mainDiv">
            {props.children}
        </div>
    );
};

export default MainDiv;