//Style
import './Spi.css';

//Packages
import { ScaleLoader } from 'react-spinners'

// -- FUNCTION
const Spi = () => {

    // -- RETURN
    return(
        <div className="divSpinner">
            <ScaleLoader
                color="#052F56"
                height={70}
                width={9}
            />
        </div>
    )
}

export default Spi;