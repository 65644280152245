import './Div.css';

const Div = props => {

    const style = {}

    if(props.width){
        style.width = props.width;
    }

    if(props.height){
        style.height = props.height;
    }

    if(props.marginBottom){
        style.marginBottom = props.marginBottom;
    }

    if(props.marginRight){
        style.marginRight = props.marginRight;
    }

    if(props.marginLeft){
        style.marginLeft = props.marginLeft;
    }

    if(props.marginTop){
        style.marginTop = props.marginTop;
    }

    if(props.margin){
        style.margin = props.margin;
    }

    if(props.float){
        style.float = props.float;
    }

    if(props.backgroundColor){
        style.backgroundColor = props.backgroundColor;
    }

    if(props.border){
        style.border = props.border;
    }
    
    return(
        <div className="container-div" style={style}>
            {props.children}
        </div>
    );
};

export default Div;