import './FullLine.css';

const FullLine = props => {
    return(
        <div className="quantity-fullLine">

        </div>
    )
}

export default FullLine;