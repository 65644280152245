//Style
import './AdminUsers.css';

//Packages
import React, {useEffect, useState, useContext} from 'react';
import { Link } from 'react-router-dom';
import { Authcontext } from '../../../../login/context/auth-context';

//Components
import AdminUser from '../components/AdminUser';
import Loading from '../../../shared/loading/Loading';

// Functions
import { GETauthrequest } from '../../../../shared/functions/requests';

import { changeDialogVisibility } from '../../../../redux/actions/dialogActions';
import { useDispatch } from 'react-redux';


const AdminUsers = () => {

    const auth = useContext(Authcontext);

    // Problema dialog mantia inativa navbar quando se volta atras
    const dispatch = useDispatch();

    const [loadedData, setLoadedData] = useState();
    const [users, setUsers] = useState();
    
    //Fetch users
    useEffect(() => {
        // Problema dialog mantia inativa navbar quando se volta atras
        dispatch(changeDialogVisibility(true));

        GETauthrequest(auth,'/users/get').then((res) => {
            setUsers(res.users);
            setLoadedData(res.users);
        })
    }, [auth, dispatch]);

    //Filter by search
    function handleFilterSearch(e){
        setLoadedData(users.filter(p => p.companyName.toUpperCase().startsWith(e.target.value.toUpperCase())));
    }

    // Return
    if(loadedData){
        return(
            <div className="admin_users">
                <input className="admin_catalog_search" placeholder="Search Users" onChange={handleFilterSearch}/>
                <div className="admin_pages_title">
                    <div className="admin_pages_title_divResponsive">
                        <h1>Users</h1>
                        <div className="admin_catalog_div_button">
                            <Link to="/admin/users/adduser"><button className="button" style={{float:"right",marginTop:"40px"}}>Add new user</button></Link>
                            <Link to="/admin/users/importUsers"><button className="button_back" style={{float:"right",marginTop:"40px",marginRight:"20px"}}>Import csv</button></Link>
                        </div>
                    </div>
                </div>
                <div className="admin_users_div">
                    <div className="users_table">
                        <div className="users_table_header1">
                            <p>Client ID</p>
                        </div>
                        <div className="users_table_header2">
                            <p>Name</p>
                        </div>
                        <div className="users_table_header3">
                            <p>Email</p>
                        </div>
                        <div className="users_table_header4">
                            <p>Country</p>
                        </div>
                        <div className="users_table_header5">
                            <p>Edit</p>
                        </div>
                    </div>
                    {loadedData.map(user => (
                        <AdminUser key={user.customerId} user={user} />
                    ))}
                </div> 
            </div> 
        )
    }else{
        return(
            <Loading/>
        )
    }
}

export default AdminUsers;